import * as XLSX from "xlsx/xlsx.mjs"
import axios from "axios"
import { HOST } from "../../../Providers/AuthProvider"

export const SarswatParse = async (file, selectedAccount, setErroredItems) => {

  const token = sessionStorage.getItem("accessToken")
  const reader = new FileReader()
  const rABS = !!reader.readAsBinaryString
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target.result
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
    /* Get first worksheet */
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    console.log(rABS, wb)
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
    /* Update state */
    parsing(data)
    console.log("daaaaaaaaa", data)
  }
  if (rABS) reader.readAsBinaryString(file)
  else reader.readAsArrayBuffer(file)


  const parsing = (xlsData) => {
    let res = []
    let errored = []
    let firstIndex
    xlsData.some((item, index) => {
        if (item.length === 17) {
          firstIndex = index
          console.log("firstIndex", firstIndex)
          return item
        }
      }
    )
    let newArr = xlsData
    const removed = newArr.splice(0, firstIndex)
    let connectedItems = []
    newArr.map((item, index) => {
      if (index % 2) {
        connectedItems.push([...item, ...newArr[index - 1]])
      }
    })

    if (connectedItems) {
      connectedItems.map((item) => {
        //   console.log((item[2]?.split("/").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0] || item[2]?.split(":").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0]))
        if (!item[2]?.toString()?.toLowerCase()?.includes("cheque")
          && !item[2]?.toString()?.toLowerCase()?.includes("ref.")) {
        if (item.length === 20 && (item[2]?.split("/").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0] || item[2]?.split(":").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0]) && item[14]) {
          res.push(
            {
              "UTR": (item[2]?.split("/").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0] || item[2]?.split(":").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0]),
              "amount": item[14].replace(",", ""),
              "acNo": selectedAccount
            })
        } else if (item[2] && item[2] != "Remarks" && item[14]?.replace(/\D/g, "")[0]) {
          errored.push({
            UTR: item[2],
            amount: item[14]
          })
        }
      }
      })
    }
    setErroredItems(errored)
    try {
      axios.post(HOST + "/Banking/deposit-entry-bulk",
        res,
        {
          headers: { "Authorization": `${token}` }
        }).then((response) => {
        console.log(response.data)
      })
    } catch (e) {
      console.log(e)
    }
    console.log("DATA TO SEND", res)
    console.log("ERRORED ITEM (No Amount or UTR)", errored)
  }
}
