import "./index.scss";
import {useEffect, useState} from "react";
import ButtonCustom from "../../components/ButtonCustom";
import DateTimeRangePicker from "../../components/DateTimeRangePicker";
import DepositEntryPopUp from "../../components/DepositEntryPopUp";
import DropdownStatus from "../../components/DropdownStatus";
import InfoPopUp from "../../components/InfoPopUp";
import PaginationCustom from "../../components/PaginationCustom";
import SearchCustom from "../../components/SearchCustom";
import TextInputCustom from "../../components/TextInputCustom";
import moment from "moment";
import {useAuth} from "../../Providers/AuthProvider";
import * as url from "url"

export default () => {
    const {requestsToApi} = useAuth();
  let now = new Date();
  const startOfToday = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  const endOfToday = moment(startOfToday).add(1, "days").subtract(1, "seconds");

  // const [newOptions, setNewOptions] = useState({utrInput: "", amountInput: "", status: "", idInput: "", date: null});
    const [newOptions, setNewOptions] = useState({
        utrInput: "",
        amountInput: "",
        status: "",
        userId: "",
        idInput: "",
        gatewayId: "",
        userName: "",
        managerId: "",
        date: null,
    });
    const [showDepositPopUp, setShowDepositPopUp] = useState(false);
    const [choseReport, setChoseReport] = useState({});
    const [reportsData, setReportsData] = useState([]);
    const [dateForPagination, setDateForPagination] = useState(new Date());
    const [firstDay, setFirstDay] = useState(new Date());
    const [activePage, setActivePage] = useState(1);
    const [sortByDesc, setSortByDesc] = useState({createdAt: true, updatedAt: false, amount: false});
    const [sortedReports, setSortedReports] = useState([]);
    const [isAutoRefresh, setIsAutoRefresh] = useState(false);
    console.log('setIsAutoRefresh', isAutoRefresh);


    useEffect(() => {
      let autoRefreshInterval
      if (isAutoRefresh){
        autoRefreshInterval = setInterval(async () =>{
          try {
            const response = await requestsToApi.get("/Banking/manager-report" + urlParams);
            setSortedReports(response.data.result.sort((a, b) => {if (a.createdAt > b.createdAt) return -1;}));
            setReportsData(response.data.result);
        } catch (error) {
            console.log(error?.message);
        }
        }, 10000)
      }
      if (!isAutoRefresh) {
        clearInterval(autoRefreshInterval)
      }
      return () => clearInterval(autoRefreshInterval)
    }, [isAutoRefresh])

    useEffect(async () => {
        try {
            const response = await requestsToApi.get("/Banking/manager-report" + urlParams);
            setSortedReports(response.data.result.sort((a, b) => {if (a.createdAt > b.createdAt) return -1;}));
            setReportsData(response.data.result);
        } catch (error) {
            console.log(error?.message);
        }
    }, []);

    useEffect( () => {
        setDateForPagination(newOptions?.date?.start || new Date());
        setFirstDay( new Date(newOptions?.date?.start));
    }, [newOptions.date]);

  // useEffect( async () => {
  //     const filteredReportsData = reportsData.filter(report => moment(report.createdAt).format("DD.MM.YY") === moment(dateForPagination).format("DD.MM.YY"));
  //     setFilteredReports(filteredReportsData);
  // }, [dateForPagination]);

    const urlParams =
        "?" +
        "status=" + (newOptions.status ? newOptions.status[0] + newOptions.status.slice(1).toLowerCase() : "") +
        "&type=" + "D" +
        "&userId=" + newOptions.userId +
        "&requestId=" + newOptions.idInput +
        "&gatewayId=" + newOptions.gatewayId +
        "&userName=" + newOptions.userName +
        "&filterManagerId=" + newOptions.managerId +
  "&from=" + (!newOptions?.date?.start ? startOfToday : newOptions?.date?.start) +
  "&to=" + (!newOptions?.date?.end ? endOfToday : newOptions?.date?.end) +
      "&UTR=" + newOptions.utrInput

    const changeValue = (value, filterTitle) => {
        setNewOptions({...newOptions, [filterTitle]: value});
    };

    const openDepositPopUp =(report) => {
        setChoseReport(report);
        if (report.status === "Pending") {
            setShowDepositPopUp(prevState => !prevState);
        }
    };

    const handleClickClear = () => {
        setNewOptions({utrInput: "", amountInput: "", status: "", userId: "", idInput: "", gatewayId: "", userName: "", managerId: "", date: null, UTR: ""});
    };

    const handleClickApply = async () => {
        console.log("it works!");
        try {
            const response = await requestsToApi.get("/Banking/manager-report" + urlParams);

            if (response.data.result != null){
              setSortedReports(response.data.result.sort((a, b) => {if (a.createdAt > b.createdAt) return -1;}));
              setReportsData(response.data.result);
            } else {
              setSortedReports([]);
              setReportsData([]);
            }

        } catch (error) {
            console.log(error?.message);
        }
    };

    const changeSort = (key) => {
        setSortByDesc({createdAt: false , updatedAt: false, amount: false , [key]: !sortByDesc[key]});

        if (reportsData.length >= 2) {
            if (key === "amount") {
                setSortedReports(reportsData?.sort((a, b) => sortByDesc[key] ? a[key] - b[key] : b[key] - a[key]));
            }
            if (key !== "amount") {
                const filter = reportsData.filter(item => item[key]);

                const sort = filter.sort((a, b) => {
                    if (a[key] > b[key]) return -1;
                });
                setSortedReports(sortByDesc[key] ? [...sort.reverse(), ...reportsData.filter(item => !item[key])] : [...sort, ...reportsData.filter(item => !item[key])]);
            }
        }
    };

    return (
    <div className="payment-reports">
      <div className="payment-reports__header">
        <div className="payment-reports__header_date-rows">
          <div className="payment-reports__header_date-row">
            <h1>Reports</h1>
            <DateTimeRangePicker dateTimeRange={newOptions.date} onChangeFunc={(value) => changeValue(value, "date")} />
          </div>
          <div className="payment-reports__header_date-unput-row">
            <TextInputCustom className="payment-reports__textInputCustom" onChange={(value) => changeValue(value, "utrInput")} placeholder="UTR">
              {newOptions.utrInput}
            </TextInputCustom>
            <TextInputCustom className="payment-reports__textInputCustom" onChange={(value) => changeValue(value, "amountInput")} placeholder="Amount">
              {newOptions.amountInput}
            </TextInputCustom>
            <div className="payment-reports__header_date-input-row-drop-down-wrapper">
              <DropdownStatus title={newOptions.status} onChange={(value) => changeValue(value, "status")} />
            </div>
            {/*<TextInputCustom className="payment-reports__textInputCustom" onChange={(value) => changeValue(value, "userId")} placeholder="User ID">*/}
            {/*  {newOptions.userId}*/}
            {/*</TextInputCustom>*/}
            {/*<TextInputCustom className="payment-reports__textInputCustom" onChange={(value) => changeValue(value, "idInput")} placeholder="Request ID">*/}
            {/*  {newOptions.idInput}*/}
            {/*</TextInputCustom>*/}
            {/*<TextInputCustom className="payment-reports__textInputCustom" onChange={(value) => changeValue(value, "gatewayId")} placeholder="Gateway Id">*/}
            {/*  {newOptions.gatewayId}*/}
            {/*</TextInputCustom>*/}
            {/*<TextInputCustom className="payment-reports__textInputCustom" onChange={(value) => changeValue(value, "userName")} placeholder="User Name">*/}
            {/*  {newOptions.userName}*/}
            {/*</TextInputCustom>*/}
            {/*<TextInputCustom className="payment-reports__textInputCustom" onChange={(value) => changeValue(value, "managerId")} placeholder="Manager ID">*/}
            {/*  {newOptions.managerId}*/}
            {/*</TextInputCustom>*/}
          </div>
        </div>
        <div className="payment-reports__header-search-column">
          <div className="payment-reports__header-search-column-buttons">
            {/*<ButtonCustom onClick={() => console.log("Apply")} color="cyan">Apply</ButtonCustom>*/}
            <span className="payment-reports__header-search-column-buttons-autoRefreshTitle">Auto Refresh</span>
            <input type="checkbox" className="payment-reports__header-search-column-buttons-autoRefresh" onChange={() => setIsAutoRefresh(!isAutoRefresh)}/>
            <ButtonCustom onClick={handleClickApply} color="cyan">Apply</ButtonCustom>
            <ButtonCustom onClick={handleClickClear} color="red">Clear</ButtonCustom>
          </div>
          {/*<div className="payment-reports__header-search-column-search-wrapper">*/}
          {/*  <SearchCustom onChange={(value) => console.log(value)} />*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="payment-reports__table">
        <div className="payment-reports__table-row-date">
          <div className="payment-reports__table-date">{moment(dateForPagination).format("DD.MM.YY")}</div>
          <div className="payment-reports__table-paginaton">
            <PaginationCustom
              totalPages={newOptions?.date ? newOptions?.date?.end.diff(newOptions?.date?.start, "days") + 1 : 1}
              activePage={activePage}
              changeActivePage={page => {
                  setActivePage(page);
                  setDateForPagination(new Date(firstDay.setDate(firstDay.getDate() - (activePage - page))));
              }}
            />
          </div>
        </div>
        <ul className="payment-reports__table-header">
          <li onClick={() => changeSort("createdAt")}>
            <span className={`payment-reports__table-header-sort ${sortByDesc.createdAt ? "" : "up" }`}>CreatedAt</span>
          </li>
          {/*<li onClick={() => changeSort("updatedAt")}>*/}
          {/*  <span className={`payment-reports__table-header-sort ${sortByDesc.updatedAt ? "" : "up" }`}>CompletedAt</span>*/}
          {/*</li>*/}
          <li>ID</li>
          <li onClick={() => changeSort("amount")}>
            <span className={`payment-reports__table-header-sort ${sortByDesc.amount ? "" : "up" }`}>Amount</span>
          </li>
          <li>UTR_NO</li>
          <li>Status</li>
        </ul>
        <div className="payment-reports__table-body">
          {sortedReports?.map((report, index) =>
            <ul key={report.requestId + index}
                className="payment-reports__table-body-row">
              <li className="payment-reports__table-body-row-date">
                <div>{moment(report?.createdAt).format("DD.MM.YY")}</div>
                <div className="time">{moment(report?.createdAt).format("LTS")}</div>
              </li>
              {/*<li className="payment-reports__table-body-row-date">*/}
              {/*  {report.updatedAt &&*/}
              {/*    <div>*/}
              {/*      <div>{moment(report.updatedAt).format("DD.MM.YY")}</div>*/}
              {/*      <div className="time">{moment(report.updatedAt).format("LTS")}</div>*/}
              {/*    </div>}*/}
              {/*</li>*/}

              <li>{report?.requestId}</li>
              <li>{report?.amount} {report.status === "Pending" && <div onClick={(e) => openDepositPopUp(report)} className="payment-reports__edit-button"/> } </li>
              <li>{report?.UTR}</li>
              <li>{report?.status}</li>
            </ul>,
          )}
          <DepositEntryPopUp
            visible={showDepositPopUp}
            closeFunc={() => setShowDepositPopUp(false)}
            data={choseReport}
          />
        </div>
        <InfoPopUp />
        <PaginationCustom
          totalPages={newOptions?.date ? newOptions?.date?.end.diff(newOptions?.date?.start, "days") + 1 : 1}
          activePage={activePage}
          changeActivePage={page => {
              setActivePage(page);
              setDateForPagination(new Date(firstDay.setDate(firstDay.getDate() - (activePage - page))));
          }}
        />
      </div>
    </div>
    );
};
