import axios from "axios"
import { HOST } from "../../../Providers/AuthProvider"
import moment from "moment"
import jwt_decode from "jwt-decode";

export const IndusIndCSVParse = async (file, selectedAccount, setErroredItems) => {
  const token = sessionStorage.getItem("accessToken")
  const user = jwt_decode(token);
  let allItems
  let parsedItems
  console.log("output", await file.text())
  allItems = await file.text()
  const myId = sessionStorage.getItem("myId")
  const userName = sessionStorage.getItem("myUserName")
  function CSVToArray(strData, strDelimiter) {
    strDelimiter = strDelimiter || ","

    var objPattern = new RegExp(
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" + "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" + "([^\"\\" + strDelimiter + "\\r\\n]*))",
      "gi"
    )


    var arrData = [[]]

    var arrMatches = null

    while ((arrMatches = objPattern.exec(strData))) {
      var strMatchedDelimiter = arrMatches[1]
      if (
        strMatchedDelimiter.length &&
        strMatchedDelimiter !== strDelimiter
      ) {
        arrData.push([])

      }

      var strMatchedValue

      if (arrMatches[2]) {
        strMatchedValue = arrMatches[2].replace(new RegExp("\"\"", "g"), "\"")
      } else {
        strMatchedValue = arrMatches[3]

      }
      arrData[arrData.length - 1].push(strMatchedValue)
    }
    // console.log("object", arrData)

    // Return the parsed data.
    return arrData
  }

  if (allItems) {
    parsedItems = CSVToArray(allItems)
  }

  if (parsedItems) {
    const token = sessionStorage.getItem("accessToken")
    let i = 0
    let errored = []
    let allSendingArr = []
    let accountEntries = []
    console.log(parsedItems)
    parsedItems?.map(async item => {
      // console.log(item[2]?.split('/').filter(item2 => item2.length === 12 && item2.replace( /\D/g, '')).length > 0 ? item[2]?.split('/').filter(item2 => item2.length === 12 && item2.replace( /\D/g, '')) : null);
      if (!item[3]?.toString()?.toLowerCase()?.includes("cheque")
          && !item[3]?.toString()?.toLowerCase()?.includes("ref.")) {
      if (item[3]?.split("/").filter(item2 => item2.length === 12 && item2.replace(/\D/g, "")).length > 0 && item[5]) {
        allSendingArr.push({
          UTR: item[3]?.split("/").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0],
          amount: item[5].replaceAll(" ", ""),
          acNo: selectedAccount
        })
        i = ++i
        let date = moment(item[1], 'DD-MMM-YYYY').format("YYYY-MM-DD");
        const obj = {
          description: item[3],
          utr: item[3]?.split("/").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0],
          accountNumber: selectedAccount,
          amount: item[5].replaceAll(" ", ""),
          isDeposit: true,
          userId: myId,
          userName: userName,
          psId: user.paymentSystemId,
          isPlugin: false,
          date: date + "T09:50:05.877Z",
          isRecognized: true,
          isProcessed: true
        }
        accountEntries.push(obj)
        // console.log(i)
      } else if (item[3]?.split("/").filter(item2 => item2.length === 12 && item2.replace(/\D/g, "")).length > 0 && item[4]) {
        let date = moment(item[1], 'DD-MMM-YYYY').format("YYYY-MM-DD");
        const obj = {
          description: item[3],
          utr: item[3]?.split("/").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0],
          accountNumber: selectedAccount,
          amount: item[4].replaceAll(" ", ""),
          isDeposit: false,
          userId: myId,
          userName: userName,
          psId: user.paymentSystemId,
          isPlugin: false,
          date: date + "T09:50:05.877Z",
          isRecognized: true,
          isProcessed: true
        }
        accountEntries.push(obj)
      } else if (item.length === 7 && (item[4] || item[5]) && item[3] && !item[3].includes('Description')) {
        let date = moment(item[1], 'DD-MMM-YYYY').format("YYYY-MM-DD");
        let amount = item[4].replace("-", "") || item[5].replace("-", "");
        const obj = {
          description: item[3],
          utr: "",
          accountNumber: selectedAccount,
          amount: amount.replaceAll(" ", ""),
          isDeposit: item[5].replace("-", "") ? true : false,
          userId: myId,
          userName: userName,
          psId: user.paymentSystemId,
          isPlugin: false,
          date: date + "T00:00:00.000Z",
          isRecognized: false,
          isProcessed: false
        }
        accountEntries.push(obj);
      } else {
        errored.push({
          UTR: item[3],
          amount: item[5]
        })
      }
    }
    })
    setErroredItems(errored)
    try {
      axios.post(HOST + "/Banking/deposit-entry-bulk",
        allSendingArr,
        {
          headers: { "Authorization": `${token}` }
        }).then((response) => {
          console.log(response.data)
        })
      axios.post("https://banking.upicash.com/api/account_entries/",
        accountEntries,
        {
          headers: { "Authorization": `${token}` }
        }).then((response) => {
          console.log(response.data)
        })
    } catch (e) {
      console.log(e)
    }
    console.log("ALL SENDING ARR", allSendingArr)
    console.log("ALL SENDING ARR", accountEntries)
  }
}
