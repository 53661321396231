import "./index.scss"
import { useEffect, useState } from "react"
import ButtonCustom from "../../components/ButtonCustom"
import DropdownStatus from "../../components/DropdownStatus"
import PaginationCustom from "../../components/PaginationCustom"
import TextInputCustom from "../../components/TextInputCustom"
import moment from "moment"
import { useAuth } from "../../Providers/AuthProvider"
import DatePicker from "../../components/DatePicker"
import { toastr } from "react-redux-toastr"
export default () => {
  let now = new Date()
  const endOfToday = moment().endOf("day")
  const startOfToday = moment().startOf("day")
  const [dateTimeRange, setDateTimeRange] = useState({ start: startOfToday, end: endOfToday })
  const [newOptions, setNewOptions] = useState({ search: "", amount: "", type: "", date: null })
  const { requestsToApi } = useAuth()
  const [reportsData, setReportsData] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [allPages, setAllPages] = useState(1)
  const [lastSort, setLastSort] = useState({ createdAt: true })
  const [sortedReports, setSortedReports] = useState([])
  const [autoRefreshState, setAutoRefreshState] = useState(false)
  const changeValue = (value, filterTitle) => {
    setNewOptions({ ...newOptions, [filterTitle]: value })
  }
  const handleClickClear = () => {
    setNewOptions({ search: "", accNo: "", type: "", date: null })
    setActivePage(1)
    setAllPages(1)
    setReportsData([])
  }
  const handleClickApply = async (page) => {
    const params = new URLSearchParams()
    for (const key in newOptions) {
      if (newOptions.hasOwnProperty(key) && newOptions[key]) {
        if (key === "type" || key === "process") {
          if (key === "type") {
          if (newOptions[key] === "WITHDRAW") {
            params.set("isWithdrawal", "true")
            params.set("isDeposit", "false")
          }
          if (newOptions[key] === "DEPOSIT") {
            params.set("isDeposit", "true")
            params.set("isWithdrawal", "false")
          }
          if (newOptions[key] === "ALL") {
            params.set("isDeposit", "true")
            params.set("isWithdrawal", "true")
          }
        }
          if (key === "process") {
            if (newOptions[key] === "ISPROCESSED") {
              params.set("isProcessed ", "true")
              params.set("isRecognized", "false")
            }
            if (newOptions[key] === "ISRECOGNIZED") {
              params.set("isProcessed", "false")
              params.set("isRecognized", "true")
            }
            if (newOptions[key] === "ALL") {
              params.set("isProcessed ", "true")
              params.set("isRecognized", "true")
            }
        }} else {
          params.set(key, newOptions[key])
        }
      }
    }
    if (page && page >= 1) {
      params.set("page", page)
    }
    setSortedReports(null)
    try {
      const response = await requestsToApi.get("https://banking.upicash.com/api/account_entries/?" + params.toString())
      setReportsData(response.data.data ?? [])
      setAllPages(Math.ceil(+response.data.total  / 50))
    } catch (error) {
      toastr.error(`${error?.message}`)
      console.log(error?.message)
    }
  }
  const changePage = (page) => {
    setActivePage(page)
    handleClickApply(page)
  };
  const changeSort = (key) => {
    setLastSort(lastSort[key] ? { [key]: !lastSort[key] } : { [key]: true })
  }
  useEffect(() => {
    let dataForSort = [...reportsData]
    if ((dataForSort && dataForSort.length >= 2) || (autoRefreshState && dataForSort)) {
      const key = Object.keys(lastSort)[0]
      if (key === "amount") {
        !lastSort[key] ? setSortedReports([...dataForSort?.sort((a, b) => a[key] - b[key])]) : setSortedReports([...dataForSort?.sort((a, b) => b[key] - a[key])])
      }
      if (key !== "amount") {
        const filter = dataForSort.filter(item => item[key])
        const sort = filter.sort((a, b) => {
          if (a[key] > b[key]) return -1
        })
        setSortedReports(!lastSort[key] ? [...sort.reverse(), ...dataForSort.filter(item => !item[key])] : [...sort, ...dataForSort.filter(item => !item[key])])
      }
    } else {
      setSortedReports(reportsData)
    }
  }, [lastSort, reportsData])
  return (
    <div className="all-bank-transaction">
      <div className="all-bank-transaction__header">
        <div className="all-bank-transaction__header_date-rows">
          <div className="all-bank-transaction__header_date-row">
            <h1> All Bank Transactions </h1>
          </div>
          <div className="all-bank-transaction__header_date-unput-row">
            <TextInputCustom className="all-bank-transaction__textInputCustom"
                             onChange={(value) => changeValue(value, "search")} placeholder="Description">
              {newOptions.search}
            </TextInputCustom>
            <TextInputCustom className="all-bank-transaction__textInputCustom"
                             onChange={(value) => changeValue(value, "amount")} placeholder="Amount">
              {newOptions.amount}
            </TextInputCustom>
            <div className="all-bank-transaction__textInputCustom-drop-down-wrapper">
              <DropdownStatus
                options={["All", "deposit", "withdraw"]}
                title={"Deposit/Withdraw"}
                onChange={(value) => changeValue(value, "type")} />
            </div>
            <div className="all-bank-transaction__textInputCustom-drop-down-wrapper process">
              <DropdownStatus
                options={["All", "IsProcessed", "isRecognized"]}
                title={"IsProcessed/IsRecognized"}
                onChange={(value) => changeValue(value, "process")} />
            </div>
            <DatePicker
              dateTimeRange={dateTimeRange}
              clearDate={false}
              onChangeFunc={(value) => changeValue(value, "date")}
            />
          </div>
        </div>
        <div className="all-bank-transaction__header-search-column">
          <div className="all-bank-transaction__header-search-column-buttons">
            <ButtonCustom onClick={() => {
              handleClickApply()
              setAutoRefreshState(false)
            }} color="cyan">Apply</ButtonCustom>
            <ButtonCustom onClick={handleClickClear} color="red">Clear</ButtonCustom>
          </div>
        </div>
      </div>
      <div className="all-bank-transaction__table">
        <div className="all-bank-transaction__table-row-date">
          <div className="all-bank-transaction__table-paginaton">
            <PaginationCustom
              totalPages={allPages}
              activePage={activePage}
              changeActivePage={page => changePage(page)}
            />
          </div>
        </div>
        <ul className={`all-bank-transaction__table-header ${autoRefreshState ? "autoRefreshOn" : ""}`}>
          <li onClick={() => changeSort("createdAt")}
              className={`all-bank-transaction__table-header-sort-button all-bank-transaction__createdAt-button`}>
            <span
              className={`all-bank-transaction__table-header-sort ${lastSort.createdAt ? "" : "up"}`}>CreatedAt</span>
          </li>
          <li onClick={() => changeSort("updatedAt")} className={`all-bank-transaction__table-header-sort-button`}>
            <span
              className={`all-bank-transaction__table-header-sort ${lastSort.updatedAt ? "" : "up"}`}>UpdatedAt</span>
          </li>
          <li className={`all-bank-transaction__table-header-sort-button`}>Account Number</li>
          <li className={`all-bank-transaction__table-header-sort-button`}>Username</li>
          <li onClick={() => changeSort("amount")} className={`all-bank-transaction__table-header-sort-button`}>
            <span className={`all-bank-transaction__table-header-sort ${lastSort.amount ? "" : "up"}`}>Amount</span>
          </li>
          <li className={`all-bank-transaction__table-header-sort-button smaller`}>Deposit</li>
          <li className={`all-bank-transaction__table-header-sort-button smaller`}>Processed</li>
          <li className={`all-bank-transaction__table-header-sort-button smaller`}>Plugin</li>
          <li className={`all-bank-transaction__table-header-sort-button smaller`}>Recognized</li>
          <li className={`all-bank-transaction__table-header-sort-button description`}>Description
          </li>
        </ul>
        <div className="all-bank-transaction__table-body">
          {sortedReports?.map((report, index) =>
            <ul key={report.requestId + index}
                className="all-bank-transaction__table-body-row">
              <li className="all-bank-transaction__table-body-row-date">
                <div>{moment(report?.createdAt).format("DD.MM.YY")}</div>
                <div className="time">{moment(report?.createdAt).format("LTS")}</div>
              </li>
              <li className="all-bank-transaction__table-body-row-date">
                {report.updatedAt &&
                  <div>
                    <div>{moment(report.updatedAt).format("DD.MM.YY")}</div>
                    <div className="time">{moment(report.updatedAt).format("LTS")}</div>
                  </div>}
              </li>
              <li>{report?.accountNumber}</li>
              <li>{report?.userName}</li>
              <li>{report?.amount}</li>
              <li className="smaller">{report?.isDeposit ? "Yes" : "No"}</li>
              <li className="smaller">{report?.isProcessed ? "Yes" : "No"}</li>
              <li className="smaller">{report?.isPlugin ? "Yes" : "No"}</li>
              <li className="smaller">{report.isRecognized ? "Yes" : "No"}</li>
              <li className="description">{report?.description}</li>
              {/*<li onClick={() => showDepositPopUpPopup(report)} >{report?.IP}</li>*/}
              {/*<li onClick={() => showCancelPopup(report?.requestId)} className="all-bank-transaction__table-body-row-cancel">X</li>*/}
            </ul>
          )}
        </div>
        <div className="all-bank-transaction__table-row-date">
          <div className="all-bank-transaction__table-paginaton">
            <PaginationCustom
              totalPages={allPages}
              activePage={activePage}
              changeActivePage={page => changePage(page)}
            />
          </div>
        </div>
      </div>
    </div>
  )
};