import { useEffect, useRef, useState } from "react"
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { DateTimeRangePicker } from 'react-advanced-datetimerange-picker/dist/DateTimeRangePicker';
import { Button, FormControl } from 'react-bootstrap'
import moment from "moment"
import './index.scss'
import CalendalIcon from 'mdi-react/CalendarMonthIcon'
import CloseIcon from 'mdi-react/CloseIcon'
export default ({
  color = "#37133F",
  dateTimeRange,
  onChangeFunc,
}) => {
  let now = new Date();
  const endOfToday = moment().endOf('day');
  const startOfToday = moment().startOf('day');
  const [rangeDate, setRangeDate] = useState(dateTimeRange ? dateTimeRange : { start: startOfToday, and: endOfToday });
  const specifickButton = useRef(null);
  let local = {
    "format": "DD-MM-YYYY HH:mm:ss",
    "sundayFirst": false,
    days: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    fromDate: 'Time Range',
    toDate: 'Date Range',
    cancel: 'Clear'
  }
  const [isClear, setIsClear] = useState(false);
  const doSomethingElse = (start, end) => {
    setIsClear(false)
    setRangeDate({ start, end })
    onChangeFunc(start.format('YYYY-MM-DD'))
  }
  const clear = (e) => {
    e.stopPropagation()
    onChangeFunc('')
    setIsClear(true)
  };
  useEffect(() => {
    onChangeFunc(rangeDate.start.format('YYYY-MM-DD'))
  }, []);
  return (
    <div
      className="date-time-range__box"
      style={{ width: "fit-content", backgroundColor: color }}
      ref={specifickButton}
      onFocus={() => specifickButton.current.focus()}
      onBlur={() => specifickButton.current.focus()}
    >
      <div >
        <div className='datepickerUserCount'>
        <DateTimeRangeContainer
          singleDatePicker={true}
          start={rangeDate.start}
          end={rangeDate.end}
          local={local}
          applyCallback={doSomethingElse}
          noMobileMode={true}
          style={{
            fromDate: { color: 'white', backgroundColor: '#37133F', borderRadius: 0 },
            // toDate: { color: 'white', backgroundColor: '#37133F', borderRadius: 0 },
            // customRangeButtons : { backgroundColor : ' rgb (40, 90, 75) ' } ,
            // customRangeSelected : {         background: "#0f8422",
            //     "box-shadow": "inset -2px -2px 0px #0b5617" } ,
          }}
        >
          <Button
            id="formControlsTextB"
            type="text"
            label="Text"
            aria-describedby="btnGroupAddon"
            style={{ backgroundColor: color, width: '190px' }}
          >
            <span>{isClear ? "YYYY-MM-DD" : `${rangeDate?.start?.format('YYYY-MM-DD')}`}</span>
            <div className="date-time-range__buttons">
              <CalendalIcon size={18} />
              <CloseIcon onClick={e => clear(e) } className="date-time-range__box-clear-icon" size={18} color={"red"} />
            </div>
          </Button>
          {/* <button className="test"/> */}
        </DateTimeRangeContainer>
        </div>
      </div>
    </div>
  )
}