import { Table } from "react-bootstrap"
import LockIcon from "../../../static/images/lockIcon.png"
import LockOpenedIcon from "../../../static/images/lockOpenedIcon.png"
import EyeIcon from "mdi-react/EyeIcon"
import LanIcon from "../../../static/images/help/icon_downline.svg"
import { useHistory } from "react-router-dom"
import { useEffect, useState } from "react"
import { useAuth } from "../../../Providers/AuthProvider"
import "../../../components/ModalChildInfo/index.scss"
import ModalChildInfo from "../../../components/ModalChildInfo"
import { positiveNumberWithCommas } from "../../../utils/dataTransform"
import StringToRecolorNumber from "../../../components/StringToRecolorNumber"
import { useStorage } from "../../../Providers/StorageProvider"
import AlertCustom from "../../../components/AlertCustom"
import ModalAcceptLock from "../../../components/ModalAcceptLock"
import CurrentTime from "../../../components/CurrentTime"

export default ({ downlineData, addClassWhileShowAllTable, splitUrl, setGetReqTrigger, searchUserUrl }) => {
  const history = useHistory()
  const {
    location: { pathname }
  } = history
  const { requestsToApi } = useAuth()
  const { userName, childrenLevel, userLevel } = useStorage()
  const [parent, setParent] = useState(splitUrl.slice(-1)[0] === "downline" ? userName : splitUrl.slice(-1)[0])

  const [modalSettings, setModalSettings] = useState({
    isVisible: false,
    isMyChild: parent === userName,
    data: null
  })
  const [lockUser, setLockUser] = useState(null)
  const [alertOptions, setAlertOptions] = useState({
    message: null,
    type: "green"
  })

  const balanceTextController = (balance, field) => {
    if (!balance) return ""
    else if (balance > 0) {
      if (field === "take") return ""
      else if (field === "give") {
        return positiveNumberWithCommas(balance)
      }
    } else {
      if (field === "take") {
        return positiveNumberWithCommas(balance)
      } else if (field === "give") return ""
    }
  }

  const showUserInfo = async name => {
    try {
      const response = await requestsToApi.get(
        `agency/${userName}/agency-mgmt/downline/form-info?parent=${parent}&dl=${name}`
      )
      setModalSettings({ ...modalSettings, isVisible: true, data: response.data.result })
    } catch (error) {
      console.log(error?.message)
    }
  }

  const toggleConfirmStatus = async () => {
    try {
      const response = await requestsToApi.put(
        "agency/" + userName + "/agency-mgmt/downline/" + lockUser.name + "/betting-lock?status=" + !lockUser.isLocked
      )
      if (response.data.success) {
        setGetReqTrigger(true)
        setAlertOptions({ message: "Betting lock updated successfully.", type: "green" })
      }
    } catch (e) {
      setAlertOptions({ message: e?.message, type: "red" })
    }

    setLockUser(null)
  }

  const closeModal = success => {
    setModalSettings({ ...modalSettings, isVisible: false })
    if (success) { 
      setAlertOptions({ message: `${childrenLevel} was updated successfully.`, type: "green" })
    setGetReqTrigger(true) 
  }
  }

  useEffect(() => {
    setModalSettings({ ...modalSettings, isMyChild: splitUrl.slice(-1)[0] === "downline" ? userName : splitUrl.slice(-1)[0] === userName })
    setParent(splitUrl.slice(-1)[0] === "downline" ? userName : splitUrl.slice(-1)[0])
  }, [splitUrl, downlineData])

  return (
    <div className="children-table__container">
      <Table striped hover variant="dark" className="children-listing-table left-part">
        <thead>
          <tr>
            <th scope="col" className="text-align-left">
              Login Name
            </th>
            <th scope="col" className="text-align-left">
              ID
            </th>

            {["Downline", "Betting Status", "Status", "Details"].map(text => (
              <th key={Math.random()} scope="col" className="text-align-center">
                {text}
              </th>
            ))}
            <th scope="col">Net Exposure</th>
            <th scope="col">Take</th>
            <th scope="col">Give</th>
            <th scope="col">Credit Limit</th>
            {userLevel !== 'Agent' ?
              <th scope="col">Available Credit</th> : <> </>}
            <th scope="col">PT(%)C/F/T/H/G/X</th>
            <th scope="col" className={addClassWhileShowAllTable()}>
              Created
            </th>
            <th scope="col" className={addClassWhileShowAllTable()}>
              Last Login
            </th>
            <th scope="col" className={addClassWhileShowAllTable()}>
              Last IP
            </th>
          </tr>
        </thead>
        <tbody>
          {downlineData.length ? (
            downlineData.map(item => (
              <tr key={item.user.id}>
                <td className="text-align-left">
                  <a className="no-href" onClick={() => showUserInfo(item.user.name)}>
                    {item.user.loginName}
                  </a>
                </td>
                <td className="text-align-left">
                  <a className="no-href" onClick={() => showUserInfo(item.user.name)}>
                    {item.user.name}
                  </a>
                </td>
                <td className="text-align-center">
                  {item.user.level === "Member" ? (
                    "--"
                  ) : (
                    <a
                      className="no-href"
                      onClick={() =>
                        history.push(pathname + (pathname.slice(-1)[0] === "/" ? "" : "/") + item.user.name)
                      }
                    >
                      <img src={LanIcon} alt="lanIcon" width="15" alt="" />
                    </a>
                  )}
                </td>
                <td
                  className={`children-listing-table__betting-status text-align-center 
                  ${childrenLevel === 'Member' ? 'disabled' : ''}`}
                  onClick={() => childrenLevel !== 'Member' && setLockUser({ name: item.user.name, isLocked: item.extraInfo.bettingLocked })}
                >
                  {item.extraInfo.bettingLocked ? <img src={LockIcon} alt="" /> : <img src={LockOpenedIcon} alt="" />}
                </td>
                <td className="children-listing-table__status text-align-center">{item.user.status}</td>
                <td className="children-listing-table__details text-align-center">
                  <a className="no-href" onClick={() => history.push("/secret-room/" + parent + "/" + item.user.name, 
                  {lastUrl: searchUserUrl, user: item.user})}>
                    <EyeIcon size={17} />
                  </a>
                </td>
                <td className="children-listing-table__net-exposure">
                  <StringToRecolorNumber>{item.account.actualNetExposure}</StringToRecolorNumber>
                </td>
                <td className="children-listing-table__take -positive">
                  {balanceTextController(item.account.settledBalance, "take")}
                </td>
                <td className="children-listing-table__give -negative">
                  {balanceTextController(item.account.settledBalance, "give")}
                </td>
                <td className="children-listing-table__credit-limit">
                  {positiveNumberWithCommas(item.account.creditLimit)}
                </td>
                {userLevel !== 'Agent' ?
                  <td className="children-listing-table__available-credit">
                    {positiveNumberWithCommas(item.account.availableBalance)}
                  </td> : <> </>}
                <td className="children-listing-table__pt">
                  {item.user.pt.CRICKET}/{item.user.pt.EXCHANGE_GAMES}/{item.user.pt.FOOTBALL}/
                  {item.user.pt.GREYHOUND_RACING}/{item.user.pt.HORSE_RACING}/{item.user.pt.TENNIS}
                </td>
                {["createdDate", "lastLoginTime"].map(param => (
                  <td key={param} className={addClassWhileShowAllTable()}>
                    {item.user[param] ? <CurrentTime format="DD.MM.YYYY HH:mm:ss">{item.user[param]}</CurrentTime> : ""}
                  </td>
                ))}
                <td className={"children-listing-table__last-ip " + addClassWhileShowAllTable()}>
                  {item.user.lastLoginIp}
                </td>
              </tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </Table>
      {modalSettings.isVisible ? <ModalChildInfo settings={modalSettings} closeFunc={closeModal} /> : <></>}
      <ModalAcceptLock editingItem={lockUser} closeFunc={() => setLockUser(null)} confirmFunc={toggleConfirmStatus} />
      <AlertCustom disableFunc={() => setAlertOptions({ ...alertOptions, message: null })} type={alertOptions.type}>
        {alertOptions.message}
      </AlertCustom>
    </div>
  )
}
