import "./index.scss";
import {useEffect, useState} from "react";

export default ({options = ["ALL", "EXPORTED", "PENDING", "APPROVED", "CANCELLED" ], title, onChange}) => {
    let [showOption, setShowOption] = useState(false);
    let [selectedOption, setSelectedOption] = useState("");

    useEffect(() => {
        title ? setSelectedOption(title) : setSelectedOption("Status");
    }, [title]);

    const handleOptionClick = e => {
        setSelectedOption(e.target.innerHTML);
        setShowOption(false);
        onChange(e.target.innerHTML);
    };

    return (
    <div className="status-dropdown">
      <div className={showOption ? "status-dropdown-selected active" : "status-dropdown-selected"} onClick={() => setShowOption(prevState => !prevState)}>
        {selectedOption}
      </div>
      {showOption && (
        <ul className="status-dropdown-options">
          {options.map(item => <li className="status-dropdown-option" key={item} onClick={handleOptionClick}>{item.toUpperCase()}</li>)}
        </ul>
      )}
    </div>
    );
};
