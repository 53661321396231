import React, { useState, useContext, useMemo, useEffect } from "react"
import { createApi } from "../utils/requestToApi"
import jwt_decode from "jwt-decode"
import moment from "moment"
import { useHistory } from "react-router-dom"

export const AuthContext = React.createContext()
export const useAuth = () => useContext(AuthContext)
// export const HOST = "http://192.168.1.248:5002/api";
export const HOST = "https://payments.dev.gemexch.co/api";
// export const HOST = "https://payments-test.gemexch.bet/api";
export const Logo = '/assets/images/' + (window?.location?.hostname?.replace('app.', '').replace('admin.', '').replace('www.', '') || "gemexch") + '/logo.png';
export const Version = 'V1.1';

export const AuthProvider = ({ children }) => {
  const [authLoading, setAuthLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [appMode, setAppMode] = useState('');
  const [token, setToken] = useState(sessionStorage.getItem("accessToken"))
  const history = useHistory()

  const logout = () => {
    sessionStorage.clear()
    setIsAuthenticated(false)
  }
  //it's for agensy

  // const requestsToApi = useMemo(() => {
  //   return createApi(`https://admin.gemexch.bet/api`, logout, token)
  // }, [token]);

  //it's for payments
  const requestsToApi = useMemo(() => {
    // return createApi(`https://payments.gemexch.bet/api`, logout, token)
    return createApi(`${HOST}`, logout, token)
  }, [token]);
  
  const requestsToNodeAPI = useMemo(() => {
    // return createApi(`https://payments.gemexch.bet/api`, logout, token)
    return createApi(`https://banking.route2222.com/api`, logout, token)
  }, [token]);


  const setAuthProcess = async () => {
    if (token) {
      const { exp = 0 } = jwt_decode(token)
      const curDate = moment().unix()
      setIsAuthenticated(exp >= curDate)
      // exp <= curDate
      //   ? setIsAuthenticated(false)
      //   : setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
    setAuthLoading(false)
  }

  useEffect(() => {
    setAuthProcess()
  }, [])

  const auth = (token, user = {}, appMode = "") => {
    setIsAuthenticated(true);
    setToken(token);
    setAppMode(appMode);
    sessionStorage.setItem("accessToken", token);
    appMode && appMode === "Cashier" ? history.push("/home-page") : history.push("/account/balance");
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setToken,
        requestsToApi,
        requestsToNodeAPI,
        appMode,
        logout,
        auth
      }}
    >
      {authLoading ? <p>...Loading</p> : children}
    </AuthContext.Provider>
  )
}
