import axios from "axios"
import { HOST } from "../../../Providers/AuthProvider"

export const ujjivanCSV = async (file, selectedAccount, setErroredItems) => {
  const token = sessionStorage.getItem("accessToken")
  let allItems
  let parsedItems
  console.log("output", await file.text())
  allItems = await file.text()

  function CSVToArray(strData, strDelimiter) {
    strDelimiter = strDelimiter || ","

    var objPattern = new RegExp(
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" + "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" + "([^\"\\" + strDelimiter + "\\r\\n]*))",
      "gi"
    )


    var arrData = [[]]

    var arrMatches = null

    while ((arrMatches = objPattern.exec(strData))) {
      var strMatchedDelimiter = arrMatches[1]
      if (
        strMatchedDelimiter.length &&
        strMatchedDelimiter !== strDelimiter
      ) {
        arrData.push([])

      }

      var strMatchedValue

      if (arrMatches[2]) {
        strMatchedValue = arrMatches[2].replace(new RegExp("\"\"", "g"), "\"")
      } else {
        strMatchedValue = arrMatches[3]

      }
      arrData[arrData.length - 1].push(strMatchedValue)
    }
    // console.log("object", arrData)

    // Return the parsed data.
    return arrData
  }

  if (allItems) {
    parsedItems = CSVToArray(allItems)
  }

  if (parsedItems) {
    console.log(parsedItems)
    let res = []
    let errored = []
    parsedItems?.map(async item => {
        let amount
        let UTR
  
        if (item.length === 6 && item[4]) {
          if (!item[1]?.toString()?.toLowerCase()?.includes("cheque")
          && !item[1]?.toString()?.toLowerCase()?.includes("ref.")) {
          if (item[1].includes("UPI")) {
            UTR = item[1].split("/")[2]
            amount = item[4]
            res.push({
              "UTR": UTR,
              "amount": amount,
              "acNo": selectedAccount
            });
          } else if (item[1]?.includes("IMPS")) {
            UTR = item[1]?.split("/")[1]
            amount = item[4]
            res.push({
              "UTR": UTR,
              "amount": amount,
              "acNo": selectedAccount,
            });
          } else {
            errored.push({
              "UTR": item[1],
              "amount": item[4],
              "acNo": selectedAccount,
              "particular": item
            })
          }
        }
        } else {
          errored.push({
            "UTR": item[1],
            "amount": item[4],
            "acNo": selectedAccount,
            "particular": item
          })
        }
      // setErroredItems(errored)
      // console.log("DATA TO SEND", res)

    })
    setErroredItems(errored)
    try {
      axios.post(HOST + "/Banking/deposit-entry-bulk",
      res,
        {
          headers: { "Authorization": `${token}` }
        }).then((response) => {
        console.log(response.data)
      })
    } catch (e) {
      console.log(e)
    }
    console.log("ALL SENDING ARR", res)
    console.log("ERRORED", errored)

  }
}
