import * as XLSX from "xlsx/xlsx.mjs"
// import axios from "axios"
// import { HOST } from "../../../Providers/AuthProvider"

export const NewExcel = async (file, setResultArray, batchId) => {
  // const dispatch = useDispatch()
  const token = sessionStorage.getItem("accessToken")
  const reader = new FileReader()
  const rABS = !!reader.readAsBinaryString
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target.result
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
    /* Get first worksheet */
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    console.log(rABS, wb)
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
    /* Update state */
    parsing(data)
    console.log("daaaaaaaaa", data)
  }
  if (rABS) reader.readAsBinaryString(file)
  else reader.readAsArrayBuffer(file)

  const parsing = xlsData => {
    let res = []
    let errored = []
    console.log("xlsData", xlsData)
    
    xlsData.map(item => {
        console.log("xlsData", item[4]?.split("/")?.[item[4]?.split("/")?.length - 1], batchId)
        // console.log('object', item);
      try {
        if (
          item.length === 5 &&
          item[4].split("/")[item[4].split("/").length - 1]  === batchId &&
          item[4].split("/")[item[4].split("/").length - 2] &&
          item[4] &&
          item[2] > 0 &&
          !item[4].includes("REV/RRN")
        ) {
          console.log('pass');
          let splitedString = item[4].split("/")
          res.push({
            amount: item[2],
            acNo: splitedString[2].slice(4), // remove first 4 characters from acNo
            acName: splitedString[item[4].split("/").length - 2],
            UTR: item[0]
            // acNo: selectedAccount
          })
        } else {
          errored.push({
            UTR: item[0],
            amount: item[3],
            PayeeAccNo: item[5],
            PayeeIFSC: item[4]
          })
          // dispatch(getDepositEntryErroredItems(['t', 't']))
          console.log('errITM', item);
        }
      } catch (e) {
        console.log(e)
      }
    })
    setResultArray(res)
    // setErroredItems(errored)
    // try {
    //   axios.post(HOST + "/Banking/deposit-entry-bulk",
    //     res,
    //     {
    //       headers: { "Authorization": `${token}` }
    //     }).then((response) => {
    //     console.log(response.data)
    //   })
    // } catch (e) {
    //   console.log(e)
    // // }
    // console.log("DATA TO SEND", res)
    // console.log("ERRORED ITEM (No Amount or UTR)", errored)
  }
  ////// END FEDERAL XSLX //////
}

// export const erroredAU = () => {
//   return asdasd
// }
