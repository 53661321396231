import "./index.scss"
import { useEffect, useState } from "react"
import PaginationCustom from "../../components/PaginationCustom"
import moment from "moment"
import { useAuth } from "../../Providers/AuthProvider"
import SwitchCustom from "../../components/SwitchCustom"
import { toastr } from "react-redux-toastr"
export default () => {
  let now = new Date()
  const endOfToday = moment().endOf("day")
  const startOfToday = moment().startOf("day")
  const [dateTimeRange, setDateTimeRange] = useState({ start: startOfToday, end: endOfToday })
  const [newOptions, setNewOptions] = useState({ search: "", amount: "", type: "", date: null })
  const { requestsToApi } = useAuth()
  const [reportsData, setReportsData] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [allPages, setAllPages] = useState(1)
  const [lastSort, setLastSort] = useState({ createdAt: true })
  const [sortedReports, setSortedReports] = useState([])
  const [autoRefreshState, setAutoRefreshState] = useState(false)
  
  const handleClickApply = async (page) => {
    const params = new URLSearchParams()
    if (page && page >= 1) {
      params.set("page", page)
    }
    setSortedReports(null)
    try {
      const response = await requestsToApi.get("https://banking.upicash.com/api/accountPlugin/report/?" + params.toString())
      setReportsData(response.data.data ?? [])
      setAllPages(Math.ceil(+response.data.total  / 50))
    } catch (error) {
      toastr.error(`${error?.message}`)
      console.log(error?.message)
    }
  }
  const changePage = (page) => {
    setActivePage(page)
    handleClickApply(page)
  };
  const changeSort = (key) => {
    setLastSort(lastSort[key] ? { [key]: !lastSort[key] } : { [key]: true })
  }
  const switchToggle = async (index,accountNumber, value) => {
    try {
      const response = await requestsToApi.put("https://banking.upicash.com/api/accountPlugin/",{accountNumber:accountNumber,switch: !value})
      if(response.data && response.data.success){
        const data = JSON.parse(JSON.stringify(reportsData)) || [];
        data[index].switch = !value
        setReportsData(data)
      }
    } catch (error) {
      toastr.error(`${error?.message}`)
      console.log(error?.message)
    }
  }
  useEffect(()=>{
    handleClickApply()
  },[])
  useEffect(() => {
    let dataForSort = [...reportsData]
    if ((dataForSort && dataForSort.length >= 2) || (autoRefreshState && dataForSort)) {
      const key = Object.keys(lastSort)[0]
      if (key === "amount") {
        !lastSort[key] ? setSortedReports([...dataForSort?.sort((a, b) => a[key] - b[key])]) : setSortedReports([...dataForSort?.sort((a, b) => b[key] - a[key])])
      }
      if (key !== "amount") {
        const filter = dataForSort.filter(item => item[key])
        const sort = filter.sort((a, b) => {
          if (a[key] > b[key]) return -1
        })
        setSortedReports(!lastSort[key] ? [...sort.reverse(), ...dataForSort.filter(item => !item[key])] : [...sort, ...dataForSort.filter(item => !item[key])])
      }
    } else {
      setSortedReports(reportsData)
    }
  }, [lastSort, reportsData])
  return (
    <div className="all-bank-transaction">
      <div className="all-bank-transaction__header">
        <div className="all-bank-transaction__header_date-rows">
          <div className="all-bank-transaction__header_date-row">
            <h1> Plugin Status Report </h1>
          </div>
        </div>
      </div>
      <div className="all-bank-transaction__table">
        <div className="all-bank-transaction__table-row-date">
          <div className="all-bank-transaction__table-paginaton">
            <PaginationCustom
              totalPages={allPages}
              activePage={activePage}
              changeActivePage={page => changePage(page)}
            />
          </div>
        </div>
        <ul className={`all-bank-transaction__table-header ${autoRefreshState ? "autoRefreshOn" : ""}`}>
          <li onClick={() => changeSort("createdAt")}
              className={`all-bank-transaction__table-header-sort-button all-bank-transaction__createdAt-button`}>
            <span
              className={`all-bank-transaction__table-header-sort ${lastSort.createdAt ? "" : "up"}`}>CreatedAt</span>
          </li>
          <li onClick={() => changeSort("updatedAt")} className={`all-bank-transaction__table-header-sort-button`}>
            <span
              className={`all-bank-transaction__table-header-sort ${lastSort.updatedAt ? "" : "up"}`}>UpdatedAt</span>
          </li>
          <li className={`all-bank-transaction__table-header-sort-button`}>Account Number</li>
          <li className={`all-bank-transaction__table-header-sort-button`}>Account Name</li>
          <li className={`all-bank-transaction__table-header-sort-button`}>Bank Name</li>
          <li className={`all-bank-transaction__table-header-sort-button`}>Current Status</li>
          <li className={`all-bank-transaction__table-header-sort-button`}>Current Balance</li>
          <li className={`all-bank-transaction__table-header-sort-button`}>Switch</li>
        </ul>
        <div className="all-bank-transaction__table-body">
          {sortedReports?.map((report, index) =>
            <ul key={report.requestId + index}
                className="all-bank-transaction__table-body-row">
              <li className="all-bank-transaction__table-body-row-date">
                <div>{moment(report?.createdAt).format("DD.MM.YY")}</div>
                <div className="time">{moment(report?.createdAt).format("LTS")}</div>
              </li>
              <li className="all-bank-transaction__table-body-row-date">
                {report.updatedAt &&
                  <div>
                    <div>{moment(report.updatedAt).format("DD.MM.YY")}</div>
                    <div className="time">{moment(report.updatedAt).format("LTS")}</div>
                  </div>}
              </li>
              <li>{report?.accountNumber}</li>
              <li>{report?.accountName}</li>
              <li>{report?.bankName}</li>
              <li>{report?.currentStatus}</li>
              <li>{report?.currentBalance}</li>
              <li><SwitchCustom defaultValue={report?.switch} onChange={() => switchToggle(index,report?.accountNumber,report?.switch)} type="white" /></li>
            </ul>
          )}
        </div>
        <div className="all-bank-transaction__table-row-date">
          <div className="all-bank-transaction__table-paginaton">
            <PaginationCustom
              totalPages={allPages}
              activePage={activePage}
              changeActivePage={page => changePage(page)}
            />
          </div>
        </div>
      </div>
    </div>
  )
};