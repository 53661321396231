import moment from "moment"
import { useEffect, useRef } from "react"
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import ButtonCustom from "../../../components/ButtonCustom"
import InfoPopUp from "../../../components/InfoPopUp"
import ModalCustom from "../../../components/ModalCustom"
// import { FileUploader } from "react-drag-drop-files"
import { HOST, useAuth } from "../../../Providers/AuthProvider"
import { toastr } from "react-redux-toastr"
import { NEFTExcel } from "./helpers/NeftExcel"
import { FileUploader } from "react-drag-drop-files"
import { NewExcel } from "./helpers/NewExcel"
import { IdfcExcel } from "./helpers/IdfcExcel"

export const ExportedBathcesInnerTable = () => {
  const [dwReports, setDwReports] = useState([])
  const [sortedReports, setSortedReports] = useState([])
  const { requestsToApi } = useAuth()
  const { state } = useLocation()
  const [selectedCompleteReport, setSelectedCompleteReport] = useState()
  const [selectedReleaseReport, setSelectedReleaseReport] = useState()
  const [selectedReports, setSelectedReports] = useState([])
  const [selectedUploadFile, setSelectedUploadFile] = useState()
  const [selectedUploadNeft, setSelectedUploadNeft] = useState()
  // const [newUpload, setNewUpload] = useState(false)
  const [completeType, setCompleteType] = useState(false)

  const token = sessionStorage.getItem("accessToken")
  const history = useHistory()


  useEffect(() => {
    handleDwReport()
  }, [state.report])
  console.log(state.data)
  const handleDwReport = async () => {
    try {
      const responseDwReport = await requestsToApi.get("/banking/dw-report" + "?batchId=" + state.report)
      setDwReports(responseDwReport.data.result)
      setSortedReports(responseDwReport.data.result.sort((a, b) => {
        if (a.createdAt > b.createdAt) return -1
      }))
    } catch (error) {
      console.log(error?.message)
    }
  }

  const selecteAll = () => {
    setSelectedReports(sortedReports)
  }
  const deSelectAll = () => {
    setSelectedReports([])
  }
  const selectOne = (report, checked) => {
    // console.log([...selectedReports,report],checked)
    try {
      if (checked) {
        setSelectedReports([...selectedReports, report])
      } else {
        let temp = selectedReports.filter((e) => e.requestId !== report.requestId)
        setSelectedReports(temp)
      }
    } catch (error) {

    }

  }

  return (
    <div className="exported-batches">
      <div className="exported-batches__header-wrapper">
        <div className="exported-batches__header">
          <a onClick={() => history.push("/exports/exported-batches")}><h1>Batches</h1></a>
          <h1>{">>Batch Details #"} {state.report}</h1>
        </div>
      </div>
      <div className="row m-2 selectionbar">
        <ButtonCustom
          onClick={selecteAll}
          disabled={!dwReports?.length}
        >Select All</ButtonCustom>
        <ButtonCustom
          onClick={deSelectAll}
          disabled={!dwReports?.length}
        >Deselect All</ButtonCustom>

        <ButtonCustom
          onClick={() => setSelectedCompleteReport(selectedReports)}
          disabled={!selectedReports?.length}
          color="cyan">
          <span>Complete Selected</span>
        </ButtonCustom>
        <ButtonCustom
          onClick={() => setSelectedReleaseReport(selectedReports)}
          disabled={!selectedReports?.length}
          color="red">
          <span>Release Selected</span>
        </ButtonCustom>

        <div style={{ marginRight: "20px" }}>{selectedReports.length} Rows Selected.</div>
        <ButtonCustom
          // style={{marginLeft: '15px'}}
          onClick={() => setSelectedUploadFile(sortedReports)}
          //   disabled={!dwReports?.length}
        >Complete ICICI</ButtonCustom>
        <ButtonCustom
          onClick={() => {
            setSelectedUploadNeft(sortedReports)
            setCompleteType("IDFC")}}
        >Complete IDFC</ButtonCustom>
        <ButtonCustom
          onClick={() => {
            setSelectedUploadNeft(sortedReports)
            setCompleteType("EQUI")}}
        >Complete Equitas</ButtonCustom>
        <ButtonCustom
          onClick={() => {
            setSelectedUploadNeft(sortedReports)
            setCompleteType("YES")}}
        >Complete YES bank</ButtonCustom>

      <ButtonCustom
        onClick={(e) => e.stopPropagation()}><a
        href={HOST + "/Banking/withdraw/export-batch/csv?batchId=" + state.report + "&downloadtoken=" + token}
        target="_blank" download rel="noreferrer">
        <span>Download CSV</span>
      </a></ButtonCustom>

        <ButtonCustom
          onClick={(e) => e.stopPropagation()}><a
          href={HOST + "/Banking/withdraw/export-batch/csv?batchId=" + state.report + "&downloadtoken=" + token + "&format=SMARTPAY"}
          target="_blank" download rel="noreferrer">
          <span>SMARTPAY CSV</span>
        </a></ButtonCustom>

      <ButtonCustom
        onClick={(e) => e.stopPropagation()}><a
        href={HOST + "/Banking/withdraw/export-batch/csv?batchId=" + state.report + "&downloadtoken=" + token + "&format=YESBANK"}
        target="_blank" download rel="noreferrer">
        <span>YESBANK TXT</span>
      </a></ButtonCustom>

      <ButtonCustom
        onClick={(e) => e.stopPropagation()}><a
        href={HOST + "/Banking/withdraw/export-batch/excel?batchId=" + state.report + "&downloadtoken=" + token + "&format=AUBANK"}
        target="_blank" download rel="noreferrer">
        <span>AUBANK XLS</span>
      </a></ButtonCustom>

      <ButtonCustom
        onClick={(e) => e.stopPropagation()}><a
        href={HOST + "/Banking/withdraw/export-batch/excel?batchId=" + state.report + "&downloadtoken=" + token + "&format=BIJ_AU"}
        target="_blank" download rel="noreferrer">
        <span>BIJAL AU XLS</span>
      </a></ButtonCustom>

      <ButtonCustom
        onClick={(e) => e.stopPropagation()}><a
        href={HOST + "/Banking/withdraw/export-batch/excel?batchId=" + state.report + "&downloadtoken=" + token + "&format=IDFC"}
        target="_blank" download rel="noreferrer">
        <span>IDFC XLS</span>
      </a></ButtonCustom>

        <ButtonCustom
          onClick={(e) => e.stopPropagation()}><a
          href={HOST + "/Banking/withdraw/export-batch/excel?batchId=" + state.report + "&downloadtoken=" + token + "&format=EQUI"}
          target="_blank" download rel="noreferrer">
          <span>EQUI XLS</span>
        </a></ButtonCustom>

        <ButtonCustom
          onClick={(e) => e.stopPropagation()}><a
          href={HOST + "/Banking/withdraw/export-batch/excel?batchId=" + state.report + "&downloadtoken=" + token + "&format=RBL"}
          target="_blank" download rel="noreferrer">
          <span>RBL XLS</span>
        </a></ButtonCustom>

    </div>
  <div className="exported-batches__table">
    <ul className="exported-batches__table-header">
      <li>
      </li>
      <li>CreatedAt</li>
      <li>CompletedAt</li>
      <li>ID</li>
      <li>LoginName</li>
      <li>Amount</li>
      <li>A/C</li>
      <li>IFSC</li>
      <li>Name</li>
      <li>BankName</li>
      <li>Status</li>
      <li>Action</li>
    </ul>
    <div className="exported-batches__table-body">
      {
        sortedReports?.sort((a, b) => b.status.localeCompare(a.status))?.map((dwReport) => (
          <div className="exported-batches__table-row">
            <ul className="exported-batches__table-row-main-list">
              <li><input
                className="custom-checkbox"
                id="bet-settings__check"
                onChange={(e) => selectOne(dwReport, e.target.checked)}
                type="checkbox"
                checked={selectedReports.find((e) => e.requestId == dwReport.requestId) ? true : false}
              /></li>
              <li className="exported-batches__list-date-cell">
                <span>{dwReport?.createdAt ? moment(dwReport?.createdAt).format("DD.MM.YY") : null}</span>
                <span className="time">{moment(dwReport.createdAt).format("LTS")}</span>
              </li>
              <li className="exported-batches__list-date-cell">
                {dwReport.updatedAt &&
                  <div>
                    <span>{moment(dwReport.updatedAt).format("DD.MM.YY")}</span>
                    <span className="time">{moment(dwReport.updatedAt).format("LTS")}</span>
                  </div>}
              </li>
              <li>{dwReport.requestId}</li>
              <li>{dwReport.loginName}</li>
              <li>{dwReport.amount}</li>
              <li>{dwReport.account.accountNo}</li>
              <li>{dwReport.account.IFSC}</li>
              <li>{dwReport.account.accountHolderName}</li>
              <li>{dwReport.account.bankName}</li>
              <li>{dwReport.status}</li>
              <li>
                <button onClick={() => setSelectedCompleteReport([dwReport])}
                        className="exported-batches__table-button exported-batches__complete_button">
                  <span>Complete</span>
                </button>
                <button onClick={() => setSelectedReleaseReport([dwReport])}
                        className="exported-batches__table-button exported-batches__download_button">
                  <span>Release</span>
                </button>
              </li>
            </ul>
          </div>
        ))
      }
    </div>
  </div>
  {
    selectedCompleteReport ?
      <ConfirmActionModal data={selectedCompleteReport} reloadData={handleDwReport} selectedReports={selectedReports}
                            closeFunc={() => setSelectedCompleteReport(null)} /> : ""}
      {selectedReleaseReport ? <ConfirmReleaseActionModal data={selectedReleaseReport} reloadData={handleDwReport}
                                                       selectedReports={selectedReports}
                                                          closeFunc={() => setSelectedReleaseReport(null)} /> : ""}
      {selectedUploadFile ? <ConfirmUploadFileModal data={selectedUploadFile} reloadData={handleDwReport}
                                                    completeType={completeType}
                                                 closeFunc={() => setSelectedUploadFile(null)} /> : ""}
   {selectedUploadNeft ? <ConfirmUploadNeftModal data={selectedUploadNeft} reloadData={handleDwReport}
                                                 completeType={completeType}
                                                 batchId={state.report.toString()}
                                                 closeFunc={() => setSelectedUploadNeft(null)} /> : ""}
  {/* {selectedUploadNeft ? <ConfirmUploadNeftModal data={selectedUploadNeft} reloadData={handleDwReport}
                                                 closeFunc={() => setSelectedUploadNeft(null)} /> : ""
  } */}
</div>
)
}


const ConfirmActionModal = ({
                              data, selectedReports, reloadData = () => {
  }, closeFunc = () => {
  }
                            }) => {
  const { requestsToApi } = useAuth()
  const utrInput = useRef(null)
  let [utrRefState, setUtrRefState] = useState("")
  const [disableButton, setDisableButton] = useState(true)
  let [utrRefValidationCheck, setUtrRefValidationCheck] = useState(false)
  let [utrRefExistCheck, setUtrRefExistCheck] = useState(true)
  const [isSending, setIsSending] = useState(false)

  // const validateUtr = (e) => {
  //     setDisableButton(e.target.value.length === 12 ? false : true )
  //     setUtrInput(e.target.value)
  // };
  let runUtrRefSetStateValidation = (e) => {
    utrRefOnInputChange(e)
    utrRefValidation(e)
  }

  let utrRefOnInputChange = (e) => {
    // let temp = e.target.value.split(".")[0]
    // temp = Number(temp)

    if (e.target.value.length >= 0) {
      setUtrRefState(e.target.value)
    } else {
      setUtrRefState("")
    }
  }
  let utrRefValidation = (e) => {
    // let utrRefToNum = Number(e.target.value);

    if (e.target.value.length === 12) {
      setUtrRefValidationCheck(true)
    } else {
      setUtrRefValidationCheck(false)
    }
  }

  const onSumbit = async () => {
    setIsSending(true)
    try {
      // e.preventDefault()
      // [
      //     {
      //         "requestId":"2209220052227160",
      //         "UTR":"12345abcdefg",
      //         "remark":""
      //     }
      // ]
      let reqData = data.map((e) => {
          return {
            requestId: e.requestId.toString(), UTR: utrRefState.toString(), remark: ""
          }
        }
      )
      console.log(reqData)
      const responseCompleteTxn = await requestsToApi.post("/banking/withdraw/complete", reqData)
      console.log(responseCompleteTxn)
      reloadData()
      setTimeout(() => closeFunc(), 500)
      if (responseCompleteTxn.data.success || responseCompleteTxn.data.status.statusCode == "0") {
        setTimeout(() => {
          setIsSending(false)
          toastr.success(`${responseCompleteTxn.data.result.message}`, responseCompleteTxn.data.result.errored && `${"Errored:" + responseCompleteTxn.data.result.errored}`)

        }, 1000)
      }


      // setDwReports(responseDwReport.data.result);
      // setSortedReports(responseDwReport.data.result.sort((a, b) => {if (a.createdAt > b.createdAt) return -1;}));
    } catch (error) {
      console.log(error?.message)
      setTimeout(() => {
        setIsSending(false)
      }, 1000)
      toastr.error("Error")
    }
  }
  return (
    <ModalCustom
      visible={data}
      closeFunc={closeFunc}
      className={"modal-confirm"}
      width="100%"
    >
      <div className="modal-confirm__content text-center">

        <h4>Are you sure you want to Complete Action?</h4>
        <div className="exported-batches__table" style={{ maxHeight: "60vh", overflow: "scroll" }}>
          <ul className={`exported-batches__table-header ${!selectedReports?.length ? "with-utr" : ""}`}>
            <li>CreatedAt</li>
            <li>CompletedAt</li>
            <li>ID</li>
            <li>LoginName</li>
            <li>Amount</li>
            <li>A/C</li>
            <li>IFSC</li>
            <li>Name</li>
            <li>BankName</li>
            <li>Status</li>
            {!selectedReports?.length ? <li>Utr</li> : <></>}
            <li>Action</li>
          </ul>
          <div className="exported-batches__table-body">
            {
              data && data.map((report) => (
                <div className="exported-batches__table-row">
                  <ul className={`exported-batches__table-row-main-list ${!selectedReports?.length ? "with-utr" : ""}`}>
                    <li className="exported-batches__list-date-cell">
                      <span>{moment(report.createdAt).format("DD.MM.YY")}</span>
                      <span className="time">{moment(report.createdAt).format("LTS")}</span>
                    </li>
                    <li className="exported-batches__list-date-cell">
                      {report.updatedAt &&
                        <div>
                          <span>{moment(report.updatedAt).format("DD.MM.YY")}</span>
                          <span className="time">{moment(report.updatedAt).format("LTS")}</span>
                        </div>}
                    </li>
                    <li>{report.requestId}</li>
                    <li>{report.loginName}</li>
                    <li>{report.amount}</li>
                    <li>{report.account.accountNo}</li>
                    <li>{report.account.IFSC}</li>
                    <li>{report.account.accountHolderName}</li>
                    <li>{report.account.bankName}</li>
                    <li>{report.status}</li>
                    <li>{report.account.bankName}</li>
                    <li>{report?.UTR}</li>
                    {/*{!selectedReports?.length ?*/}
                    {/*  <li>*/}
                    {/*    <input*/}
                    {/*      placeholder="UTR"*/}
                    {/*  className="input-utr"*/}
                    {/*  type="number"*/}
                    {/*  ref={utrInput}*/}
                    {/*  value={utrRefState}*/}
                    {/*  onChange={runUtrRefSetStateValidation}*/}
                    {/*/>*/}
                    {/*  </li>*/}
                    {/*:*/}
                    {/*  <></>*/}
                    {/*}*/}
                    <li>{report.action}</li>
                  </ul>
                </div>
              ))
            }
          </div>
        </div>

        <div className="deposit-entry__form-body">
          <div className="deposit-entry__form-input-wrapper">
            <div className="deposit-entry__form-input-wrapper">
              <label className="deposit-entry__top-input-label deposit-entry__label">UTR or Ref No</label>
              <input autoComplete="off" type="text" name="utr-ref" className="deposit-entry__form-input"
                     placeholder="UTR"
                     value={utrRefState}
                     ref={utrInput}
                     onChange={runUtrRefSetStateValidation}
              />
            </div>
          </div>
        </div>

        <div className="modal-confirm__controls">

          <ButtonCustom
            className="cancel-button"
            onClick={closeFunc}
          >
            Cancel
          </ButtonCustom>
          <ButtonCustom
            disabled={isSending || !(utrRefValidationCheck)}
            className={`${utrRefValidationCheck ? "unlock-button" : ""} deposit-entry__form-button deposit-entry__form-save `}
            onClick={() => onSumbit()} width="76px" height="32px" color="green">Confirm</ButtonCustom>

        </div>
      </div>
    </ModalCustom>
  )
}
const ConfirmReleaseActionModal = ({
                                     data, selectedReports, reloadData = () => {
  }, closeFunc = () => {
  }
                                   }) => {
  const { requestsToApi } = useAuth()
  const [remarkInput, setRemarkInput] = useState("")
  const [disableButton, setDisableButton] = useState(true)
  const [isSending, setIsSending] = useState(false)


  const validateRemark = (e) => {
    setDisableButton(e.target.value.length > 5 ? false : true)
    setRemarkInput(e.target.value)
    };

  const onSumbit = async () => {
    setIsSending(true)
    try {
      const requestIds = data.map((item) => item.requestId.toString())

      // todo need array for batchId in API
      // const batchIds = data.map((item) => item.batchId )
            const batchIds = data[0]?.batchId.toString();

      const payload = {
        "batchId": batchIds,
                "requestIds": requestIds,
        // "remark": remarkInput || "",
      }
      const responseDwReport = await requestsToApi.post("/banking/withdraw/release-txns", payload)
      reloadData()
      setTimeout(() => closeFunc(), 500)
      if (responseDwReport.data.success || responseDwReport.data.status.statusCode == "0") {
        setTimeout(() => {
          setIsSending(false)
        }, 1000)
        // toastr.success(`${responseDwReport.data.result.message}`, `${responseDwReport.data.result.errored && 'Errored:', responseDwReport.data.result.message}`)
        toastr.success(`Success`, ``)

      }
    } catch (error) {
      console.log(error?.message)
      setTimeout(() => {
        setIsSending(false)
      }, 1000)
      // console.log(error.data)
      toastr.error(`Error`, ``)

    }
  }

  return (
    <ModalCustom
      visible={data}
      closeFunc={closeFunc}
      className={"modal-confirm"}
      // width="1000px"
    >
      <div className="modal-confirm__content text-center">

        <h4>Are you sure you want to Release Action?</h4>
        <div className="exported-batches__table" style={{ maxHeight: "60vh", overflow: "scroll" }}>
          <ul className={`exported-batches__table-header ${!selectedReports?.length ? "with-remark" : ""}`}>
            <li>CreatedAt</li>
            <li>CompletedAt</li>
            <li>ID</li>
            <li>LoginName</li>
            <li>Amount</li>
            <li>A/C</li>
            <li>IFSC</li>
            <li>Name</li>
            <li>BankName</li>
            <li>Status</li>
            {!selectedReports?.length ? <li>Remark</li> : <></>}
            <li>Action</li>
          </ul>
          <div className="exported-batches__table-body">
            {
              data && data.map((report) => (
                <div className="exported-batches__table-row">
                  <ul
                    className={`exported-batches__table-row-main-list ${!selectedReports?.length ? "with-remark" : ""}`}>
                    <li className="exported-batches__list-date-cell">
                      <span>{moment(report.createdAt).format("DD.MM.YY")}</span>
                      <span className="time">{moment(report.createdAt).format("LTS")}</span>
                    </li>
                    <li className="exported-batches__list-date-cell">
                      {report.updatedAt &&
                        <div>
                          <span>{moment(report.updatedAt).format("DD.MM.YY")}</span>
                          <span className="time">{moment(report.updatedAt).format("LTS")}</span>
                        </div>}
                    </li>
                    <li>{report.requestId}</li>
                    <li>{report.loginName}</li>
                    <li>{report.amount}</li>
                    <li>{report.account.accountNo}</li>
                    <li>{report.account.IFSC}</li>
                    <li>{report.account.accountHolderName}</li>
                    <li>{report.account.bankName}</li>
                    <li>{report.status}</li>
                    {!selectedReports?.length ?
                      <li>
                        <textArea
                          onChange={validateRemark}
                          value={remarkInput}
                        >
                        </textArea>
                      </li>
                      :
                      <></>
                    }
                    <li>{report.action}</li>
                  </ul>
                </div>
              ))
            }
          </div>
        </div>

        <div className="modal-confirm__controls">
          <ButtonCustom
            className="cancel-button"
            onClick={closeFunc}
          >
            Cancel
          </ButtonCustom>
          <ButtonCustom
            onClick={onSumbit}
            width="76px"
            height="32px"
            color="green"
            color={(selectedReports?.length ? true : !disableButton) ? "green" : "gray"}
            disabled={isSending || (selectedReports?.length ? false : disableButton)}
          >
            Relase
          </ButtonCustom>
        </div>
      </div>
    </ModalCustom>
  )
}

const ConfirmUploadFileModal = ({
                                  data, reloadData, closeFunc = () => {
  }
                                }) => {
  const { requestsToApi } = useAuth()
  const [newArr, setnewArr] = useState([])
  const [resultArray, setResultArray] = useState([])
  const [filteredArray, setFilteredArray] = useState([])
  const [requestedData, setRequestedData] = useState()
  const [finishFiltering, setFinishFiltering] = useState("")
  const [isVisibleInfoPopUp, setIsVisibleInfoPopUp] = useState(false)
  const [responseMessage, setResponseMessage] = useState(false)

  const fileTypes = ["TXT"]

  const onSumbit = async () => {
    try {
      let payload = filteredArray.map(item => {
        return {
          requestId: item?.requestId?.toString() || "",
          UTR: item?.UTR?.toString() || "",
          remark: item?.remark?.toString() || ""
        }
      })
      const responseDwReport = await requestsToApi.post(
        "https://payments-test.gemexch.bet/api/Banking/withdraw/complete",
        payload
      )
      setTimeout(() => closeFunc(), 10000)
      reloadData()
      console.log("responseDwReport", responseDwReport)
      if (responseDwReport.data.result.message) {
        console.log("SETTED TSTR")
        setIsVisibleInfoPopUp(true)
        setResponseMessage(responseDwReport.data.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  // const [file, setFile] = useState(null)
  const handleChangeFile = e => {
    //   setFile(file)
    console.log(e)
    //   setIsImage(URL.createObjectURL(file))
    const TXTfile = e.target.files[0],
      reader = new FileReader()
    reader.onload = function(event) {
      setnewArr(event.target.result.split("PMT"))
      // holder.innerText = event.target.result;
    }
    reader.readAsText(TXTfile)
  }
  console.log(data)

  useEffect(() => {
    if (newArr) {
      setResultArray(
        newArr
          .slice(1)
          .map((i, index) => i.split("|")[1])
          .map(item => {
            if (item?.split("~")[3] == "SUC") {
              return {
                amount: `${item?.split("~")[0]}`,
                UTR: `${item?.split("~")[1]}`,
                requestId: `${item?.split("~")[2]}`,
                status: `${item?.split("~")[3]}`,
                remark: `${item?.split("~")[4]}`
              }
            }
          })
      )
    }
  }, [newArr])
  console.log("resultArray", resultArray)

  useEffect(() => {
    // console.log(first)
    // setFilteredArray(resultArray.map(itemSmall => data.find(item2 => item2.requestId === itemSmall.requestId)))
    let inArr = []
    if (data && resultArray.length) {
      data.map(item => {
        resultArray.map(item2 => {
          // console.log(item.requestId, item2.requestId)
          console.log("2222222")

          if (item?.requestId == item2?.requestId) {
            console.log("11111", item?.requestId, item2?.requestId)
            inArr.push({ ...item, UTR: item2?.UTR, remark: item2?.remark })
          }
        })
      })
      if (!inArr.length) {
        console.log("maosdoasd")
        setFinishFiltering("Looks like ID doesn't match")
      } else {
        setFinishFiltering("")
      }
    }
    setFilteredArray(inArr)
  }, [resultArray])

  console.log("filteredArray", filteredArray)
  // const [file, setFile] = useState(null);
  // const handleChange = (file) => {
  //   setFile(file);
  //   clg
  // };
  console.log("resultArray", resultArray)
  return (
    <ModalCustom
      visible={data}
      closeFunc={closeFunc}
      className={"modal-confirm"}
      width={filteredArray.length ? "100%" : "30%"}
    >
      {responseMessage?.message && (
        <div className="responseMessage">
          {responseMessage?.message}
          {responseMessage?.errored && <span>Errored at ID:</span>}
          {responseMessage?.errored?.map((item) => {
            console.log(item)
            return <span style={{ color: "red", fontSize: "25px" }}>{item}</span>
          })}
        </div>
      )}

      {!filteredArray.length ? (
        <div className="modal-confirm__content text-center"
             style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <input name="myFile" type="file" onChange={e => handleChangeFile(e)} />

          {finishFiltering && <span style={{ marginTop: "20px" }}>{finishFiltering}</span>}

          <div className="modal-confirm__controls">
            <ButtonCustom
              className="cancel-button"
              onClick={closeFunc}
            >
              Cancel
            </ButtonCustom>
          </div>
        </div>
      ) : (
        <div className="modal-confirm__content text-center">
          {/* <InfoPopUp title={responseMessage} isError={false} isVisible={responseMessage}/> */}
          <h4>Are you sure you want to Release Action?</h4>
          <div className="exported-batches__table" style={{ maxHeight: "60vh", overflow: "scroll" }}>
            <ul className={"exported-batches__table-header uploaded"}>
              <li>CreatedAt</li>
              <li>CompletedAt</li>
              <li>ID</li>
              <li>LoginName</li>
              <li>Amount</li>
              <li>A/C</li>
              <li>IFSC</li>
              <li>Name</li>
              <li>BankName</li>
              <li>Status</li>
              <li>UTR</li>
              <li>Remark</li>
              <li>Action</li>
            </ul>
            <div className="exported-batches__table-body" style={{ width: "fit-content" }}>
              {filteredArray &&
                filteredArray.map(report => (
                  <div className="exported-batches__table-row">
                    <ul className={"exported-batches__table-row-main-list uploaded"}>
                      <li className="exported-batches__list-date-cell">
                        <span>{moment(report?.createdAt).format("DD.MM.YY")}</span>
                        <span className="time">{moment(report?.createdAt).format("LTS")}</span>
                      </li>
                      <li className="exported-batches__list-date-cell">
                        {report?.updatedAt && (
                          <div>
                            <span>{moment(report?.updatedAt).format("DD.MM.YY")}</span>
                            <span className="time">{moment(report?.updatedAt).format("LTS")}</span>
                          </div>
                        )}
                      </li>
                      <li>{report?.requestId}</li>
                      <li>{report?.loginName}</li>
                      <li>{report?.amount}</li>
                      <li>{report?.account?.accountNo}</li>
                      <li>{report?.account?.IFSC}</li>
                      <li>{report?.account?.accountHolderName}</li>
                      <li>{report?.account?.bankName}</li>
                      <li>{report?.status}</li>
                      <li>{report?.UTR}</li>
                      <li>{report?.remark}</li>
                      <li>{report.action}</li>
                    </ul>
                  </div>
                ))}
            </div>
          </div>

          <div className="modal-confirm__controls">
            <ButtonCustom className="cancel-button" onClick={closeFunc}>
              Cancel
            </ButtonCustom>
            <ButtonCustom
              onClick={onSumbit}
              width="76px"
              height="32px"
              color="green"
              //   disabled={(selectedReports?.length ? false : disableButton)}
            >
              Complete
            </ButtonCustom>
          </div>
        </div>
      )}
    </ModalCustom>
  )
}


const ConfirmUploadNeftModal = ({ data, reloadData, batchId, newUpload, completeType, closeFunc = () => {} }) => {
  const { requestsToApi } = useAuth()
  const [newArr, setnewArr] = useState([])
  const [resultArray, setResultArray] = useState([])
  const [filteredArray, setFilteredArray] = useState([])
  const [requestedData, setRequestedData] = useState()
  const [finishFiltering, setFinishFiltering] = useState("")
  const [isVisibleInfoPopUp, setIsVisibleInfoPopUp] = useState(false)
  const [responseMessage, setResponseMessage] = useState(false)

  const fileTypes = ["XLSX"]

  const onSumbit = async () => {
    try {
      let payload = filteredArray.map(item => {
        return {
          requestId: item?.requestId?.toString() || "",
          UTR: item?.UTR?.toString() || "",
          remark: item?.remark?.toString() || ""
        }
      })
      const responseDwReport = await requestsToApi.post(
        "https://payments-test.gemexch.bet/api/Banking/withdraw/complete",
        payload
      )
      setTimeout(() => closeFunc(), 2000)
      reloadData()
      console.log("responseDwReport", responseDwReport)
      if (responseDwReport.data.result.message) {
        console.log("SETTED TSTR")
        setIsVisibleInfoPopUp(true)
        setResponseMessage(responseDwReport.data.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  // // const [file, setFile] = useState(null)
  // const handleChangeFile = e => {
  //   //   setFile(file)
  //   console.log(e)
  //   //   setIsImage(URL.createObjectURL(file))
  //   const TXTfile = e.target.files[0],
  //     reader = new FileReader()
  //   reader.onload = function (event) {
  //     setnewArr(event.target.result.split("PMT"))
  //     // holder.innerText = event.target.result;
  //   }
  //   reader.readAsText(TXTfile)
  // }
  // console.log(data)

  // useEffect(() => {
  //   if (newArr) {
  //     setResultArray(
  //       newArr
  //         .slice(1)
  //         .map((i, index) => i.split("|")[1])
  //         .map(item => {
  //           if (item?.split("~")[3] == "SUC") {
  //             return {
  //               amount: `${item?.split("~")[0]}`,
  //               UTR: `${item?.split("~")[1]}`,
  //               requestId: `${item?.split("~")[2]}`,
  //               status: `${item?.split("~")[3]}`,
  //               remark: `${item?.split("~")[4]}`
  //             }
  //           }
  //         })
  //     )
  //   }
  // }, [newArr])
  // console.log("resultArray", resultArray)

  // useEffect(() => {
  //   // console.log(first)
  //   // setFilteredArray(resultArray.map(itemSmall => data.find(item2 => item2.requestId === itemSmall.requestId)))
  //   let inArr = []
  //   if (data && resultArray.length) {
  //     data.map(item => {
  //       resultArray.map(item2 => {
  //         // console.log(item.requestId, item2.requestId)
  //         console.log("2222222")

  //         if (item?.requestId == item2?.requestId) {
  //           console.log("11111", item?.requestId, item2?.requestId)
  //           inArr.push({ ...item, UTR: item2?.UTR, remark: item2?.remark })
  //         }
  //       })
  //     })
  //     if (!inArr.length) {
  //       console.log("maosdoasd")
  //       setFinishFiltering("Looks like ID doesn't match")
  //     } else {
  //       setFinishFiltering("")
  //     }
  //   }
  //   setFilteredArray(inArr)
  // }, [resultArray])

  // console.log("filteredArray", filteredArray)
  // // const [file, setFile] = useState(null);
  // // const handleChange = (file) => {
  // //   setFile(file);
  // //   clg
  // // };
  console.log("resultArray", resultArray, data)

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  useEffect(() => {
    let filRes = []
    let usedUTR = [];
    if (resultArray) {
      if (completeType === "YES") {
        resultArray.map(item => {
          data.map(dataItem => {
            console.log("item.acName", Number(item.amount).toFixed())
            if (
              item.acNo === dataItem.account.accountNo.slice(-4) &&
              item.acName.replace(/[^a-zA-Z0-9_.]+/g, "").replace(/ /g,'').substring(0, 10) === dataItem.account.accountHolderName.replace(/[^a-zA-Z0-9_.]+/g, "").replace(/ /g,'').substring(0, 10) &&
              Number(item.amount).toFixed() == dataItem.amount
            ) {
              let alreadyUtr = data.filter(a => {
                return a.UTR?.substring(0,12) == item.UTR?.substring(0,12)
              })
              if (usedUTR.indexOf(item.UTR) === -1 && alreadyUtr.length === 0) {
                dataItem.UTR = item.UTR;
                usedUTR.push(item.UTR);
              }
              filRes.push(dataItem)
            }

            // if (item.acNo === dataItem.account.accountNo.slice(-4) && item.acNo == '8367'){
            //
            //   console.log(item.acNo, item.acName, Number(item.amount).toFixed())
            //   console.log(dataItem.account.accountNo.slice(-4), dataItem.account.accountHolderName.replace(/[^a-zA-Z0-9_.]+/g, "").replace(/ /g,'').substring(0, 20), dataItem.amount)
            //   console.log(item.UTR)
            // }
          })
        })
      } else if(completeType === "EQUI"){
        resultArray.map(item => {
          data.map(dataItem => {
            if (
              item.payeeAccNo === dataItem.account.accountNo &&
              item.payeeIFSC === dataItem.account.IFSC.toUpperCase() &&
              item.amount === dataItem.amount
            ) {

              // if (item.payeeAccNo === dataItem.account.accountNo && item.payeeAccNo == '7372500100026401'){
              //
              //   console.log(item.payeeAccNo, dataItem.account.accountNo, Number(item.amount).toFixed())
              //   console.log(item.payeeIFSC, dataItem.account.IFSC.toUpperCase(), dataItem.amount)
              //   console.log(item.amount, dataItem.amount)
              // }

              let alreadyUtr = data.filter(a => {
                return a.UTR == item.UTR
              })
              if (usedUTR.indexOf(item.UTR) === -1 && alreadyUtr.length === 0) {
                dataItem.UTR = item.UTR;
                usedUTR.push(item.UTR);
              }
              filRes.push(dataItem)
            }


          })
        })
      } else if (completeType === "IDFC") {
        resultArray.map(item => {
          data.map(dataItem => {
            if (
              item.requestId == dataItem.requestId
            ) {
              // if (item.payeeAccNo === dataItem.account.accountNo && item.payeeAccNo == '7372500100026401'){
              //
              //   console.log(item.payeeAccNo, dataItem.account.accountNo, Number(item.amount).toFixed())
              //   console.log(item.payeeIFSC, dataItem.account.IFSC.toUpperCase(), dataItem.amount)
              //   console.log(item.amount, dataItem.amount)
              // }
              dataItem.UTR = item.UTR;
              filRes.push(dataItem)
            }
          })
        })
      }
    }
    console.log("filRes", filRes)
    setFilteredArray(removeDuplicates(filRes))
  }, [resultArray])

  console.log(filteredArray);
  return (
    <ModalCustom
      visible={data}
      closeFunc={closeFunc}
      className={"modal-confirm"}
      width='100%'
    >
      {responseMessage?.message && (
        <div className="responseMessage">
          {responseMessage?.message}
          {responseMessage?.errored && <span>Errored at ID:</span>}
          {responseMessage?.errored?.map(item => {
            console.log(item)
            return <span style={{ color: "red", fontSize: "25px" }}>{item}</span>
          })}
        </div>
      )}

      {!filteredArray?.length > 0 ? (
        <div
          className="modal-confirm__content text-center"
          style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        >
          {/* <input name="myFile" type="file" onChange={e => YESExcel(e)} /> */}
          <span className="deposit-entry__top-select-label deposit-entry__label">NEFT Excel</span>
       {completeType == "YES" &&
       <FileUploader
          handleChange={f => NewExcel(f, setResultArray, batchId)}
          types={["XLSX"]}
          name="file"
          classes="lll"
        />}
          { completeType == "EQUI" && <FileUploader
          handleChange={f => NEFTExcel(f, setResultArray)}
          types={["XLSX"]}
          name="file"
          classes="lll"
        />}
          { completeType == "IDFC" && <FileUploader
            handleChange={f => IdfcExcel(f, setResultArray)}
            types={["XLSX"]}
            name="file"
            classes="lll"
          />}
      }

          {finishFiltering && <span style={{ marginTop: "20px" }}>{finishFiltering}</span>}

          <div className="modal-confirm__controls">
            <ButtonCustom
              className="cancel-button"
              onClick={closeFunc}
            >
              Cancel
            </ButtonCustom>
          </div>
        </div>
      ) : (
        <div className="modal-confirm__content text-center">
          {/* <InfoPopUp title={responseMessage} isError={false} isVisible={responseMessage}/> */}
          <h4>Are you sure you want to Release Action?</h4>
          <div className="exported-batches__table" style={{ maxHeight: "60vh", overflow: "scroll" }}>
            <ul className={"exported-batches__table-header uploaded"}>
              <li>CreatedAt</li>
              <li>CompletedAt</li>
              <li>ID</li>
              <li>LoginName</li>
              <li>Amount</li>
              <li>A/C</li>
              <li>IFSC</li>
              <li>Name</li>
              <li>BankName</li>
              <li>Status</li>
              <li>UTR</li>
              <li>Remark</li>
              <li>Action</li>
            </ul>
            <div className="exported-batches__table-body" style={{ width: "fit-content" }}>
              {filteredArray &&
                filteredArray.map(report => (
                  <div className="exported-batches__table-row">
                    <ul className={"exported-batches__table-row-main-list uploaded"}>
                      <li className="exported-batches__list-date-cell">
                        <span>{moment(report?.createdAt).format("DD.MM.YY")}</span>
                        <span className="time">{moment(report?.createdAt).format("LTS")}</span>
                      </li>
                      <li className="exported-batches__list-date-cell">
                        {report?.updatedAt && (
                          <div>
                            <span>{moment(report?.updatedAt).format("DD.MM.YY")}</span>
                            <span className="time">{moment(report?.updatedAt).format("LTS")}</span>
                          </div>
                        )}
                      </li>
                      <li>{report?.requestId}</li>
                      <li>{report?.loginName}</li>
                      <li>{report?.amount}</li>
                      <li>{report?.account?.accountNo}</li>
                      <li>{report?.account?.IFSC}</li>
                      <li>{report?.account?.accountHolderName}</li>
                      <li>{report?.account?.bankName}</li>
                      <li>{report?.status}</li>
                      <li>{report?.UTR}</li>
                      <li>{report?.remark}</li>
                      <li>{report.action}</li>
                    </ul>
                  </div>
                ))}
            </div>
          </div>

          <div className="modal-confirm__controls">
            <ButtonCustom className="cancel-button" onClick={closeFunc}>
              Cancel
            </ButtonCustom>
            <ButtonCustom
              onClick={onSumbit}
              width="76px"
              height="32px"
              color="green"
              //   disabled={(selectedReports?.length ? false : disableButton)}
            >
              Complete
            </ButtonCustom>
          </div>
        </div>
      )}
    </ModalCustom>
  )
}

