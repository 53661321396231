import { useEffect, useState } from "react"
import TextInputCustom from "../../../components/TextInputCustom"
import "./index.scss"
import RadioCustom from "../../../components/RadioCustom"
import { Button } from "react-bootstrap"
import ButtonCustom from "../../../components/ButtonCustom"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../../Providers/AuthProvider"
import { numberWithCommas } from "../../../utils/dataTransform"
import { loginValidation, passValidation } from "../../../utils/validation"
import { useStorage } from "../../../Providers/StorageProvider"
import AlertCustom from "../../../components/AlertCustom"

export default () => {
  const { requestsToApi } = useAuth()
  const history = useHistory()
  const {
    balance: { availableBalance: availableCredit },
    userName: myName,
    childrenLevel
  } = useStorage()
  const [inputValues, setInputValues] = useState({
    userPrefix: childrenLevel === "Member" ? myName + 'M' : myName,
    userSuffix: "",
    user: "",
    pass: "",
    repeatPass: "",
    status: "ACTIVE",
    cusPosition: null,
    // maMaxPosition: null,
    credit: "",
    notes: ""
  })
  const [suffixWarning, setSuffixWarning] = useState(false)
  const [loginWarning, setLoginWarning] = useState(false)
  const [redAlertMessage, setRedAlertMessage] = useState(null)
  const [greenAlertMessage, setGreenAlertMessage] = useState(null)

  const changeInputValue = key => value => {
    setInputValues({ ...inputValues, [key]: value })
    
  }

  const inputNameToggle = num => value => {
    if (inputValues.userSuffix[num] !== value.slice(-1)[0]) {
      const newSuffix = value
        ? inputValues.userSuffix.slice(0, num) + value[value.length - 1] + inputValues.userSuffix.substring(num + 1)
        : inputValues.userSuffix.slice(0, num) + inputValues.userSuffix.substring(num + 1)
      if (newSuffix.length === 2) {
        checkSuffixAvailable(newSuffix)
        // setSuffixWarning(!checkSuffixAvailable(newSuffix))
      } else {
        setSuffixWarning(true)
      }
      setInputValues({
        ...inputValues,
        userSuffix: newSuffix
      })
    }

    document.getElementById("name-input-2").click()
  }

  const radioToggle = e => {
    const status = e.target.attributes["status"].value
    setInputValues({ ...inputValues, status })
  }

  const addCommas = nStr => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    if (Math.abs(+nStr)  < 100) {
      let res = 0
      x.length > 1 ?
        res = x1 + x2.substring(0,3) :
        res = x1 + '.00'
      return res
    }
    return x1
  }

  const passwordMatching = () => inputValues.pass && inputValues.pass === inputValues.repeatPass
  const nameValidation = key => () => /^[0-9]{1}/.test(inputValues.userSuffix[key])
  const creditValidation = () =>
    inputValues.credit && 0 <= Number(inputValues.credit) && Number(inputValues.credit) <= availableCredit
  const positionValidation = key => () =>
    inputValues[key] && 0 <= Number(inputValues[key]) && Number(inputValues[key]) <= 80

  const checkLoginAvailable = async () => {
    try {
      const response = await requestsToApi.get(`agency/${myName}/account/user/${inputValues.user}?checkAvailable=true`)
      setLoginWarning(!response.data.result)
    } catch (error) {
      setLoginWarning(true)
      console.log("error", error?.message)
    }
  }

  const checkSuffixAvailable = async suffix => {
    try {
      const response = await requestsToApi.get(
        `agency/${myName}/agency-mgmt/downline/${inputValues.userPrefix + suffix}?checkAvailable=true`
      )

      setSuffixWarning(!response.data.result)
      // return response.data.result
    } catch (error) {
      console.log(error?.message)
    }
  }

  const submitToggle = async () => {
    try {
      // Username not empty
      // Username not warning
      // login name not empty
      // login name not warning
      // both password matching and greater than 8 characters
      // credit limits checks
      // position taking should be filled.

      if (
        !suffixWarning &&
        inputValues.userSuffix &&
        !loginWarning &&
        inputValues.user &&
        passValidation(inputValues.pass) &&
        passwordMatching() &&
        creditValidation() &&
        positionValidation("cusPosition")
      ) {
        const reqData = {
          betSettings: [],
          commissions: [],
          creditLimits: {
            creditLimit: inputValues.credit,
            maxAgentCredit: "",
            maxMemberCredit: "",
            paymentThreshold: 0
          },
          personalInformation: {
            name: inputValues.userPrefix + inputValues.userSuffix,
            loginName: inputValues.user,
            password: inputValues.pass,
            status: inputValues.status,
            level: childrenLevel,
            master: childrenLevel !== "Member",
            notes: inputValues.notes
          },
          posTakings: [
            {
              displayName: "All Sports",
              maxDownLinePosition: 0,
              parentAutoPosition: 0,
              parentPosition: inputValues.cusPosition,
              posTakingGroup: "All Sports"
            }
          ]
        }
        await requestsToApi.post(
          "agency/" + myName + "/agency-mgmt/downline?parent=" + myName + "&dl=" + inputValues.user,
          reqData
        )
        setGreenAlertMessage(`${childrenLevel} was created successfully.`)
        setTimeout(() => history.push("/agency-management/downline"), 1500)
      } else {
        setRedAlertMessage("Sorry, some of the submitted data was wrong. Please check your input and try again.")
      }
    } catch (error) {
      console.log("req error", error)
      setRedAlertMessage("Sorry, some of the submitted data was wrong. Please check your input and try again.")
    }
  }

  useEffect(async () => {
    try {
      const response = await requestsToApi.get(`agency/${myName}/agency-mgmt/downline/form-info?parent=${myName}&dl=`)
      const formInfo = response.data.result.formInfo
      if (childrenLevel === "Member") {
        formInfo.userPrefix = formInfo.userPrefix + 'M'
      }
      setInputValues({ ...inputValues, ...response.data.result.formInfo })
      // checkSuffixAvailable(response.data.result.formInfo.userPrefix + response.data.result.formInfo.iuserPrefix)
    } catch (error) {
      console.log(error?.message)
    }
  }, [])
  return (
    <div className="new-ma">
      <div className="new-ma__containers">
        <div className="new-ma__container new-ma__information">
          <h2>Information</h2>
          <hr />
          <h3>Username</h3>
          <span>{inputValues.userPrefix} -</span>
          <TextInputCustom
            id="name-input-1"
            type="text"
            onClick={() => document.getElementById("name-input-1").select()}
            onChange={inputNameToggle(0)}
            checkFunc={nameValidation(0)}
            className="text-input_small"
            color="white"
            error={suffixWarning}
          >
            {inputValues.userSuffix[0]}
          </TextInputCustom>
          -
          <TextInputCustom
            id="name-input-2"
            type="text"
            onClick={() => document.getElementById("name-input-2").select()}
            onChange={inputNameToggle(1)}
            checkFunc={nameValidation(1)}
            className="text-input_small"
            color="white"
            error={suffixWarning}
          >
            {inputValues.userSuffix[1]}
          </TextInputCustom>
          <TextInputCustom
            label="Login Name"
            type="email"
            color="white"
            onChange={value => setInputValues({ ...inputValues, user: value.toLowerCase() })}
            checkFunc={() => loginValidation(inputValues.user)}
            onBlur={checkLoginAvailable}
            error={loginWarning}
          >
            {inputValues.user}
          </TextInputCustom>
          <TextInputCustom
            label="Password"
            type="password"
            color="white"
            onChange={changeInputValue("pass")}
            checkFunc={() => passValidation(inputValues.pass)}
          // onBlur={checkLoginAvailable}
          >
            {inputValues.pass}
          </TextInputCustom>
          <TextInputCustom
            label="Repeat Password"
            type="password"
            color="white"
            checkFunc={passwordMatching}
            onChange={changeInputValue("repeatPass")}
          >
            {inputValues.repeatPass}
          </TextInputCustom>
          <h3>Status</h3>
          <div className="radios-container">
            {inputValues.userStatus ? (
              inputValues.userStatus.map(title => (
                <RadioCustom
                  onChange={radioToggle}
                  status="ACTIVE"
                  checked={title === inputValues.status}
                  type="green"
                  key={title}
                >
                  {title}
                </RadioCustom>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="new-ma__container new-ma__credit">
          <h2>Credit</h2>
          <hr />
          <div className="input-with-params">
            <div>
              <TextInputCustom
                label="Credit Limit"
                color="white"
                onChange={changeInputValue("credit")}
                checkFunc={creditValidation}
                type="number"
              >
                {inputValues.credit}
              </TextInputCustom>
            </div>
            <div className="params">
              <span>{">=0"}</span>
              <span>{"<=" + addCommas(inputValues.creditLimits?.data.availableBalance)}</span>
            </div>
          </div>
        </div>
        <div className="new-ma__container new-ma__position-taking">
          <h2>Position Taking</h2>
          <hr />
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextInputCustom
              label="My Share (%)"
              color="white"
              onChange={changeInputValue("cusPosition")}
              checkFunc={positionValidation("cusPosition")}
              type="number"
            >
              {inputValues.cusPosition}
            </TextInputCustom>
            {/* <p>+</p>
                        <div className="input-with-params">
                            <TextInputCustom
                                label='MA Max Position (%)'
                                color='white'
                                onChange={changeInputValue("maMaxPosition")}
                                checkFunc={positionValidation("maMaxPosition")}
                                type="number"
                            >{inputValues.maMaxPosition}
                            </TextInputCustom>
                            <div className="params">
                                <span>{"<= 80"}</span>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      <div className="new-ma__container new-ma__notes">
        <h2>Notes</h2>
        <hr />
        <textarea
          onChange={e => setInputValues({ ...inputValues, notes: e.target.value })}
          value={inputValues.notes}
        ></textarea>
      </div>
      <div className="new-ma__controls">
        <Button
          className="cansel-button"
          variant="link"
          onClick={() => history.push("/agency-management/downline")}
          style={{ color: "#18c432" }}
        >
          Cancel
        </Button>
        <ButtonCustom onClick={submitToggle} width="76px" height="32px" color="purple">
          Create
        </ButtonCustom>
      </div>
      <AlertCustom timeout={3000} disableFunc={() => setRedAlertMessage(null)}>
        {redAlertMessage}
      </AlertCustom>
      <AlertCustom timeout={3000} type="green" disableFunc={() => setGreenAlertMessage(null)}>
        {greenAlertMessage}
      </AlertCustom>
    </div>
  )
}
