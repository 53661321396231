import "./index.scss";
import { useEffect, useState } from "react";
import ButtonCustom from "../ButtonCustom";
import InfoPopUp from "../InfoPopUp";
import axios from "axios";
import AlertCustom from "../AlertCustom";
import { HOST } from "../../Providers/AuthProvider"
import { toastr } from "react-redux-toastr"
import { Table } from "react-bootstrap";

export default ({ visible, closeFunc, data }) => {
  const [report, setReport] = useState({});
  const [copied, setCopied] = useState("");
  const [remark, setRemark] = useState("");
  const [error, setError] = useState("");
  const [isVisibleInfoPopUp, setIsVisibleInfoPopUp] = useState(false);
  const [alertOptions, setAlertOptions] = useState({
    message: null,
    type: "red"
  })

  useEffect(() => {
    setReport(data);
  }, [data]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(""), 2000);
    }
  }, [copied]);

  const handleClickApprove = async () => {
    const token = sessionStorage.getItem("accessToken");

    try {
      const response = await axios.get(HOST + `/Banking/withdraw/approve-request?requestId=${report?.requestId}`,
        { headers: { "Authorization": `${token}` } });

      if (response.data.success) {
        // setIsVisibleInfoPopUp(true);
        // setTimeout(() => setIsVisibleInfoPopUp(false), 3000);
        // setTimeout(() => closeFunc(false), 2500);
        toastr.success("Successfully saved");
        setAlertOptions({ type: "green", message: "Successfully saved" })
        closeFunc(true);
      }

    } catch (error) {
      // setIsVisibleInfoPopUp(true);
      // setError(error?.response?.data?.error);
      setAlertOptions({ type: "red", message: error?.response?.data?.result })
      // setAlertOptions({ ...alertOptions, message: error?.response?.data?.error })
      // setTimeout(() => setError(""), 3000);
      // setTimeout(() => setIsVisibleInfoPopUp(false), 3000);
    }
  };
  const handleClickReject = async () => {
    const token = sessionStorage.getItem("accessToken");

    try {
      const response = await axios.post(HOST + `/Banking/reject-request?requestId=${report?.requestId}`, {
        remark: remark,
        requestId: report?.requestId
      },
        { headers: { "Authorization": `${token}` } });

      if (response.data.success) {
        // setIsVisibleInfoPopUp(true);
        // setTimeout(() => setIsVisibleInfoPopUp(false), 3000);
        // setTimeout(() => closeFunc(false), 2500);
        setAlertOptions({ type: "green", message: "Successfully saved" })
        closeFunc(true);
      }

    } catch (error) {
      // setIsVisibleInfoPopUp(true);
      // setError(error?.response?.data?.error);
      setAlertOptions({ type: "red", message: error?.response?.data?.result })
      // setAlertOptions({ ...alertOptions, message: error?.response?.data?.error })
      // setTimeout(() => setError(""), 3000);
      // setTimeout(() => setIsVisibleInfoPopUp(false), 3000);
    }
  };
  const handleClickCancel = () => {
    // setAlertOptions({ type:"green", message: "Successfully saved" })
    setRemark("")
    closeFunc(false);
  };

  const changeRemark = (e) => {
    setRemark(e.target.value)
  }

  useEffect(() => {
    console.log(alertOptions)
  }, [alertOptions.message])

  const copyText = (text) => {
    console.log(text);
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      {visible && <div className="withdrawal-pop-up">
        <InfoPopUp title={error} isError={!!error} isVisible={isVisibleInfoPopUp} />
        <div className="withdrawal-pop-up__body">
          <div className="close" onClick={handleClickCancel}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 30 30">
              <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" fill="white"></path>
            </svg>
          </div>
          <Table>
            <tbody>
              <tr className="data-table-title">
                <td className="withdraw_popup_title">
                  <span>Request Id</span>
                  <span>:</span>
                </td>
                <td className="bold withdraw_popup_data">{report?.requestId}</td>
              </tr>
              <tr className="data-table-title">
                <td className="withdraw_popup_title">
                  <span>LoginName</span>
                  <span>:</span>
                </td>
                <td className="bold withdraw_popup_data">{report?.loginName}</td>
              </tr>
              <tr className="data-table-title">
                <td className="withdraw_popup_title">
                  <span>Withdrawl Amount</span>
                  <span>:</span>
                </td>
                <td className="bold withdraw_popup_data">₹{report?.amount}</td>
              </tr>
            </tbody>
          </Table>
          <Table striped>
            <tbody>
              <tr className="data-table">
                <td className="withdraw_popup_title">
                  <span>Ac No</span>
                  <span>:</span>
                </td>
                <td className="bold withdraw_popup_data">
                  <span>{report?.account?.accountNo}</span>
                  {
                    copied !== 'accountNo' && (<svg onClick={() => { setCopied("accountNo"); copyText(report?.account?.accountNo); }} xmlns="http://www.w3.org/2000/svg" height="1rem" width='1rem' viewBox="0 0 448 512">
                      <path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" fill="#ffffff" />
                    </svg>)}
                  {
                    copied === 'accountNo' && (<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 50 50" fill="white">
                      <path d="M 25 2 C 12.317 2 2 12.317 2 25 C 2 37.683 12.317 48 25 48 C 37.683 48 48 37.683 48 25 C 48 20.44 46.660281 16.189328 44.363281 12.611328 L 42.994141 14.228516 C 44.889141 17.382516 46 21.06 46 25 C 46 36.579 36.579 46 25 46 C 13.421 46 4 36.579 4 25 C 4 13.421 13.421 4 25 4 C 30.443 4 35.393906 6.0997656 39.128906 9.5097656 L 40.4375 7.9648438 C 36.3525 4.2598437 30.935 2 25 2 z M 43.236328 7.7539062 L 23.914062 30.554688 L 15.78125 22.96875 L 14.417969 24.431641 L 24.083984 33.447266 L 44.763672 9.046875 L 43.236328 7.7539062 z" stroke-width="2"></path>
                    </svg>)
                  }
                </td>
                <td className="withdraw_popup_title">
                  <span>Name</span>
                  <span>:</span>
                </td>
                <td className="bold withdraw_popup_data">
                  <span>{report?.account?.accountHolderName}</span>
                  {
                    copied !== 'accountHolderName' && (<svg onClick={() => { setCopied("accountHolderName"); copyText(report?.account?.accountHolderName); }} xmlns="http://www.w3.org/2000/svg" height="1rem" width='1rem' viewBox="0 0 448 512">
                      <path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" fill="#ffffff" />
                    </svg>)}
                  {
                    copied === 'accountHolderName' && (<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 50 50" fill="white">
                      <path d="M 25 2 C 12.317 2 2 12.317 2 25 C 2 37.683 12.317 48 25 48 C 37.683 48 48 37.683 48 25 C 48 20.44 46.660281 16.189328 44.363281 12.611328 L 42.994141 14.228516 C 44.889141 17.382516 46 21.06 46 25 C 46 36.579 36.579 46 25 46 C 13.421 46 4 36.579 4 25 C 4 13.421 13.421 4 25 4 C 30.443 4 35.393906 6.0997656 39.128906 9.5097656 L 40.4375 7.9648438 C 36.3525 4.2598437 30.935 2 25 2 z M 43.236328 7.7539062 L 23.914062 30.554688 L 15.78125 22.96875 L 14.417969 24.431641 L 24.083984 33.447266 L 44.763672 9.046875 L 43.236328 7.7539062 z" stroke-width="2"></path>
                    </svg>)
                  }

                </td>
              </tr>
              <tr className="data-table">
                <td className="withdraw_popup_title">
                  <span>IFSC</span>
                  <span>:</span>
                </td>
                <td className="bold withdraw_popup_data">
                  <span style={{ textTransform: "uppercase" }}>{report?.account?.IFSC}</span>
                  {
                    copied !== 'IFSC' && (<svg onClick={() => { setCopied("IFSC"); copyText(report?.account?.IFSC); }} xmlns="http://www.w3.org/2000/svg" height="1rem" width='1rem' viewBox="0 0 448 512">
                      <path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" fill="#ffffff" />
                    </svg>)}
                  {
                    copied === 'IFSC' && (<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 50 50" fill="white">
                      <path d="M 25 2 C 12.317 2 2 12.317 2 25 C 2 37.683 12.317 48 25 48 C 37.683 48 48 37.683 48 25 C 48 20.44 46.660281 16.189328 44.363281 12.611328 L 42.994141 14.228516 C 44.889141 17.382516 46 21.06 46 25 C 46 36.579 36.579 46 25 46 C 13.421 46 4 36.579 4 25 C 4 13.421 13.421 4 25 4 C 30.443 4 35.393906 6.0997656 39.128906 9.5097656 L 40.4375 7.9648438 C 36.3525 4.2598437 30.935 2 25 2 z M 43.236328 7.7539062 L 23.914062 30.554688 L 15.78125 22.96875 L 14.417969 24.431641 L 24.083984 33.447266 L 44.763672 9.046875 L 43.236328 7.7539062 z" stroke-width="2"></path>
                    </svg>)
                  }

                </td>
                <td className="withdraw_popup_title">
                  <span>Bank Name</span>
                  <span>:</span>
                </td>
                <td className="bold withdraw_popup_data">
                  <span>{report?.account?.bankName}, {report?.account?.branchName}</span>
                  {
                    copied !== 'bankName' && (<svg onClick={() => { setCopied("bankName"); copyText(report?.account?.bankName); }} xmlns="http://www.w3.org/2000/svg" height="1rem" width='1rem' viewBox="0 0 448 512">
                      <path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" fill="#ffffff" />
                    </svg>)}
                  {
                    copied === 'bankName' && (<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 50 50" fill="white">
                      <path d="M 25 2 C 12.317 2 2 12.317 2 25 C 2 37.683 12.317 48 25 48 C 37.683 48 48 37.683 48 25 C 48 20.44 46.660281 16.189328 44.363281 12.611328 L 42.994141 14.228516 C 44.889141 17.382516 46 21.06 46 25 C 46 36.579 36.579 46 25 46 C 13.421 46 4 36.579 4 25 C 4 13.421 13.421 4 25 4 C 30.443 4 35.393906 6.0997656 39.128906 9.5097656 L 40.4375 7.9648438 C 36.3525 4.2598437 30.935 2 25 2 z M 43.236328 7.7539062 L 23.914062 30.554688 L 15.78125 22.96875 L 14.417969 24.431641 L 24.083984 33.447266 L 44.763672 9.046875 L 43.236328 7.7539062 z" stroke-width="2"></path>
                    </svg>)
                  }
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="remark_section">
            <label htmlFor="remark">Remark:</label>
            <textarea name="remark" onChange={changeRemark} />
          </div>
          <div className="withdraw_popup_buttons">
            <ButtonCustom onClick={handleClickApprove} color="cyan">Approve</ButtonCustom>
            <ButtonCustom onClick={handleClickReject} color="red">Reject</ButtonCustom>
            <ButtonCustom onClick={handleClickCancel} color="red">Cancel</ButtonCustom>
          </div>
        </div>
      </div>}
      <AlertCustom
        disableFunc={() => setAlertOptions({ ...alertOptions, message: null })}
        type={alertOptions.type}
      >{alertOptions.message}
      </AlertCustom>
    </>
  );
};
