import React, {useEffect, useRef, useState} from "react";
import { Image } from "react-bootstrap";
import {useHistory} from "react-router-dom";
import ButtonCustom from "../../components/ButtonCustom";
import TextInputCustom from "../../components/TextInputCustom";
import {Logo, useAuth} from "../../Providers/AuthProvider";
import "./index.scss";

const Login = () => {
  // const [inputUserValue, setInputUserValue] = useState('hkdsa');
  // const [inputPassValue, setInputPassValue] = useState('abcd1234');
    const [inputUserValue, setInputUserValue] = useState("");
    const [inputPassValue, setInputPassValue] = useState("");
    const [message, setMessage] = useState("");
    const {requestsToApi, auth, isAuthenticated, appMode} = useAuth();
    const history = useHistory();
    const passInput = useRef(null);
    const submitButton = useRef(null);

    useEffect(() => {
        if (isAuthenticated || appMode === "Cashier") {
            history.push("/home-page");
        } else {
            if (isAuthenticated) history.push("/account/balance");
        }
    }, []);

    const validateFields = () => {

        if (inputUserValue.trim().length && inputPassValue.trim().length) {
            login();
        } else {
            if (!inputUserValue && !!inputPassValue) {
                setMessage("Username is required.");
            } else {
                if (!inputPassValue & !!inputUserValue) {
                    setMessage("Password is required.");
                } else {
                    setMessage("All fields must be filled in");
                }
            }
        }
    };

  //it's for agensy

  // const login = async () => {
  //   try {
  //     const response = await requestsToApi.post('/auth/login', {
  //       password: inputPassValue,
  //       username: inputUserValue
  //     })
  //     if (response.headers.authorization) {
  //       sessionStorage.setItem('myUserName', response.data.result.user.name)
  //       sessionStorage.setItem('myLoginName', response.data.result.user.loginName)
  //       sessionStorage.setItem('myLastLoginTime', response.data.result.user.lastLoginTime)
  //       sessionStorage.setItem('myLevel', response.data.result.user.level)
  //       auth(response.headers.authorization)
  //     }
  //   } catch (error) {
  //     console.log("error", error)
  //     if (error?.status === 401) {
  //       setMessage("Wrong email or password")
  //     } else {
  //       setMessage(error?.message)
  //     }
  //   }
  // }

  //it's for payments

    const login = async () => {
        try {
            const preLoginResponse = await requestsToApi.get("/config/app-mode");
            const appMode = preLoginResponse.data.result.appMode;

            if ( appMode === "Cashier") {
                const response = await requestsToApi.post("/appusers/auth/login", {
                    mobile: inputUserValue,
                    password: inputPassValue,
                });

                console.log(response);

                if (response.headers.authorization) {
                    sessionStorage.setItem("myUserName", response.data.result.firstname);
                    sessionStorage.setItem("myMobile", response.data.result.mobile);
                    sessionStorage.setItem("myLastLoginTime", response.data.result.lastLoginDate);
                    sessionStorage.setItem("myRole", response.data.result.role);
                    sessionStorage.setItem("myId", response.data.result.id);
                    sessionStorage.setItem("permissions", response.data.result.permissions);
                    sessionStorage.setItem("appMode", "Cashier");
          // sessionStorage.setItem('myLevel', response.data.result.user.level)
                    auth(response.headers.authorization, {}, appMode);
                } else {
                  setMessage(response.data.result?.message || response.data.error.message);
                }
            }
      // else {
      //   throw new Error('Not Cashier appMode');
      // }
        } catch (error) {
            console.log("error", error);
            if (error?.status === 401) {
                setMessage("Wrong email or password");
            } else {
                setMessage(error?.message);
            }
        }
    };

    return (
    <div className='login-page'>
      <div className='login-block'>
        <div className="login-block__logo">
            <Image src={Logo} />
          </div>

        <TextInputCustom
          label='Enter Username:'
          type='email'
          color='white'
          onChange={setInputUserValue}
          onEnter={() => passInput.current.focus()}
        >{inputUserValue}
        </TextInputCustom>
        <TextInputCustom
          label='Enter Password:'
          type='password'
          color='white'
          ref={passInput}
          onChange={setInputPassValue}
          onEnter={() => submitButton.current.focus()}
        >{inputPassValue}
        </TextInputCustom>
        <ButtonCustom
          className='login__button'
          color='purple'
          onClick={validateFields}
          height="42px"
          ref={submitButton}
        >LOGIN
        </ButtonCustom>
        <p>Best Viewed In:</p>
        <div className='login__icons'>
          <div className='firefox-icon'></div>
          <div className='ie-icon'></div>
          <div className='chrome-icon'></div>
        </div>
        {message ? <p className='login__message'>{message}</p> : <></>}
      </div>
    </div>
    );
};

export default Login;
