import "./index.scss"
import { useEffect, useRef, useState } from "react"
import InfoPopUp from "../../components/InfoPopUp"
import axios from "axios"
import Select from 'react-select';
import { HOST, useAuth } from "../../Providers/AuthProvider"
import { FileUploader } from "react-drag-drop-files"
import { FederalParse } from "./helpers/federal"
import { HDFCExcel } from "./helpers/HDFCExcel"
import { YESExcel } from "./helpers/YesExcel"
import { IDFCXLS } from "./helpers/IDFCXLS"
import { SarswatParse } from "./helpers/sarswat"
import { IndusIndCSVParse } from "./helpers/IndusIndCSV"
import { RBLXLSParse } from "./helpers/rblXLS"
import { RBLCSVParse } from "./helpers/rblCSV"
import { AUXLSParse } from "./helpers/AUXLS"
import { AxisCSVParse } from "./helpers/AxisCSV"
import ModalCustom from "../../components/ModalCustom"
import ButtonCustom from "../../components/ButtonCustom"
import { BPCsv } from "./helpers/bharatPe"
import { IDBIXLS } from "./helpers/IDBIXLS"
import { SouthIndiaBank } from "./helpers/southIndia"
import { yesPersonalExcel } from "./helpers/YESPersonalExcel"
import { sbiBank } from "./helpers/SBIXLS"
import { bandhanXls } from "./helpers/bandhanXls"
import { fincareXls } from "./helpers/fincareXls"
import { federalCsv } from "./helpers/federalCsv"
import { equitasXlsx } from "./helpers/equitasXlsx"
import { ujjivanCSV } from "./helpers/ujjivanCSV"
import { iciciXLSX } from "./helpers/iciciXLSX"
import { iciciXLS } from "./helpers/iciciXLS"
import { unionXLS } from "./helpers/unionXLS"
import { bobXLS } from "./helpers/bobXLS"
import { HDFCCorporateExcel } from "./helpers/HDFCCorporateExcel";
import { kotakCSV } from "./helpers/kotakCSV";
import { AxisCorporateXLS } from "./helpers/AxisCorporateXLS";
import { iciciCSV } from "./helpers/iciciCSV";
import { DCBXLS } from "./helpers/DCBXLS";
import { BandhanBankXLS } from "./helpers/BandhanBankXls";
import { shvalikXLS } from "./helpers/shivalikXLS";
import { utkarshXLS } from "./helpers/utkarshXLS";

export const DepositEntryPage = () => {
  let [optionValue, setOptionValue] = useState(null)
  let [selectedAccount, setSelectedAccount] = useState("")
  let [isVisibleInfoPopUp, setIsVisibleInfoPopUp] = useState(false)
  const { requestsToApi } = useAuth()
  const utrInput = useRef(null)
  const submitButton = useRef(null)
  const [file, setFile] = useState(null)
  const fileTypes = ["CSV"]


  const handleOptionClick = (option) => {
    setSelectedAccount(option.value)
    setOptionValue(option)
  }

  const [accounts, setAccounts] = useState([])
  let [amountState, setAmountState] = useState("")
  let [utrRefState, setUtrRefState] = useState("")
  let [amountValidationCheck, setAmountValidationCheck] = useState(false)
  let [utrRefValidationCheck, setUtrRefValidationCheck] = useState(false)
  let [utrRefExistCheck, setUtrRefExistCheck] = useState(true)
  let [erroredItems, setErroredItems] = useState(true)
  const [erroredPopUp, setErroredPopUp] = useState()
  const [bankType, setBankType] = useState('')



  const savedAccountNumber = () => {
    // sessionStorage.getItem("selectedAccountNumber")
    //   ? setSelectedAccount(sessionStorage.getItem("selectedAccountNumber"))
    //   : setSelectedAccount(null)
  }

  let amountOnInputChange = (e) => {
    let temp = Number(Number(e.target.value).toFixed(2))

    if (temp >= 0) {
      setAmountState(temp)
    }
    //else {
    //     setAmountState("Amount");
    // }
  }

  let utrRefOnInputChange = (e) => {
    // let temp = e.target.value.split(".")[0]
    // temp = Number(temp)

    if (e.target.value.length >= 0) {
      setUtrRefState(e.target.value)
    } else {
      setUtrRefState("")
    }
  }

  let amountValidation = (e) => {
    let amountToNum = Number(e.target.value)

    if (amountToNum > 0) {
      setAmountValidationCheck(true)
    } else {
      setAmountValidationCheck(false)
    }
  }

  let utrRefValidation = (e) => {
    // let utrRefToNum = Number(e.target.value);

    if (e.target.value.length > 0) {
      setUtrRefValidationCheck(true)
    } else {
      setUtrRefValidationCheck(false)
    }
  }

  let runAmountSetStateValidation = (e) => {
    amountOnInputChange(e)
    amountValidation(e)
  }

  let runUtrRefSetStateValidation = (e) => {
    utrRefOnInputChange(e)
    utrRefValidation(e)
  }

  useEffect(async () => {
    try {
      const response = await requestsToApi.get("Banking/deposit/payment-gateways?requestAll=true")
      let list = [];
      response.data.result.forEach(obj => {
        list.push({ label: `${obj.account.acNo} / ${obj.account.name} / ${obj.account.bankName}`, value: obj.account.acNo });
      })
      setAccounts(list)
    } catch (error) {
      console.log(error?.message)
    }
    savedAccountNumber()
  }, [])

  const handleClickReset = (e) => {
    e.preventDefault()
    setAmountState("")
    setUtrRefState("")
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    const token = sessionStorage.getItem("accessToken")

    try {
      const response = await axios.post(HOST + "/Banking/deposit-entry",
        {
          "UTR": utrRefState,
          "amount": amountState,
          "acNo": selectedAccount
        },
        {
          headers: { "Authorization": `${token}` }
        })

      if (response.data.success) {
        setIsVisibleInfoPopUp(true)
        setAmountState("")
        setUtrRefState("")
        setAmountValidationCheck(false)
        setUtrRefValidationCheck(false)
        setTimeout(() => setIsVisibleInfoPopUp(false), 3000)
        setUtrRefExistCheck(true)
      } else {
        setAmountState("")
        setUtrRefState("")
        setAmountValidationCheck(false)
        setUtrRefValidationCheck(false)
      }
    } catch (error) {
      if (error && error.response && error.response.status && error.response.status === 400) {
        setAmountState("")
        setUtrRefState("")
        setAmountValidationCheck(false)
        setUtrRefValidationCheck(false)
        setUtrRefExistCheck(false)
        setIsVisibleInfoPopUp(true)
        setTimeout(() => setIsVisibleInfoPopUp(false), 3000)
      }
    }
  }

  const [parsedItems, setParsedItems] = useState()
  const [allItems, setAllItems] = useState()

  const [federalEntries, setfederalEntries] = useState()

  function CSVToArray(strData, strDelimiter) {
    strDelimiter = (strDelimiter || ",")

    var objPattern = new RegExp(
      (
        "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

        "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

        "([^\"\\" + strDelimiter + "\\r\\n]*))"
      ),
      "gi"
    )


    var arrData = [[]]

    var arrMatches = null


    while (arrMatches = objPattern.exec(strData)) {

      var strMatchedDelimiter = arrMatches[1]
      if (
        strMatchedDelimiter.length &&
        strMatchedDelimiter !== strDelimiter
      ) {
        arrData.push([])

      }

      var strMatchedValue

      if (arrMatches[2]) {

        strMatchedValue = arrMatches[2].replace(
          new RegExp("\"\"", "g"),
          "\""
        )

      } else {
        strMatchedValue = arrMatches[3]

      }
      arrData[arrData.length - 1].push(strMatchedValue)
    }
    console.log("object", arrData)

    // Return the parsed data.
    return (arrData)
  }

  function CSVToArrayForFederalBank(strData, strDelimiter) {

    // Return the parsed data.
  }

  // console.log(allItems && allItems?.split('2022"' || '2023"').map((item)=> item.split(',INR')[0]).map((item2) => item2.split(item2.indexOf('/'))));

  const handleChange = async file => {
    setFile(file)
    // let f = file;
    //   let reader = new FileReader();
    //   let output = reader.readAsText(f);
    console.log("output", await file.text())
    setAllItems(await file.text())
    //  CSVToArray()

    //  if (allItems) {
    //   setParsedItems(
    //     allItems
    //       .map((i, index) => i.split('"')[1])
    //       // .map(item => {
    //       //   if (item?.split("~")[3] == "SUC") {
    //       //     return {
    //       //       amount: `${item?.split("~")[0]}`,
    //       //       UTR: `${item?.split("~")[1]}`,
    //       //       requestId: `${item?.split("~")[2]}`,
    //       //       status: `${item?.split("~")[3]}`,
    //       //       remark: `${item?.split("~")[4]}`
    //       //     }
    //       //   }
    //       // })
    //   )
    // }
    // // setIsImage(URL.createObjectURL(file))
    // console.log(parsedItems);
  }


  const handleFederalBankChange = async file => {
    setFile(file)
    let text = await file.text()

    try {
      if (text) {
        for (const item of text.split(/\n/)) {
          if (item.split(",")[2] && item.split(",")[2] !== undefined) {
            if (item.split(",")[2].startsWith("UPI")) {
              let utr = item.split(",")[2].split("/")[1]?.substring(0, 12)
              console.log(item);
              console.log("preamount", item.split("\"").length);
              if (item.split("\"").length === 5) {
                let amount = parseFloat(item.split("\"")[1]?.replace(",", ""))
                console.log("amount", amount)
                console.log("UTR", utr)
                if (amount && utr && utr.length === 12)
                  await makeUTREntry(utr, amount)
              } else if (item.split("\"").length === 3) {
                let amount = parseFloat(item.split(",")[8]?.replace(",", ""))
                console.log("amount", amount)
                console.log("UTR", utr)
                if (amount && utr && utr.length === 12)
                  await makeUTREntry(utr, amount)
              }
            } else if (item.split(",")[2].startsWith("IMPS")) {
              let utr = item.split(",")[2].split("/")[1].substring(0, 12)
              let amount = parseFloat(item.split("\"")[1].replace(",", ""))
              console.log(item);
              console.log("preamount", item.replace(/['"]+/g, ''));
              if (amount && utr && utr.length === 12)
                // await makeUTREntry(utr, amount)
                console.log("amount", amount)
              console.log("UTR", utr)
            }
          }
        }
      }
    } catch (e) {

      console.log(e)
    }
  }

  const makeUTREntry = async (utr, number) => {
    try {
      const token = sessionStorage.getItem("accessToken")

      const response = await axios.post(HOST + "/Banking/deposit-entry",
        {
          "UTR": utr,
          "amount": number,
          "acNo": selectedAccount
        },
        {
          headers: { "Authorization": `${token}` }
        })

      if (response.data.success) {
        setIsVisibleInfoPopUp(true)
        setAmountState("")
        setUtrRefState("")
        setAmountValidationCheck(false)
        setUtrRefValidationCheck(false)
        setTimeout(() => setIsVisibleInfoPopUp(false), 3000)
        setUtrRefExistCheck(true)
      } else {
        setAmountState("")
        setUtrRefState("")
        setAmountValidationCheck(false)
        setUtrRefValidationCheck(false)
      }
    } catch (error) {
      if (error && error.response && error.response.status && error.response.status === 400) {
        setAmountState("")
        setUtrRefState("")
        setAmountValidationCheck(false)
        setUtrRefValidationCheck(false)
        setUtrRefExistCheck(false)
        setIsVisibleInfoPopUp(true)
        setTimeout(() => setIsVisibleInfoPopUp(false), 3000)
      }
    }
  }


  // console.log('parsedItems', parsedItems);

  useEffect(() => {
    if (allItems) {
      setParsedItems(CSVToArray(allItems))
    }
  }, [allItems])

  useEffect(() => {
    if (parsedItems) {
      const token = sessionStorage.getItem("accessToken")
      let i = 0

      parsedItems?.map(async (item) => {
        // console.log(item[2]?.split('/').filter(item2 => item2.length === 12 && item2.replace( /\D/g, '')).length > 0 ? item[2]?.split('/').filter(item2 => item2.length === 12 && item2.replace( /\D/g, '')) : null);
        if (item[2]?.split("/").filter(item2 => item2.length === 12 && item2.replace(/\D/g, "")).length > 0 && item[5].length) {
          i = ++i
          console.log(i)
          try {
            const response = await axios.post(HOST + "/Banking/deposit-entry",
              {
                "UTR": item[2]?.split("/").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0],
                "amount": item[5],
                "acNo": selectedAccount
              },
              {
                headers: { "Authorization": `${token}` }
              })

            if (response.data.success) {
              setIsVisibleInfoPopUp(true)
              setAmountState("")
              setUtrRefState("")
              setAmountValidationCheck(false)
              setUtrRefValidationCheck(false)
              setTimeout(() => setIsVisibleInfoPopUp(false), 3000)
              setUtrRefExistCheck(true)
            } else {
              setAmountState("")
              setUtrRefState("")
              setAmountValidationCheck(false)
              setUtrRefValidationCheck(false)
            }
          } catch (error) {
            if (error && error.response && error.response.status && error.response.status === 400) {
              setAmountState("")
              setUtrRefState("")
              setAmountValidationCheck(false)
              setUtrRefValidationCheck(false)
              setUtrRefExistCheck(false)
              setIsVisibleInfoPopUp(true)
              setTimeout(() => setIsVisibleInfoPopUp(false), 3000)
            }
          }


        }
      })
    }
  }, [parsedItems])
  console.log('erroredItemserroredItemserroredItems', selectedAccount)
  return (
    <div className="deposit-entry__main">
      <h1>Deposit Entry</h1>
      {erroredItems.length > 0 && <div onClick={() => setErroredPopUp(true)} style={{ color: '#d75353', cursor: 'pointer', fontSize: '17px' }}>You have errored entries Click here to see detailes</div>}
      {/* {!selectedAccount && <div onClick={() => setErroredPopUp(true)}>Please, select Account No. firstly</div>} */}

      <div className="deposit-entry">
        <div className="deposit-entry__top">


          <span className="deposit-entry__top-select-label deposit-entry__label">Account No.</span>
          <InfoPopUp isVisible={isVisibleInfoPopUp} utrRefExistCheck={utrRefExistCheck} />
          <Select
            defaultValue={optionValue}
            value={optionValue}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={handleOptionClick}
            options={accounts}
          />
          {/*<div className="deposit-entry__pop-up"> Successfully saved</div>*/}
          {/* <div className="deposit-entry__top-select-custom">
            <div
              className={showOption ? "deposit-entry__top-select-custom-selected active" : "deposit-entry__top-select-custom-selected"}
              onClick={handleListDisplay}>
              {selectedAccount}
            </div>
            {showOption && (
              <ul className="deposit-entry__top-select-custom-options">
                {accounts.map(account => (
                  <li
                    className="deposit-entry__top-select-custom-option"
                    key={account}
                    onClick={() => handleOptionClick(account.account)}
                  >
                    {account.account.acNo} / {account.account.name} / {account.account.bankName}
                  </li>
                ))}
              </ul>
            )}
          </div> */}
        </div>
        <div className="deposit-entry__form-wrapper">
          <h2 className="deposit-entry__form-title">Deposit Entry</h2>
          <div className="deposit-entry__form">
            <div className="deposit-entry__form-body">
              <div className="deposit-entry__form-input-wrapper">
                <label className="deposit-entry__top-input-label deposit-entry__label">Amount</label>
                <input
                  type="text"
                  name={"amount"}
                  value={amountState === 0 ? "" : amountState}
                  onChange={runAmountSetStateValidation}
                  onKeyPress={e => (e.key === "Enter" ? utrInput.current.focus() : "")}
                  className="deposit-entry__form-input"
                  placeholder=""
                />
              </div>
              <div className="deposit-entry__form-input-wrapper">
                <label className="deposit-entry__top-input-label deposit-entry__label">UTR or Ref No</label>
                <input
                  autoComplete="off"
                  type="text"
                  name={"utr-ref"}
                  value={utrRefState}
                  ref={utrInput}
                  onChange={runUtrRefSetStateValidation}
                  onKeyPress={e => (e.key === "Enter" ? submitButton.current.focus() : "")}
                  className="deposit-entry__form-input"
                  placeholder=""
                />
              </div>
            </div>
            <div className="deposit-entry__form-footer">
              <button onClick={handleClickReset} className="deposit-entry__form-button deposit-entry__form-reset">
                <span>RESET</span>
              </button>
              <button
                ref={submitButton}
                onClick={handleFormSubmit}
                disabled={!(amountValidationCheck && utrRefValidationCheck)}
                className={`${amountValidationCheck && utrRefValidationCheck ? "unlock-button" : ""} deposit-entry__form-button deposit-entry__form-save `}
              >
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
        <span className="deposit-entry__top-select-label deposit-entry__label">IDFC CSV</span>
        <FileUploader handleChange={(f) => { handleChange(f); setSelectedAccount(null); setOptionValue(null) }} types={fileTypes} name="file" classes="lll" disabled={selectedAccount ? false : true} />

        {/* <span className="deposit-entry__top-select-label deposit-entry__label">FEDERAL CSV.</span>
        <FileUploader handleChange={handleFederalBankChange} types={fileTypes} name="file" classes="lll" disabled={(!selectedAccount.includes("Account No")) ? false : true}/> */}

        <span className="deposit-entry__top-select-label deposit-entry__label">FEDERAL CSV.</span>
        <FileUploader
          handleChange={(f) => { federalCsv(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["CSV"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">IDFC XLSX. *</span>
        <FileUploader
          handleChange={(f) => { IDFCXLS(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLSX"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">FEDERAL XLSX.</span>
        <FileUploader
          handleChange={(f) => { FederalParse(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLSX"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">Sarswat XLS.</span>
        <FileUploader
          handleChange={(f) => { SarswatParse(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">IndusInd CSV. *</span>
        <FileUploader
          handleChange={(f) => { IndusIndCSVParse(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["CSV"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">RBL CSV.</span>
        <FileUploader
          handleChange={(f) => { RBLCSVParse(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["CSV"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">RBL XLS.</span>
        <FileUploader
          handleChange={(f) => { RBLXLSParse(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS", "XLSX"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">AU XLS.</span>
        <FileUploader
          handleChange={f => {
            AUXLSParse(f, selectedAccount, setErroredItems)
            setBankType('AUXLS');
            setSelectedAccount(null);
            setOptionValue(null)
          }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">AXIS CSV.</span>
        <FileUploader
          handleChange={f => { AxisCSVParse(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["CSV"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">HDFC XLS. *</span>
        <FileUploader
          handleChange={f => { HDFCExcel(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />
        <span className="deposit-entry__top-select-label deposit-entry__label">HDFC Corporate XLS. *</span>
        <FileUploader
          handleChange={f => { HDFCCorporateExcel(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />
        <span className="deposit-entry__top-select-label deposit-entry__label">YES Excel</span>
        <FileUploader
          handleChange={f => { YESExcel(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLSX"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">BP CSV</span>
        <FileUploader
          handleChange={f => { BPCsv(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["CSV"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">IDBI XLS</span>
        <FileUploader
          handleChange={f => { IDBIXLS(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">YES Personal BANK XLS *</span>
        <FileUploader
          handleChange={f => { yesPersonalExcel(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">South India XLS</span>
        <FileUploader
          handleChange={f => { SouthIndiaBank(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">SBI XLS</span>
        <FileUploader
          handleChange={f => { sbiBank(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">Bandhan XLS</span>
        <FileUploader
          handleChange={f => { bandhanXls(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">Fincare XLS *</span>
        <FileUploader
          handleChange={f => { fincareXls(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">Equitas XLS</span>
        <FileUploader
          handleChange={f => { equitasXlsx(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLSX"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">Ujjivan CSV</span>
        <FileUploader
          handleChange={f => { ujjivanCSV(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["CSV"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">ICICI XLSX</span>
        <FileUploader
          handleChange={f => { iciciXLSX(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLSX"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">ICICI XLS *</span>
        <FileUploader
          handleChange={f => { iciciXLS(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">Union XLS *</span>
        <FileUploader
          handleChange={f => { unionXLS(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">BOB XLS *</span>
        <FileUploader
          handleChange={f => { bobXLS(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">Kotak Bank CSV *</span>
        <FileUploader
          handleChange={f => { kotakCSV(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["CSV"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">Axis Corporate Bank XLS *</span>
        <FileUploader
          handleChange={f => { AxisCorporateXLS(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">ICICI Bank CSV *</span>
        <FileUploader
          handleChange={f => { iciciCSV(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["CSV"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">DCB Bank XLS *</span>
        <FileUploader
          handleChange={f => { DCBXLS(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">Bandhan Bank XLS *</span>
        <FileUploader
          handleChange={f => { BandhanBankXLS(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

        <span className="deposit-entry__top-select-label deposit-entry__label">Shivalik Bank (CustomAccountStatement) XLS *</span>
        <FileUploader
          handleChange={f => { shvalikXLS(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />
        
        <span className="deposit-entry__top-select-label deposit-entry__label">Utkarsh Bank (Personal) XLS *</span>
        <FileUploader
          handleChange={f => { utkarshXLS(f, selectedAccount, setErroredItems); setSelectedAccount(null); setOptionValue(null) }}
          types={["XLS"]}
          name="file"
          classes="lll"
          disabled={selectedAccount ? false : true}
        />

      </div>
      {!erroredItems.length ? (
        <div
          className="modal-confirm__content text-center"
          style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        >
          <div className="modal-confirm__controls">
            <ButtonCustom
              className="cancel-button"
            // onClick={closeFunc}
            >
              Cancel
            </ButtonCustom>
          </div>
        </div>
      ) : (
        <div className="modal-confirm__content text-center">
          <div className="exported-batches__table" style={{ maxHeight: "60vh", overflow: "scroll" }}>
            <ul className={"exported-batches__table-header erroredEntries"}>
              <li>Date</li>
              <li>Description(UTR)</li>
            </ul>
            <div className="exported-batches__table-body">
              {erroredItems &&
                erroredItems.map(report => {
                  // console.log("first", report)
                  return (
                    <div className="exported-batches__table-row">

                      <ul className={"exported-batches__table-row-main-list erroredEntries"}>
                        <li>{report.UTR}</li>
                        <li>{report.amount}</li>

                      </ul>
                    </div>
                  )
                })}
            </div>
          </div>

          <div className="modal-confirm__controls">
            <ButtonCustom className="cancel-button" onClick={() => {
              erroredItems = [];
            }}>
              Close
            </ButtonCustom>

          </div>
        </div>
      )}
      {erroredPopUp ? (
        <ErroredModal
          data={erroredItems}
          closeFunc={() => setErroredPopUp(null)}
          bankType={bankType}
        />
      ) : (
        ""
      )}
    </div>
  )
}

const ErroredModal = ({
  data, bankType, closeFunc = () => {
  }
}) => {
  // console.log(data.map((item) => item))

  return (
    <ModalCustom
      visible={data}
      closeFunc={closeFunc}
      className={"modal-confirm"}
      width={"50%"}
    >
      {!data.length ? (
        <div
          className="modal-confirm__content text-center"
          style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        >
          <div className="modal-confirm__controls">
            <ButtonCustom
              className="cancel-button"
            // onClick={closeFunc}
            >
              Cancel
            </ButtonCustom>
          </div>
        </div>
      ) : (
        <div className="modal-confirm__content text-center">
          <div className="exported-batches__table" style={{ maxHeight: "60vh", overflow: "scroll" }}>
            <ul className={"exported-batches__table-header erroredEntries"}>
              <li>Date</li>
              <li>Description(UTR)</li>
            </ul>
            <div className="exported-batches__table-body">
              {data &&
                data.map(report => {
                  // console.log("first", report)
                  return (
                    <div className="exported-batches__table-row">

                      <ul className={"exported-batches__table-row-main-list erroredEntries"}>
                        <li>{report.UTR}</li>
                        <li>{report.amount}</li>

                      </ul>
                    </div>
                  )
                })}
            </div>
          </div>

          <div className="modal-confirm__controls">
            <ButtonCustom className="cancel-button" onClick={closeFunc}>
              Close
            </ButtonCustom>

          </div>
        </div>
      )}
    </ModalCustom>
  )
}
