import './index.scss'
import CloseCircle from 'mdi-react/CloseCircleIcon'

export default ({ visible, children, className, closeFunc, width }) => {
  return (
    <div className={`custom-modal ${visible ? '' : 'invisible'}`}>
      <div style={{ width }} className={`custom-modal__container ${className ? className : ''}`}>
        {closeFunc && <CloseCircle
          size="36px"
          className="custom-modal__close-icon"
          onClick={closeFunc}
        />}
        {children}
      </div>
    </div>
  )
}