

export const getSidebarData = (userLevel, childrenLevel, userAppMode, userPermissions) => {
    const getAgencyManagementFields = () => {
        const AgencyManagementFields = [{
            title: `${childrenLevel} Listing`,
            path: "/agency-management/downline",
        },
        {
            title: "Position Taking Listing",
            path: "/agency-management/downline-pt/",
        },
        {
            title: "Transfer",
            path: "/agency-management/transfer",
        }];

        if (userLevel === "SMA") {AgencyManagementFields.push({
            title: "Notifications",
            path: "/agency-management/notifications",
        });}

        return AgencyManagementFields;
    };


    return userAppMode !== "Cashier" ? [
        {
            title: "Agency Management",
            subMenu: getAgencyManagementFields(),
        },
        {
            title: "Risk Management",
            subMenu: [
                {
                    title: "Net Exposure",
                    path: "/risk-management/net-exposure",
                },
                {
                    title: "Bet Ticker",
                    path: "/risk-management/bet-ticker",
                },
                {
                    title: "Event Management",
                    path: "/risk-management/event-management",
                },
            ],
        },
        {
            title: "Reports",
            subMenu: [
                {
                    title: "P&L Report by Market",
                    path: "/reports/pnl-by-market",
                },
                {
                    title: "P&L Report by Agent",
                    path: "/reports/pnl-by-agency",
                },
                {
                    title: "Casino Report",
                    path: "/reports/casino-report",
                },
                {
                    title: "Bet List",
                    path: "/reports/bet-list",
                },
                {
                    title: "Transfer Statement",
                    path: "/reports/transfer-statement",
                },
            ],
        },
        {
            title: "Account",
            subMenu: [
                {
                    title: "Balance",
                    path: "/account/balance",
                },
                {
                    title: "Statement",
                    path: "/account/statement#pnl-statement",
                },
            ],
        },
        {
            title: "Payment Management",
            subMenu: [
                {
                    title: "Deposit entry",
                    path: "/payment-management/deposit-entry",
                },
                {
                    title: "Reports",
                    path: "/payment-management/reports",
                },
            ],
        },
    ]
    :
    [
        {
            title: "Deposit",
            subMenu: [
                userPermissions?.includes( "deposit-entry") ? {title: "Deposit entry", path: "/deposit/deposit-entry"} : "",
                userPermissions?.includes( "manager-reports") ? {title: "Manager entry report", path: "/deposit/reports"} : "",
                userPermissions?.includes( "deposit-account-balance") ? {title: "Deposit Account Balance", path: "/deposit/account-balance"} : "",
            ],
        },
        {
            title: "Withdraw",
            subMenu: [
                userPermissions?.includes( "pending-withdrawals") ? {title: "Pending withdrawals", path: "/withdraw/pending-withdrawals"} : "",
                // userPermissions?.includes("export-batch") ? {title: "Create Batch", path: "/withdraw/create-batch"} : "",
                // userPermissions?.includes( "exported-batches") ? {title: "List of Batches", path: "/withdraw/batches-list"} : "",
                userPermissions?.includes( "exported-batches") ? {title: "Export CSV", path: "/withdraw/export-csv"} : "",
                userPermissions?.includes( "exported-batches") ? {title: "My Batches", path: "/exports/exported-batches"} : "",
            ],
        },
        {
            title: "Reports",
            subMenu: [
                userPermissions?.includes( "customer-request-reports") ? {title: "Customer Withdrawals", path: "/reports/withdraw-report"} : "",
                userPermissions?.includes( "customer-request-reports") ? {title: "Customer Deposits", path: "/reports/deposit-report"} : "",
                userPermissions?.includes( "customer-request-reports") ? {title: "All Bank Transactions", path: "/reports/all-bank-transactions"} : "",
                userPermissions?.includes( "plugin-status-report") ? {title: "Plugin Status Report", path: "/reports/plugin-status-report"} : "",
            ],
        },
        // {
        //     title: "Export",
        //     subMenu: [
        //         userPermissions?.includes( "customer-request-reports") ? {title: "Exported Batches", path: "/exports/exported-batches"} : "",
        //     ],
        // },
        {
            title: "Account",
            subMenu: [
                userPermissions?.includes( "deposit-accounts") ? {title: "Account Deposits", path: "/accounts/deposit-account"} : "",
                userPermissions?.includes( "withdrawal-accounts") ? {title: "Account Withdrawals", path: "/accounts/withdraw-account"} : "",
            ],
        },
    ];
};
