import { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import StringToRecolorNumber from "../../components/StringToRecolorNumber"
import { useStorage } from "../../Providers/StorageProvider"
import { numberWithCommas } from "../../utils/dataTransform"
import "./index.scss"
import { useAuth } from "../../Providers/AuthProvider"
import ButtonCustom from "../../components/ButtonCustom"
import { AddingAccountsWithdrawalsPage } from "../AddingAccountsWithdrawalsPage"

const Balance = () => {
  // const { balance, userLevel } = useStorage()
  const [tableData, setTableData] = useState([])
  let [editing, setEditing] = useState(false);
  let [editingType, setEditingType] = useState(false);
  let [editingData, setEditingData] = useState(false);
  const { requestsToApi } = useAuth();
  const { userPermissions } = useStorage();

  // useEffect(() => {
  //   setTableData(
  //     [
  //       ["Net Exposure", balance.actualNetExposure],
  //       ["Balance Down", balance.cashBalance],
  //       ["Balance Up", balance.totalBalance],
  //       ["Credit Limit", balance.smaCredit],
  //       ["Available Credit", balance.availableBalance],
  //       [
  //         `Total credit given to ${userLevel === "MA" ? "Agents" : userLevel === "Agent" ? "Members" : "MAs"}`,
  //         balance.totalDownlineCredit
  //       ],
  //       [userLevel !== "MA" && userLevel !== "Agent" ? "Total credit distributed by MAs" : "", balance.totalMACredit],
  //       [userLevel !== "Agent" ? "Total credit distributed by Agents" : "", balance.totalAgentCredit]
  //     ].map(([title, value], index) =>
  //       index > 2 ? [title, numberWithCommas(value)] : [title, <StringToRecolorNumber>{value}</StringToRecolorNumber>]
  //     )
  //   )
  // }, [balance, userLevel])

  useEffect(async()=>{
    const response = await requestsToApi.get(`/Banking/withdraw/payment-gateways?requestAll=true`)
    if(response.status === 200 && response.data?.result){
      setTableData(response.data.result);
    }
  }, [])

  return (
   !editing? <div className="balance-page">
      <div>
        <div className="deposit-entry__main-header" >
          <h1 className="deposit-entry__main-header-title">Withdraw accounts</h1>
          {userPermissions?.includes( "withdrawal-accounts-edit") &&
            <ButtonCustom color="green" onClick={() => {
              setEditing(true)
              setEditingType('Add')
              setEditingData(false)
            }}>Add New</ButtonCustom>
          }
        </div>
        <div className="accountsPayment__table">
          <ul className={`accountsPayment__table-header`}>
            <li className={`accountsPayment__table-header-sort-button`}>ID</li>
            <li className={`accountsPayment__table-header-sort-button`}>Account No.</li>
            <li className={`accountsPayment__table-header-sort-button`}>IFSC</li>
            <li className={`accountsPayment__table-header-sort-button`}>Name</li>
            <li className={`accountsPayment__table-header-sort-button`}>Bank Name</li>
            <li className={`accountsPayment__table-header-sort-button`}>Active</li>
            {userPermissions?.includes( "withdrawal-accounts-edit") &&<li className={`accountsPayment__table-header-sort-button`}></li>}
          </ul>
          <div className="accountsPayment__table-body">
            {tableData.map((data) =>
              <ul className={`accountsPayment__table-body-row`}>
                <li className={`accountsPayment__table-header-sort-button table__data`}>{data.id}</li>
                <li className={`accountsPayment__table-header-sort-button table__data`}>{data.acNo}</li>
                <li className={`accountsPayment__table-header-sort-button table__data`}>{data.IFSC}</li>
                <li className={`accountsPayment__table-header-sort-button table__data`}>{data.name}</li>
                <li className={`accountsPayment__table-header-sort-button table__data`}>{data.bankName}</li>
                <li>
                  <form action="#">
                    <div className="switchAccount">
                      <input
                        id={data.id + 1}
                        type="checkbox"
                        // onChange={toggleSwitch}
                        className={`switchAccount-input `}
                        checked={data.isActive}
                      />
                      <label htmlFor={data.id + 1} className={`switchAccount__label labelAccount_red`}>Switch</label>
                    </div>
                  </form>
                </li>
                {userPermissions?.includes( "withdrawal-accounts-edit") &&
                  <li className={`accountsPayment__table-body-row-cancel`}>
                     <ButtonCustom color="green" onClick={() => {
                       setEditing(true)
                       setEditingType('Edit')
                       setEditingData(data)
                  }}>Edit</ButtonCustom>
                </li>}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
    :    <AddingAccountsWithdrawalsPage report={editingData} editingType={editingType} setEditing={setEditing}/>

)
}
export default Balance
