import * as XLSX from "xlsx/xlsx.mjs"
// import axios from "axios"
// import { HOST } from "../../../Providers/AuthProvider"

export const NEFTExcel = async (file, setResultArray) => {
  // const dispatch = useDispatch()
  const token = sessionStorage.getItem("accessToken")
  const reader = new FileReader()
  const rABS = !!reader.readAsBinaryString
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target.result
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
    /* Get first worksheet */
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    console.log(rABS, wb)
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
    /* Update state */
    parsing(data)
    console.log("daaaaaaaaa", data)
  }
  if (rABS) reader.readAsBinaryString(file)
  else reader.readAsArrayBuffer(file)

  const parsing = xlsData => {
    let res = []
    let errored = []
    console.log("xlsData", xlsData)
    xlsData.map(item => {
      // console.log('object', item);
      try {
        if (
          item.length === 10 &&
          item[7]
          && item[3] > 0
          && item[8] === "Payment Successful"
        ) {
          console.log('pass');
          res.push({
            UTR: item[7].substring(3),
            amount: item[3],
            payeeAccNo: item[5],
            payeeIFSC: item[4]
            // acNo: selectedAccount
          })
        } else if (
          item.length === 5 &&
          item[2]
          && item[4].includes("IMPS")
        ) {
          res.push({
            UTR: (item[4]?.split("RRN:")[1]).substring(0, 12),
            amount: parseFloat(item[2].toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
            // acNo: selectedAccount
          })
        } else {
          errored.push({
            UTR: item[7].slice(0, 3),
            amount: item[3],
            PayeeAccNo: item[5],
            PayeeIFSC: item[4]
          })
          // dispatch(getDepositEntryErroredItems(['t', 't']))
          console.log('errITM', item);
        }
      } catch (e) {
        console.log(e)
      }
    })
    setResultArray(res)
    // setErroredItems(errored)
    // try {
    //   axios.post(HOST + "/Banking/deposit-entry-bulk",
    //     res,
    //     {
    //       headers: { "Authorization": `${token}` }
    //     }).then((response) => {
    //     console.log(response.data)
    //   })
    // } catch (e) {
    //   console.log(e)
    // // }
    // console.log("DATA TO SEND", res)
    // console.log("ERRORED ITEM (No Amount or UTR)", errored)
  }
  ////// END FEDERAL XSLX //////
}

// export const erroredAU = () => {
//   return asdasd
// }
