import * as XLSX from "xlsx/xlsx.mjs"
import axios from "axios"
import { HOST } from "../../../Providers/AuthProvider"
import moment from "moment"
import jwt_decode from "jwt-decode";

let asdasd = ['t']
export const iciciXLS = async (file, selectedAccount, setErroredItems) => {
  // const dispatch = useDispatch()
  const token = sessionStorage.getItem("accessToken")
  const user = jwt_decode(token);
  const reader = new FileReader()
  const rABS = !!reader.readAsBinaryString
  const myId = sessionStorage.getItem("myId")
  const userName = sessionStorage.getItem("myUserName")
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target.result
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
    /* Get first worksheet */
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    console.log(rABS, wb)
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
    /* Update state */
    parsing(data)
    console.log("daaaaaaaaa", data)
  }
  if (rABS) reader.readAsBinaryString(file)
  else reader.readAsArrayBuffer(file)

  const parsing = xlsData => {
    let res = []
    let errored = []
    let accountEntries = []
    console.log("xlsData", xlsData)
    xlsData.map(item => {
      if (!item[5]?.toString()?.toLowerCase()?.includes("cheque")
          && !item[5]?.toString()?.toLowerCase()?.includes("ref.")) {
      if (
        item.length === 9 &&
        item[6] == 'CR' &&
        item[7] && item[5] && (item[5].includes("UPI") || item[5].includes("IMPS"))
      ) {
        res.push({
          UTR: item[5].includes("UPI") ? (item[5]?.split("/")[1]) : (item[5]?.split("/")[2]),
          amount: parseFloat(item[7].toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
          acNo: selectedAccount
        })
        const date = moment(item[2], 'DD-MM-YYYY').format("YYYY-MM-DD");
        const obj = {
          description: item[5],
          utr: item[5].includes("UPI") ? (item[5]?.split("/")[1]) : (item[5]?.split("/")[2]),
          accountNumber: selectedAccount,
          amount: parseFloat(item[7].toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
          isDeposit: true,
          userId: myId,
          userName: userName,
          psId: user.paymentSystemId,
          isPlugin: false,
          date: date + "T00:00:00.000Z",
          isRecognized: true,
          isProcessed: true
        }
        accountEntries.push(obj)
      } else if (item.length === 9 &&
        item[6] == 'DR' &&
        item[7] && item[5] && (item[5].includes("UPI") || item[5].includes("IMPS"))) {
        const date = moment(item[2], 'DD-MM-YYYY').format("YYYY-MM-DD");
        const obj = {
          description: item[5],
          utr: item[5].includes("UPI") ? (item[5]?.split("/")[1]) : (item[5]?.split("/")[2]),
          accountNumber: selectedAccount,
          amount: parseFloat(item[7].toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
          isDeposit: false,
          userId: myId,
          userName: userName,
          psId: user.paymentSystemId,
          isPlugin: false,
          date: date + "T00:00:00.000Z",
          isRecognized: true,
          isProcessed: true
        }
        accountEntries.push(obj)
      } else if (item.length === 9 &&
        (item[6] == 'DR' || item[6] == 'CR') &&
        item[7] && item[5]) {
        const date = moment(item[2], 'DD-MM-YYYY').format("YYYY-MM-DD");
        const obj = {
          description: item[5],
          utr: "",
          accountNumber: selectedAccount,
          amount: parseFloat(item[7].toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
          isDeposit: item[6] == 'CR' ? true : false,
          userId: myId,
          userName: userName,
          psId: user.paymentSystemId,
          isPlugin: false,
          date: date + "T00:00:00.000Z",
          isRecognized: false,
          isProcessed: false
        }
        accountEntries.push(obj)
      } else if (item.length === 9 && item[5]) {
        errored.push({
          UTR: item[5],
          amount: item[7],
          CR: item[6]
        })
        // dispatch(getDepositEntryErroredItems(['t', 't']))
      }
    }
    })
    setErroredItems(errored)
    try {
      axios.post(HOST + "/Banking/deposit-entry-bulk",
        res,
        {
          headers: { "Authorization": `${token}` }
        }).then((response) => {
          console.log(response.data)
        })
      axios.post("https://banking.upicash.com/api/account_entries/",
        accountEntries,
        {
          headers: { "Authorization": `${token}` }
        }).then((response) => {
          console.log(response.data)
        })
    } catch (e) {
      console.log(e)
    }
    console.log("DATA TO SEND", res)
    console.log("DATA TO SEND", accountEntries)
    console.log("ERRORED ITEM (No Amount or UTR)", errored)
  }
}

export const erroredAU = () => {
  return asdasd
}
