import "./index.scss";
import ApproveWithdrawalPopUp from "../../components/ApproveWithdrawalPopUp";
import ButtonCustom from "../../components/ButtonCustom";
import InfoPopUp from "../../components/InfoPopUp";
import SearchCustom from "../../components/SearchCustom";
import moment from "moment";
import { useAuth } from "../../Providers/AuthProvider";
import { useEffect, useState } from "react";
import sound from "../../static/sound/26055615_alert_sound.mp3";


export default () => {
  const { requestsToApi } = useAuth();
  const [reportsData, setReportsData] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [choseReport, setChoseReport] = useState({});
  const [chosenReportIndex, setChosenReportIndex] = useState(-1);
  const [sortByDesc, setSortByDesc] = useState({ createdAt: true, updatedAt: false, amount: false });
  const [sortedReports, setSortedReports] = useState([]);
  const [amountFrom, setAmountFrom] = useState('')
  const [amountTo, setAmountTo] = useState('')

  const [audio] = useState(new Audio(sound))
  const [newWithdrawal, setNewWithdraval] = useState(0)

  const [isloading, setIsLoading] = useState(false);

  const startAudio = () => {
    audio.play()
  }

  const firstRender = async () => {
    try {
      const response = await requestsToApi.get("/banking/dw-report" + "?status=Pending" + "&type=W");
      setSortedReports(response.data.result.sort((a, b) => { if (a.createdAt > b.createdAt) return -1; }));
      setNewWithdraval(response.data.result.length)
      setReportsData(response.data.result);
    } catch (error) {
      console.log(error?.message);
    }
  }



  let interval;
  const getData = () => {
    interval = setInterval(async () => {
      try {
        const response = await requestsToApi.get("/banking/dw-report" + "?status=Pending" + "&type=W");
        setSortedReports(response.data.result.sort((a, b) => { if (a.createdAt > b.createdAt) return -1; }));
        setReportsData(response.data.result);
      } catch (error) {
        console.log(error?.message);
      }
    }, 10000);
  };

  const openDepositPopUp = (report, index) => {
    setChosenReportIndex(index)
    setChoseReport(report);
    if (report.status === "Pending") {
      setShowPopUp(prevState => !prevState);
    }
  };
  const handleClickApply = async () => {
    try {
      setIsLoading(true);
      setSortedReports([]);
      setReportsData([]);

      const response = await requestsToApi.get("/banking/dw-report" + "?status=Pending" + "&type=W");
      setSortedReports(response.data.result.sort((a, b) => { if (a.createdAt > b.createdAt) return -1; }));
      setReportsData(response.data.result);
      // alert(response.data.result.length + " reports found");

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error?.message);
    }
    // getData();
  };

  useEffect(() => {
    if (reportsData.length > newWithdrawal) {
      startAudio()
      setNewWithdraval(reportsData.length)
    }
  }, [reportsData])

  useEffect(() => {
    firstRender();
    // getData();
    return function cleanup() {
      clearInterval(interval);
    };
  }, []);

  const handleClickClear = () => {
    clearInterval(interval);
    setAmountFrom('')
    setAmountTo('')
  };

  const changeSort = (key) => {
    setSortByDesc({ createdAt: false, updatedAt: false, amount: false, [key]: !sortByDesc[key] });

    if (reportsData.length >= 2) {
      if (key === "amount") {
        setSortedReports(reportsData?.sort((a, b) => sortByDesc[key] ? a[key] - b[key] : b[key] - a[key]));
      }
      if (key !== "amount") {
        const filter = reportsData.filter(item => item[key]);

        const sort = filter.sort((a, b) => {
          if (a[key] > b[key]) return -1;
        });
        setSortedReports(sortByDesc[key] ? [...sort.reverse(), ...reportsData.filter(item => !item[key])] : [...sort, ...reportsData.filter(item => !item[key])]);
      }
    }
  };

  const onAmountFrom = (e) => {
    setAmountFrom(e)
  }

  const onAmountTo = (e) => {
    setAmountTo(e)
  }


  const handleCloseFunc = (success) => {
    if (success) {
      sortedReports.splice(chosenReportIndex, 1)
      setChosenReportIndex(-1)
    }
    // if(success) firstRender();
    setShowPopUp(false);
  }

  return (
    <div className="pending-withdrawals-reports">
      <div className="pending-withdrawals-reports__header">
        <div className="pending-withdrawals-reports__header-text">
          <h1>Pending withdrawals</h1>
        </div>
        <div className='pending-withdrawals-reports__header-filter'>
          <input
            type="text"
            placeholder='From'
            onChange={(e) => onAmountFrom(e.target.value)}
            value={amountFrom}
          />
          <input
            type="text"
            placeholder='To'
            onChange={(e) => onAmountTo(e.target.value)}
            value={amountTo}
          />
        </div>
        <div className="pending-withdrawals-reports__header-search-column">
          <div className="pending-withdrawals-reports__header-search-column-buttons">
            {/* disable apply button if isLoading */}
            <ButtonCustom onClick={handleClickApply} disabled={isloading}
              color="cyan">Apply</ButtonCustom>
            <ButtonCustom onClick={handleClickClear} color="red">Clear</ButtonCustom>
          </div>
          <div className="pending-withdrawals-reports__header-search-column-search-wrapper">
            <SearchCustom onChange={(value) => console.log(value)} />
          </div>
        </div>
      </div>
      <div className="pending-withdrawals-reports__table">
        <ul className="pending-withdrawals-reports__table-header">
          <li onClick={() => changeSort("createdAt")}>
            <span className={`pending-withdrawals-reports__table-header-sort ${sortByDesc.createdAt ? "" : "up"}`}>CreatedAt</span>
          </li>
          <li onClick={() => changeSort("updatedAt")}>
            <span className={`pending-withdrawals-reports__table-header-sort ${sortByDesc.updatedAt ? "" : "up"}`}>CompletedAt</span>
          </li>
          <li>ID</li>
          <li>Username</li>
          <li onClick={() => changeSort("amount")}>
            <span className={`pending-withdrawals-reports__table-header-sort ${sortByDesc.amount ? "" : "up"}`}>Amount</span>
          </li>
          <li>Account No</li>
          <li>Bank</li>
          <li>Account Holder</li>
          <li>Status</li>
          <li>Bank manager</li>
          <li>IP</li>
        </ul>
        <div className="pending-withdrawals-reports__table-body">
          <div className="alert alert-success alert-dismissible" role="alert" >
          {/* <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button> */}
            {sortedReports.length > 0 ? sortedReports.length + " Records found" : "Loading..."   }  </div>

            {sortedReports?.filter(i => (amountFrom ? i.amount >= amountFrom : i.amount > 0) && (amountTo ? i.amount <= amountTo : i.amount > 0)).map((report, index) =>
              <ul onClick={() => openDepositPopUp(report, index)} key={report.requestId + index}
                className="pending-withdrawals-reports__table-body-row">
                <li className="pending-withdrawals-reports__table-body-row-date">
                  <div>{moment(report?.createdAt).format("DD.MM.YY")}</div>
                  <div className="time">{moment(report?.createdAt).format("LTS")}</div>
                </li>
                <li className="pending-withdrawals-reports__table-body-row-date">
                  {report.updatedAt &&
                    <div>
                      <div>{moment(report.updatedAt).format("DD.MM.YY")}</div>
                      <div className="time">{moment(report.updatedAt).format("LTS")}</div>
                    </div>}
                </li>
                <li>{report?.userId}</li>
                <li><a
                  href={"https://monitoring.dev.saikiseva.com/secret-room/" + report?.memberCode}
                  target="_blank" rel="noreferrer"
                >{report?.loginName}</a></li>
                <li>{report?.amount}</li>
                <li>{report?.account?.accountNo}</li>
                <li className="pending-withdrawals-reports__table-body-row-date">
                  <div>
                    <div>{report?.account?.IFSC}</div>
                    <div>{report?.account?.bankName}, {report?.account?.branchName}</div>
                  </div>
                </li>
                <li>{report?.account?.accountHolderName}</li>
                <li>{report?.status}</li>
                <li>{report?.manager}</li>
                <li>{report?.IP}</li>
              </ul>,
            )}

        </div>
        <InfoPopUp />
        <ApproveWithdrawalPopUp
          data={choseReport}
          visible={showPopUp}
          closeFunc={handleCloseFunc}
        />
        {/*{reportsData && reportsData[0] && <PaginationCustom totalPages={20} activePage={1} />}*/}
      </div>
    </div>
  );
};
