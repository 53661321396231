import "./index.scss"
import { useEffect, useRef, useState } from "react"
import InfoPopUp from "../../components/InfoPopUp"
import axios from "axios"
import { HOST, useAuth } from "../../Providers/AuthProvider"
import MdClose from 'mdi-react/CloseBoldIcon'
import { useHistory } from "react-router-dom"
import * as fs from 'fs';
import logoUPI from '../../static/images/UPI.png'
import logoIMPS from '../../static/images/IMPS2.png'
import { toastr } from "react-redux-toastr"
import Select from "react-select"

export const AddingPaymentAccountsPage = ({ report, editingType, setEditing }) => {
  const { requestsToApi } = useAuth()
  const minInput = useRef(null)
  const submitButton = useRef(null)
  let [amountValidationCheck, setAmountValidationCheck] = useState(false)
  let [utrRefValidationCheck, setUtrRefValidationCheck] = useState(false)
  let [isManualSwitcher, setIsManualSwitcher] = useState(report?.isManual || false)
  let [isTierSwitcher, setIsTierSwitcher] = useState(report?.isTier || false)
  let [currentTiers, setCurrentTiers] = useState(report?.tier && report?.tier.split(',').length === 5 ? report?.tier.split(',') : [0, 0, 0, 0, 0])
  let [isActiveSwitcher, setIsActiveSwitcher] = useState(report?.isActive || false)
  let [isUPISwitcher, setIsUPISwitcher] = useState(report?.isUPI || false)
  let [isImage, setIsImage] = useState(report?.logo || "")
  let [manualImageUrl, setManualImageUrl] = useState("")

  const token = sessionStorage.getItem("accessToken")

  const [selectedImage, setSelectedImage] = useState(
    report?.logo ? report?.logo : "https://lotusbookgaming.com/gstatic/paymentgateway/UPI.png"
  )

  const [selectedTier, setSelectedTier] = useState(
    report?.tier ? report?.tier : "1"
  )

  const images = [
    { name: 'UPI', url: 'https://lotusbookgaming.com/gstatic/paymentgateway/UPI.png' },
    { name: 'IMPS', url: 'https://lotusbookgaming.com/gstatic/paymentgateway/IMPS2.png' },
    { name: 'NEFT', url: 'https://lotusbookgaming.com/gstatic/paymentgateway/NEFT@4x.png' }
    // { name: 'Image 3', url: 'https://example.com/image3.jpg' }
  ]

  const tiers = [
    { 'name': 'Tier 1', 'value': '1' },
    { 'name': 'Tier 2', 'value': '2' },
    { 'name': 'Tier 3', 'value': '3' },
    { 'name': 'Tier 4', 'value': '4' },
    { 'name': 'Tier 5', 'value': '5' }
  ]

  const bankLogoUrl = [
    { name: "Select Logo", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg" },
    { name: "AXIS BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/AXIX_BANK.svg" },
    { name: "CITY BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CITY_BANK.svg" },
    { name: "SBI BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/SBI.svg" },
    { name: "HDFC BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/HDFC_BANK.svg" },
    { name: "ICICI BANK", url: " https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/ICICI_BANK.svg" },
    { name: "PAYTM PAYMENTS BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/PAYTM_PAYMENTS_BANK.svg" },
    { name: "ABN AMRO BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/ABN_AMRO.svg" },
    { name: "ADCB BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/ADCB_BANK.svg" },
    { name: "AIRTEL PAYMENT BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/AIRTEL_PAYMENT_BANK.svg" },
    { name: "AMERICAN EXPRESS", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/AMERICAN_EXPRESS.svg" },
    { name: "ANZ BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/ANZ.svg" },
    { name: "AU SMALL FINANCE BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/AU_SMALL_FINANCE_BANK.svg" },
    { name: "BANDHAN BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BANDHAN_BANK.svg" },
    { name: "BANK OF MAHARASTHRA", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BANK+OF+MAHARASTHRA.svg" },
    { name: "BANK OF AMERICA", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BANK_OF_AMERICA.svg" },
    { name: "BANK OF BARODA", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BANK_OF_BARODA.svg" },
    { name: "BANK OF CHINA", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BANK_OF_CHINA.svg" },
    { name: "WESTPAC BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/bank--63.svg" },
    { name: "BARCLAYS BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BARCLAYS.svg" },
    { name: "BBK BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BBK.svg" },
    { name: "BOC BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BOC.svg" },
    { name: "BOI BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BOI.svg" },
    { name: "BPN PARIBAS BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BPN_PARIBAS.svg" },
    { name: "CANARA BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CANARA_BANK.svg" },
    { name: "CBEP BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CBEP_BANK.svg" },
    { name: "CENTAL BANK OF INDIA", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CENTAL_BANK_OF_INDIA.svg" },
    { name: "CHASE BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CHASE.svg" },
    { name: "CITY UNION BANK LTD", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CITY_UNION_BANK_LTD.svg" },
    { name: "CREDIT AGRICOLE", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CREDIT_AGRICOLE.svg" },
    { name: "CREDIT SUISSE", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CREDIT_SUISSE.svg" },
    { name: "CSB BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CSB_BANK.svg" },
    { name: "DBS BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/DBS.svg" },
    { name: "DCB BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/DCB_BANK.svg" },
    { name: "DEUTSCHE BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/DEUTSCHE_BANK.svg" },
    { name: "DHANLAXMI BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/DHANLAXMI_BANK.svg" },
    { name: "DOHA BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/DOHA_BANK.svg" },
    { name: "EMIRATES NBD", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/EMIRATES_NBD.svg" },
    { name: "FINO PAYMENTS BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/FINO_PAYMENTS_BANK.svg" },
    { name: "FIRST ABU DHABI BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/FIRST_ABU_DHABI_BANK.svg" },
    { name: "FIRST RAND", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/FIRST_RAND.svg" },
    { name: "HANA BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/HANA_BANK.svg" },
    { name: "HSBC BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/HSBC.svg" },
    { name: "ICBC BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/ICBC.svg" },
    { name: "IDBI BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/IDBI_BANK.svg" },
    { name: "IDFC FIRST BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/IDFC_FIRST_BANK.svg" },
    { name: "INDIA POST PAYMENTS BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/INDIA_POST_PAYMENTS_BANK.svg" },
    { name: "INDIAN BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/INDIAN_BANK.svg" },
    { name: "INDIAN OVERSEAS BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/INDIAN_OVERSEAS_BANK.svg" },
    { name: "INDUSLND BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/INDUSLND_BANK.svg" },
    { name: "INDUSTRIAL BANK OF KOREA", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/INDUSTRIAL_BANK_OF_KOREA.svg" },
    { name: "J & K BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/J%26K_BANK.svg" },
    { name: "JIO PAYMENT BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/JIO_PAYMENT_BANK.svg" },
    { name: "KARNATAKA BANK LTD", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/KARNATAKA_BANK_LTD.svg" },
    { name: "KB BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/KB.svg" },
    { name: "KOTAK BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/KOTAK.svg" },
    { name: "KRUNGTHAI BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/KRUNGTHAI_BANK.svg" },
    { name: "MIZIHO BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/MIZIHO.svg" },
    { name: "MUFG BANK ", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/MUFG.svg" },
    { name: "PUNJAB & SIND BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/PUNJAB_%26_SIND_BANK.svg" },
    { name: "PUNJAB NATIONAL BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/PUNJAB_NATIONAL_BANK.svg" },
    { name: "QNB BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/QNB.svg" },
    { name: "RBL BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/RBL_BANK.svg" },
    { name: "SCOTIA BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/SCOTIA_BANK.svg" },
    { name: "SHINHAN BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/SHINHAN_BANK.svg" },
    { name: "SMBC BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/SMBC.svg" },
    { name: "SOUTH INDIAN BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/SOUTH_INDIAN_BANK.svg" },
    { name: "STANDARD CHARTERED BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/STANDARD_CHARTERED_BANK.svg" },
    { name: "TAMILNAD MERCANTILE BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/TAMILNAD_MERCANTILE_BANK.svg" },
    { name: "UBO BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/UBO_BANK.svg" },
    { name: "UCO BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/UCO_BANK.svg" },
    { name: "UJJIVAN SMALL FINANCE BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/UJJIVAN_SMALL_FINANCE_BANK.svg" },
    { name: "UNION BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/UNION_BANK.svg" },
    { name: "WOORI BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/WOORI_BANK.svg" },
    { name: "YES BANK", url: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/YES_BANK.svg" }
  ]

  let [inputs, setInputs] = useState({
    nameState: report?.name || "", min: report?.min || "", max: report?.max || "", promo: report?.promo || "", index: report?.index || "", acNo: report?.account?.acNo || "", IFSC: report?.account?.IFSC || "", bankName: report?.account?.bankName || "", bankLogo: report?.account?.bankLogo || "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg", accName: report?.account?.name || ""
  });

  const [ifscInput, setIfscInput] = useState(report?.account?.IFSC);
  const [ifscDetails, setIfscDetails] = useState();
  let [errorMessage, setErrorMessage] = useState()
  let [nnn, setNnn] = useState(true)
  let [isEmpty, setIsEmpty] = useState({
    nameState: false,
    min: false,
    max: false,
    index: false,
    acNo: false,
    IFSC: false,
    bankName: false,
    accName: false
  })

  const fileTypes = ["JPG", "PNG", "GIF"]

  const [file, setFile] = useState(null)
  const [file64, setFile64] = useState(null)

  const handleChange = async file => {
    setFile(file)
    setIsImage(URL.createObjectURL(file))
    if (file) {
      const reader = new FileReader();

      reader.onloadend = async () => {
        const base64 = reader.result;
        try {
          const response = await axios.post(
            `http://13.234.202.89/image/`,
            { image: base64 }
          )


          console.log('reasrer', response)
          if (response.data.data) {
            setManualImageUrl(response.data.data.image)
            console.log("SUCCESS", response.data.data)
          }
        } catch (error) {
          console.log("ERROR", error)
        }
        console.log(base64);
      }

      reader.readAsDataURL(file);


      // Declare an array of image objects with a name and URL

      // const token = sessionStorage.getItem("accessToken")
      // let myHeaders = new Headers()
      // myHeaders.append("Authorization", `${token}`)
      //   const toBase64 = file => new Promise((resolve, reject) => {
      //     const reader = new FileReader();
      //     reader.readAsDataURL(file);
      //     reader.onload = () => resolve(reader.result);
      //     reader.onerror = error => reject(error);
      // });

      // async function Main() {
      //   //  const file = document.querySelector('#myfile').files[0];
      //    console.log(await toBase64(file));
      //    setFile64(await toBase64(file))

      // }

      // Main();
      // setFile64(await toBase64(file))

      // let formdata = new FormData()
      // formdata.append("file", file64)
      // let requestOptions = {
      //   method: "POST",
      //   // headers: myHeaders,
      //   body: formdata
      //   // redirect: "follow"
      // }


    }
  }

  const toggleSwitchTier = () => {
    // console.log(index);
    setIsTierSwitcher(!isTierSwitcher)
  }

  const toggleSwitchTierSelect = (event, index) => {
    // console.log(index, event.target.value, currentTiers);
    let temp = [...currentTiers]
    console.log(temp);
    temp[index] = temp[index] === 0 ? 1 : 0;
    console.log(temp);
    setCurrentTiers(temp);
  }

  const toggleSwitchManual = () => {
    setIsManualSwitcher(!isManualSwitcher)
  }

  const toggleSwitchActive = () => {
    setIsActiveSwitcher(!isActiveSwitcher)
  }
  const toggleSwitchUPI = () => {
    setIsUPISwitcher(!isUPISwitcher)
  }

  const resetInputs = () => {
    setInputs({ nameState: "", min: "", max: "", promo: "", index: "", acNo: "", IFSC: "", bankName: "", bankLogo: "", accName: "" })
    setIsManualSwitcher(false)
    setIsActiveSwitcher(false)
    setIsUPISwitcher(false)
    setFile(null)
    setIsImage('')
    setNnn(false)
    setTimeout(() => {
      setNnn(true)
    }, 50)
  }

  const inputsValidation = () => {
    const newObj = {
      nameState: false,
      min: false,
      max: false,
      index: false,
      acNo: false,
      IFSC: false,
      bankName: false,
      accName: false
    }
    Object.keys(inputs).map(item => {
      if (inputs[item] == "") {
        newObj[item] = true
      }
    })
    setIsEmpty(newObj)
  }

  const handleFormSubmit = async e => {
    if (
      !inputs.nameState ||
      !inputs.min ||
      !inputs.max ||
      !inputs.index ||
      !inputs.acNo ||
      !inputs.bankName ||
      !inputs.accName
    ) {
      inputsValidation()
      setErrorMessage("Please fill all required fields!")
      setTimeout(() => {
        setErrorMessage("")
      }, 5000)
      return
    }
    e.preventDefault()
    let firstReq = false
    let secondReq = false

    try {
      const response = await (editingType === "Add" ? axios.post : axios.put)(
        HOST + "/Banking/deposit/payment-gateways",
        {
          path: report?.path ? report?.path : null,
          isManual: isManualSwitcher,
          isActive: isActiveSwitcher,
          name: inputs.nameState,
          logo: selectedImage ? selectedImage : report?.logo ? report?.logo : "",
          id: report?.id ? report?.id : "",
          min: inputs.min,
          max: inputs.max,
          promo: inputs.promo,
          index: inputs.index,
          isUPI: isUPISwitcher,
          isTier: isTierSwitcher,
          tier: currentTiers.toString(),
          account: {
            isActive: report?.account?.isActive ? report?.account?.isActive : false,
            acNo: inputs.acNo,
            IFSC: inputs.IFSC,
            name: inputs.accName,
            bankName: inputs.bankName,
            min: report?.account?.min ? report?.account?.min : null,
            max: report?.account?.max ? report?.account?.max : null,
            bankLogo: inputs.bankLogo
          }
        },
        { headers: { Authorization: `${token}` } }
      )

      if (response.data.success) {
        console.log("SUCCESS")
        firstReq = true
        toastr.success('Account added successfully')
        setEditing(false)

      }
    } catch (error) {
      console.log("ERROR", error)
      toastr.error("Something went wrong, please try again")
    }

    if (file) {
      let myHeaders = new Headers()
      myHeaders.append("Authorization", `${token}`)

      let formdata = new FormData()
      formdata.append("file", file, "[PROXY]")
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
      }

      try {
        const response = await fetch(
          HOST + `/PromoMaterial/upload?bucketName=lotusbookgaming&prefix=gstatic/paymentgateway/${inputs.nameState}/`,
          requestOptions
        )

        if (response.data.success) {
          console.log("SUCCESS")
          secondReq = true
        }
      } catch (error) {
        console.log("ERROR", error)
      }
    }
    if (!file && firstReq) {
      setEditing(false)
    } else if (file && firstReq && secondReq) {
      setEditing(false)
    }
  }
  // This code for make axios request to get bank details on IFSC Code.
  useEffect(() => {
    setInputs({ ...inputs, ["IFSC"]: ifscInput });
    if (ifscInput?.length === 11) {
      axios.get(`${HOST}/Banking/ifsc?ifsc=${ifscInput}`,
        { headers: { Authorization: `${token}` } })
        .then((response) => {
          console.log(response);
          if (response.data.success) {
            setIfscDetails(response.data.result);
            setInputs({ ...inputs, ["bankName"]: response.data.result.BANK, ["IFSC"]: ifscInput });
            console.log(response.data.result)
          }
        }).catch((error) => {
          setIfscDetails("ERROR");
          console.log(error)
        })
    }
  }, [ifscInput])

  // console.log(ifscDetails);

  return (
    <div className="newPayment-entry__main">
      <h1>{editingType === "Add" ? "New Payment Account" : "Edit Payment Account"}</h1>
      <div className="newPayment-entry">
        <div className="newPayment-entry__form-wrapper">
          <form action="#">
            <div className="newPayment-entry__form">
              <div className="newPayment-entry__form-leftSide">
                <h2 className="newPayment-entry__form-title">
                  {editingType === "Add" ? "New Payment Account" : "Edit Payment Account"}
                </h2>
                <div className="newPayment-entry__form-input-wrapper">
                  <label className="newPayment-entry__top-input-label newPayment-entry__label">Name</label>
                  <input
                    type="text"
                    name={"Name"}
                    value={inputs.nameState}
                    onChange={e => {
                      setInputs({ ...inputs, ["nameState"]: e.target.value })
                      e.target.value && setIsEmpty({ ...isEmpty, ["nameState"]: false })
                    }}
                    className={`newPayment-entry__form-input ${isEmpty.nameState && "error"}`}
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="newPayment-entry__top-input">
                  <div className="newPayment-entry__top-input-item">
                    <label className="newPayment-entry__top-input-label newPayment-entry__label">isManual</label>
                    <form action="#">
                      <div className="switchAccount" style={{ marginBottom: "20px" }}>
                        <input
                          id={"Manual"}
                          type="checkbox"
                          defaultChecked={false}
                          onChange={toggleSwitchManual}
                          className={`switchAccount-input `}
                          checked={isManualSwitcher}
                          required
                        />
                        <label htmlFor={"Manual"} className={`switchAccount__label labelAccount_red`}>
                        </label>
                      </div>
                    </form>
                  </div>
                  <div className="newPayment-entry__top-input-item">
                    <label className="newPayment-entry__top-input-label newPayment-entry__label">isActive</label>
                    <form action="#">
                      <div className="switchAccount" style={{ marginBottom: "20px" }}>
                        <input
                          id={"Active"}
                          type="checkbox"
                          onChange={toggleSwitchActive}
                          defaultChecked={false}
                          className={`switchAccount-input `}
                          checked={isActiveSwitcher}
                          required
                        />
                        <label htmlFor={"Active"} className={`switchAccount__label labelAccount_red`}>
                        </label>
                      </div>
                    </form>
                  </div>
                  <div className="newPayment-entry__top-input-item">
                    <label className="newPayment-entry__top-input-label newPayment-entry__label">isUPI</label>
                    <form action="#">
                      <div className="switchAccount" style={{ marginBottom: "20px" }}>
                        <input
                          id={"UPI"}
                          type="checkbox"
                          onChange={toggleSwitchUPI}
                          className={`switchAccount-input `}
                          defaultChecked={false}
                          checked={isUPISwitcher}
                          required
                        />
                        <label htmlFor={"UPI"} className={`switchAccount__label labelAccount_red`}>
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="newPayment-entry__form-input-wrapper">
                  <label className="newPayment-entry__top-input-label newPayment-entry__label">Min</label>
                  <input
                    autoComplete="off"
                    type="number"
                    name={"Min-ref"}
                    value={inputs.min}
                    onChange={e => {
                      setInputs({ ...inputs, ["min"]: e.target.value })
                      e.target.value && setIsEmpty({ ...isEmpty, ["min"]: false })
                    }}
                    ref={minInput}
                    required
                    className={`newPayment-entry__form-input ${isEmpty.min && "error"}`}
                    placeholder="Min"
                  />
                </div>
                <div className="newPayment-entry__form-input-wrapper">
                  <label className="newPayment-entry__top-input-label newPayment-entry__label">Max</label>
                  <input
                    autoComplete="off"
                    type="number"
                    name={"Max-ref"}
                    value={inputs.max}
                    onChange={e => {
                      setInputs({ ...inputs, ["max"]: e.target.value })
                      e.target.value && setIsEmpty({ ...isEmpty, ["max"]: false })
                    }}
                    required
                    className={`newPayment-entry__form-input ${isEmpty.max && "error"}`}
                    placeholder="Max"
                  />
                </div>
                <div className="newPayment-entry__form-input-wrapper">
                  <label className="newPayment-entry__top-input-label newPayment-entry__label">Promo</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name={"Promo-ref"}
                    value={inputs.promo}
                    onChange={e => {
                      setInputs({ ...inputs, ["promo"]: e.target.value })
                      e.target.value && setIsEmpty({ ...isEmpty, ["promo"]: false })
                    }}
                    className={`newPayment-entry__form-input`}
                    placeholder="Promo"
                  />
                </div>
                <div className="newPayment-entry__form-input-wrapper">
                  <label className="newPayment-entry__top-input-label newPayment-entry__label">Index</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name={"index-ref"}
                    value={inputs.index}
                    onChange={e => {
                      setInputs({ ...inputs, ["index"]: e.target.value })
                      e.target.value && setIsEmpty({ ...isEmpty, ["index"]: false })
                    }}
                    required
                    className={`newPayment-entry__form-input ${isEmpty.index && "error"}`}
                    placeholder="Index"
                  />
                </div>

              </div>

              <div className="newPayment-entry__form-rightSide">
                <div className="newPayment-entry__form-title">
                  Account Info{" "}
                  <div
                    className="pull-right"
                    onClick={() => {
                      setEditing(false)
                    }}
                  >
                    <MdClose />
                  </div>
                </div>
                <div className="newPayment-entry__form-input-wrapper">
                  <label className="newPayment-entry__top-input-label newPayment-entry__label">AcNo</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name={"AcNo-ref"}
                    value={inputs.acNo}
                    onChange={e => {
                      setInputs({ ...inputs, ["acNo"]: e.target.value })
                      e.target.value && setIsEmpty({ ...isEmpty, ["acNo"]: false })
                    }}
                    required
                    className={`newPayment-entry__form-input ${isEmpty.acNo && "error"}`}
                    placeholder="AcNo"
                  />
                </div>
                <div className="newPayment-entry__form-input-wrapper">
                  <label className="newPayment-entry__top-input-label newPayment-entry__label">IFSC</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name={"IFSC-ref"}
                    maxLength={11}
                    value={ifscInput} onChange={e => {
                      setIfscInput(e.target.value);
                      e.target.value && setIsEmpty({ ...isEmpty, ["IFSC"]: false })
                    }}
                    required
                    className={`newPayment-entry__form-input ${isEmpty.IFSC || ifscInput?.length === 0 && "error"}`}
                    placeholder="IFSC"
                    style={{ textTransform: "uppercase" }}
                  />

                  {ifscDetails && <>{
                    ifscDetails !== "ERROR" ?
                      <div
                        style={{ display: "flex", flexDirection: "row", columnGap: "5px", fontSize: "10px" }}
                      >
                        <span>BRANCH: {ifscDetails.BRANCH},</span>
                        <span>DISTRICT: {ifscDetails.DISTRICT},</span>
                        <span>STATE: {ifscDetails.STATE}</span>
                      </div>
                      :
                      <div style={{ color: "red" }}>
                        Please check IFSC code, Bank not found !
                      </div>}
                  </>
                  }

                </div>
                <div className="newPayment-entry__form-input-wrapper">
                  <label className="newPayment-entry__top-input-label newPayment-entry__label">Bank Name</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name={"BankName-ref"}
                    value={inputs.bankName}
                    onChange={e => {
                      setInputs({ ...inputs, ["bankName"]: e.target.value })
                      e.target.value && setIsEmpty({ ...isEmpty, ["bankName"]: false })
                    }}
                    className={`newPayment-entry__form-input ${isEmpty.bankName && "error"}`}
                    placeholder="Bank Name"
                    required
                  />
                  <div>
                    <label htmlFor="image-select">Select Logo:</label>
                    {inputs.bankLogo &&
                      <img style={{ width: '30px', height: "30px" }} src={`${inputs?.bankLogo}`} alt="Bank Logo" />}
                    <select
                      id="image-select"
                      style={{ color: "black", marginLeft: "10px" }}
                      value={inputs.bankLogo}
                      onChange={e => setInputs({ ...inputs, ["bankLogo"]: e.target.value })}
                    >
                      {bankLogoUrl.map(image => (
                        <option value={image.url} key={image.name}>
                          {image.name}
                        </option>
                      ))}
                    </select>
                  </div>

                </div>
                <div className="newPayment-entry__form-input-wrapper">
                  <label className="newPayment-entry__top-input-label newPayment-entry__label">Name</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name={"Name-ref"}
                    value={inputs.accName}
                    onChange={e => {
                      setInputs({ ...inputs, ["accName"]: e.target.value })
                      e.target.value && setIsEmpty({ ...isEmpty, ["accName"]: false })
                    }}
                    className={`newPayment-entry__form-input ${isEmpty.accName && "error"}`}
                    placeholder="Name"
                    required
                  />
                </div>

                {/* <label className="newPayment-entry__top-input-label newPayment-entry__label">Logo</label> */}

                {/* {nnn && <FileUploader handleChange={handleChange} name="file" types={fileTypes} classes="lll" />} */}
                {/* <input type="select">
                  <option value="fff"></option>
                  <option value="aaaf"></option>

                </input> */}
                <div>
                  <label htmlFor="image-select">Select an image:</label>
                  <select
                    id="image-select"
                    style={{ color: 'black', marginLeft: '10px' }}
                    value={selectedImage}
                    onChange={e => setSelectedImage(e.target.value)}
                  >
                    {images.map(image => (
                      <option value={image.url} key={image.name}>
                        {image.name}
                      </option>
                    ))}
                  </select>
                  {/* {selectedImage && <img style={{width: '50%'}} src={selectedImage} alt="Selected image" />} */}
                </div>

                <div
                  className="accountsPayment__table-body-row-logo"
                  style={{
                    width: "150px",
                    height: "80px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px"
                  }}
                >
                  {selectedImage && <img style={{ width: "80%", height: "50%" }} src={selectedImage} alt="" />}
                </div>


                <div className="newPayment-entry__top-input">
                  <div className="newPayment-entry__top-input-item" style={{display: "flex", flexDirection: "column"}}>
                    <label className="newPayment-entry__top-input-label newPayment-entry__label">Tier Enable</label>
                    <form action="#">
                      <div className="switchAccount" style={{ marginBottom: "20px" }}>
                        <input
                          id={"tier"}
                          type="checkbox"
                          defaultChecked={false}
                          onChange={toggleSwitchTier}
                          className={`switchAccount-input `}
                          checked={isTierSwitcher}
                          required
                        />
                        <label htmlFor={"tier"} className={`switchAccount__label labelAccount_red`}>
                        </label>
                      </div>

                      {isTierSwitcher ?
                        <div style={{ display: "flex" }}>
                          {tiers.map((tier, index) => (
                            <div>
                              <label className="newPayment-entry__top-input-label newPayment-entry__label">{tier.name}</label><br />
                              <div className="switchAccount" style={{ marginBottom: "20px" }}>
                                <input
                                  id={tier.name}
                                  type="checkbox"
                                  defaultChecked={currentTiers[index] == 1 ? true : false}
                                  onChange={(event) => {toggleSwitchTierSelect(event, index)}}
                                  className={`switchAccount-input `}
                                  checked={currentTiers[index] == 1 ? true : false}
                                />
                                <label htmlFor={tier.name} className={`switchAccount__label labelAccount_red`}>
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                        : null
                      }

                    </form>
                  </div>
                </div>





                <div className="newPayment-entry__form-footerContainer">
                  {errorMessage && (
                    <div className="newPayment-entry__form-footerContainer-errorMessage">{errorMessage}</div>
                  )}
                  <div className="newPayment-entry__form-footerContainer-buttons">
                    <div onClick={() => resetInputs()} className="newPayment-entry__form-button newPayment-entry__form-reset">
                      <span>RESET</span>
                    </div>
                    <button
                      type="button"
                      ref={submitButton}
                      onClick={handleFormSubmit}
                      // disabled={!(amountValidationCheck && utrRefValidationCheck)}
                      className={`${amountValidationCheck && utrRefValidationCheck ? "unlock-button" : ""
                        } newPayment-entry__form-button newPayment-entry__form-save `}
                    >
                      <span>Save</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
