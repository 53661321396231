import "./index.scss"
import { useRef, useState } from "react"
import axios from "axios"
import { HOST, useAuth } from "../../Providers/AuthProvider"
import MdClose from 'mdi-react/CloseBoldIcon'
import { toastr } from "react-redux-toastr"
import { useEffect } from "react"


export const AddingAccountsWithdrawalsPage = ({ report, editingType, setEditing }) => {
  const submitButton = useRef(null)
  let [amountValidationCheck, setAmountValidationCheck] = useState(false)
  let [isButtonDisable, setIsButtonDisable] = useState(true)



  let [inputs, setInputs] = useState({
    id: report?.id || "",
    nameState: report?.name || "",
    promo: report?.promo || "",
    index: report?.index || "",
    acNo: report?.acNo || "",
    IFSC: report?.IFSC || "",
    bankName: report?.bankName || "",
    name: report?.name || "",
    isActive: report?.isActive || false,
  })

  let [errorMessage, setErrorMessage] = useState()
  let [isEmpty, setIsEmpty] = useState({
    nameState: false,
    min: false,
    max: false,
    index: false,
    acNo: false,
    IFSC: false,
    bankName: false,
    accName: false
  })
 

  const resetInputs = () => {
    setInputs({ name: "", acNo: "", IFSC: "", bankName: "", isActive: false })
    setIsButtonDisable(true)
  }

  const inputsValidation = () => {
    const newObj = {
      nameState: false,
      min: false,
      max: false,
      index: false,
      acNo: false,
      IFSC: false,
      bankName: false,
      accName: false
    }
    Object.keys(inputs).map(item => {
      if (inputs[item] == "") {
        newObj[item] = true
      }
    })
    setIsEmpty(newObj)
  }

  const handleFormSubmit = async e => {
    if (
      !inputs.name ||
      !inputs.acNo ||
      !inputs.IFSC ||
      !inputs.bankName ||
      typeof inputs.isActive !== 'boolean'
    ) {
      inputsValidation()
      setErrorMessage("Please fill all required fields!")
      setTimeout(() => {
        setErrorMessage("")
      }, 5000)
      return
    }
    e.preventDefault()
    const token = sessionStorage.getItem("accessToken")

    try {
console.log({
  id: editingType === "Add" ? undefined : inputs.id,
  IFSC: inputs.IFSC,
  acNo: inputs.acNo,
  bankName: inputs.bankName,
  name: inputs.name,
  isActive: inputs.isActive
});
      const response = await (editingType === "Add" ? axios.post : axios.put)(
        HOST + "/Banking/withdraw/payment-gateways",
        {
          id: editingType === "Add" ? undefined : inputs.id,
          IFSC: inputs.IFSC,
          acNo: inputs.acNo,
          bankName: inputs.bankName,
          name: inputs.name,
          isActive: inputs.isActive
        },
        { headers: { Authorization: `${token}` } }
      )

      if (response.data.success) {
        toastr.success('Account added successfully')
        setEditing(false)
      } else {
        toastr.failure(response?.data?.result?.status?.statusDesc)
      }
    } catch (error) {
      console.log("ERROR", error)
      toastr.error("Something went wrong, please try again")
    }
  }

  const checkIFSC = async (code)=>{
    try {
      const response = await axios.get(
        "https://ifsc.razorpay.com/" + code
      );
      if(response?.data){
        setInputs({ ...inputs, ["bankName"]: response?.data?.BANK,  ["IFSC"]: response?.data?.IFSC });
        setIsButtonDisable(false);
        return;
      }
      setIsButtonDisable(true);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(()=>{
    checkIFSC(report?.IFSC);
  },[])
  return (
    <div className="newPayment-entry__main">
      <h1>{editingType === "Add" ? "New Withdrawal Account" : "Edit Withdrawal Account"}</h1>
      <div className="newPayment-entry">
        <div className="newPayment-entry__form-wrapper">
          <form action="#">
            <div className="newPayment-entry__form">
              <div className="newPayment-entry__form-rightSide">
                <div className="newPayment-entry__form-title">Account Info <div className="pull-right" onClick={()=>{setEditing(false)}}><MdClose /></div></div>
              <div className="form-display-inline-block">
               <div className="float-left" style={{margin:"10px"}}>
                <div className="newPayment-entry__form-input-wrapper">
                  <label className="newPayment-entry__top-input-label newPayment-entry__label">AcNo</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name={"AcNo-ref"}
                    value={inputs.acNo}
                    onChange={e => {
                      setInputs({ ...inputs, ["acNo"]: e.target.value })
                      e.target.value && setIsEmpty({ ...isEmpty, ["acNo"]: false })
                    }}
                    required
                    className={`newPayment-entry__form-input ${isEmpty.acNo && "error"}`}
                    placeholder="AcNo"
                  />
                </div>
                 <div className="newPayment-entry__form-input-wrapper">
                   <label className="newPayment-entry__top-input-label newPayment-entry__label">IFSC</label>
                   <input
                     maxLength={11}
                     autoComplete="off"
                     type="text"
                     name={"IFSC-ref"}
                     value={inputs.IFSC}
                     onChange={e => {
                       console.log(e.target.value);
                       setIsButtonDisable(true);
                       setInputs({ ...inputs, ["IFSC"]: e.target.value })
                       e.target.value && setIsEmpty({ ...isEmpty, ["IFSC"]: false })
                       if(e.target.value.length === 11){
                         checkIFSC(e.target.value);}
                     }}
                     required
                     className={`newPayment-entry__form-input ${isEmpty.IFSC && "error"}`}
                     placeholder="IFSC"
                   />
                 </div>
                 <div className="newPayment-entry__top-input" >
                   <div className="newPayment-entry__top-input-item float-left" >
                     <label className="newPayment-entry__top-input-label newPayment-entry__label">isActive</label>
                     <form action="#">
                       <div className="switchAccount" style={{ marginBottom: "20px" }}>
                         <input
                           id={"Active"}
                           type="checkbox"
                           onChange={e =>{
                             setInputs({ ...inputs, ["isActive"]: !inputs.isActive })
                           }}
                           defaultChecked={false}
                           className={`switchAccount-input `}
                           checked={inputs.isActive}
                           required
                         />
                         <label htmlFor={"Active"} className={`switchAccount__label labelAccount_red`}>
                         </label>
                       </div>
                     </form>
                   </div>
                 </div>
               </div>
                <div className="float-right" style={{margin:"10px"}}>
                  <div className="newPayment-entry__form-input-wrapper">
                    <label className="newPayment-entry__top-input-label newPayment-entry__label">Name</label>
                    <input
                      autoComplete="off"
                      type="text"
                      name={"Name-ref"}
                      value={inputs.name}
                      onChange={e => {
                        setInputs({ ...inputs, ["name"]: e.target.value })
                        e.target.value && setIsEmpty({ ...isEmpty, ["accName"]: false })
                      }}
                      className={`newPayment-entry__form-input ${isEmpty.accName && "error"}`}
                      placeholder="Name"
                      required
                    />
                  </div>

                <div className="newPayment-entry__form-input-wrapper">
                  <label className="newPayment-entry__top-input-label newPayment-entry__label">Bank Name</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name={"BankName-ref"}
                    value={inputs.bankName}
                    onChange={e => {
                      setInputs({ ...inputs, ["bankName"]: e.target.value })
                      e.target.value && setIsEmpty({ ...isEmpty, ["bankName"]: false })
                    }}
                    className={`newPayment-entry__form-input ${isEmpty.bankName && "error"}`}
                    placeholder="Bank Name"
                    required
                  />
                </div>
                  <div className="newPayment-entry__form-footerContainer">
                    {errorMessage && (
                      <div className="newPayment-entry__form-footerContainer-errorMessage">{errorMessage}</div>
                    )}
                    <label className="newPayment-entry__top-input-label newPayment-entry__label"></label>

                    <div className="newPayment-entry__form-footerContainer-buttons">
                      <div onClick={() => resetInputs()} className="newPayment-entry__form-button newPayment-entry__form-reset">
                        <span>RESET</span>
                      </div>
                      <button
                        disabled={isButtonDisable}
                        type="button"
                        ref={submitButton}
                        onClick={handleFormSubmit}
                        // disabled={!(amountValidationCheck && utrRefValidationCheck)}
                        className={`${
                          amountValidationCheck && isButtonDisable ? "unlock-button" : ""
                        } newPayment-entry__form-button newPayment-entry__form-save `}
                      >
                        <span>Save</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
                {/* <label className="newPayment-entry__top-input-label newPayment-entry__label">Logo</label> */}

                {/* {nnn && <FileUploader handleChange={handleChange} name="file" types={fileTypes} classes="lll" />} */}
                {/* <input type="select">
                  <option value="fff"></option>
                  <option value="aaaf"></option>

                </input> */}


              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
