import React from 'react'
import { ReactBurgerMenu } from 'react-burger-menu'
import './index.scss'

export default React.forwardRef(({
  children,
  color = 'purple',
  disabled,
  onClick,
  className = "",
  width,
  height = "32px",
  id,
  loading = false
}, ref) => {

  const spinnerStyle = {
    height: "20px",
    width: "20px",
    border: "2px solid #ffffff",
    borderRadius: "50%",
    borderRightColor: "transparent",
    display: "block",
    animation: "loader-rotate 1s linear infinite"
  }

  return (
    <button
      style={{ width, height, position: 'relative' }}
      className={`custom-buttom ${color} ${className}`}
      disabled={disabled} onClick={loading ? null : onClick} ref={ref} id={id}>
      {
        loading ? 
        <span style={spinnerStyle}></span> :
        <span>{children}</span>
      }
    </button>
  )
})
