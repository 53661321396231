import './index.scss'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import { useHistory } from 'react-router-dom';

export default ({ uplineData, url="/agency-management/downline" }) => {
  const history = useHistory();
  return (
    <div className="downline-nav">
      { uplineData.length ?
        <>
          <a className="no-href" url={url} onClick={(e) => history.push('/agency-management/downline')} >Downline Listing</a>
          {uplineData.map(({ loginName, username }) => {
            url = url + "/" + username;
            return (
              <div key={username}>
                <span><ChevronRightIcon size={17} /></span>
                {loginName.toString() === uplineData.slice(-1)[0].loginName ?
                  <i>{loginName}</i> :
                  <a
                    className="no-href"
                    url={url}
                    onClick={(e) => history.push((e.target.attributes["url"].value))}
                  >{loginName}</a>}
              </div>)
          })}
        </> :
        <i>Downline Listing</i>
      }
    </div>
  )
}