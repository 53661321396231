import "./index.scss";
import { useEffect, useState } from "react";
import ButtonCustom from "../../components/ButtonCustom";
import DateTimeRangePicker from "../../components/DateTimeRangePicker";
import DropdownStatus from "../../components/DropdownStatus";
import PaginationCustom from "../../components/PaginationCustom";
// import SearchCustom from "../../components/SearchCustom";
import TextInputCustom from "../../components/TextInputCustom";
import moment from "moment";
import { useAuth } from "../../Providers/AuthProvider";
import { useLocation } from "react-router-dom";
import { CancelPopup } from "../../components/CancelPopup";
import ApproveWithdrawalPopUp from "../../components/ApproveWithdrawalPopUp"
import DepositPopUp from "../../components/DepositPopUp"
import sound from "../../static/sound/26055615_alert_sound.mp3";
// import { MdRemoveRedEye } from 'react-icons/md';
import EyeIcon from 'mdi-react/EyeIcon';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { downloadCsv } from "../../utils/createCsv";

export default () => {

  let now = new Date();
  // const  = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  // const endOfToday = moment(new Date()).add(12, "hour").utc();
  const endOfToday = moment().endOf('day');
  console.log(moment().endOf('day').toString());
  // console.log(moment.now().toString());
  // const startOfToday = moment(new Date()).subtract(12, "hour").utc();
  const startOfToday = moment().startOf('day');

  const type = useLocation().pathname.includes("deposit") ? "D" : "W";
  const [newOptions, setNewOptions] = useState({
    utrInput: "",
    amountInput: "",
    status: "",
    userId: "",
    idInput: "",
    gatewayId: "",
    userName: "",
    managerId: "",
    date: null
  });

  const { requestsToApi } = useAuth();
  const [reportsData, setReportsData] = useState([]);
  const [dateForPagination, setDateForPagination] = useState(new Date());
  const [firstDay, setFirstDay] = useState(new Date());
  const [activePage, setActivePage] = useState(1);
  const [depositPopUpData, setDepositPopUpData] = useState(null);
  const [lastSort, setLastSort] = useState({ createdAt: true });
  const [sortedReports, setSortedReports] = useState([]);
  const [paginationReports, setPaginationReports] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [updatedData, setUpdatedData] = useState(false); 
 





  /// timer request states
  const [autoRefreshState, setAutoRefreshState] = useState(false);
  const [autoRefreshTimer, setAutoRefreshTimer] = useState(0);
  const [timerData, setTimerData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const [audio] = useState(new Audio(sound));
  /// end timer request states

  const urlParams =
    "?" +
    "status=" + (newOptions.status ? newOptions.status[0] + newOptions.status.slice(1).toLowerCase() : "") +
    "&type=" + type +
    "&userId=" + newOptions.userId.toString() +
    "&amount=" + newOptions.amountInput.toString() +
    "&UTR=" + newOptions.utrInput.toString() +
    "&requestId=" + newOptions.idInput.toString() +
    "&gatewayId=" + newOptions.gatewayId.toString() +
    "&loginName=" + newOptions.userName.toString() +
    "&filterManagerId=" + newOptions.managerId.toString() +
    "&from=" + (!newOptions?.date?.start ? startOfToday : newOptions?.date?.start) +
    "&to=" + (!newOptions?.date?.end ? endOfToday : newOptions?.date?.end)

  const changeValue = (value, filterTitle) => {
    setNewOptions({ ...newOptions, [filterTitle]: value });
  };

  const showAttachment = (report) => {
    setAttachmentUrl(report?.attachment);
    setModalData(report);
    console.log('report', report)
    setShowModal(true);
  }
  const handleClickClear = () => {
    setNewOptions({ utrInput: "", amountInput: "", status: "", userId: "", idInput: "", gatewayId: "", userName: "", managerId: "", date: null });
    setReportsData([]);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setAttachmentUrl('');
    setModalData(null);
  }

  const handleClickApply = async () => {

    // TODO: CHECK IT !!!!!!!!!!!!!!!!!!
    setSortedReports(null)
    try {
      const response = await requestsToApi.get("/banking/dw-report" + urlParams);
      // setSortedReports(response.data.result.sort((a, b) => {if (a.createdAt > b.createdAt) return -1;}));
      setReportsData(response.data.result ?? []);
    } catch (error) {
      console.log(error?.message);
    }
  };

  useEffect(() => {
    handleClickClear();
  }, [type]);


  const showDepositPopUpPopup = (report) => {
    setDepositPopUpData(report);
  };
  const handleClosePopUp = (isSuccess = false) => {
    if (isSuccess) {
      setSortedReports(sortedReports.filter(item => item.requestId !== depositPopUpData.requestId))
      // handleClickApply()
    }
    setDepositPopUpData(null);
  };
  const changeSort = (key) => {
    setLastSort(lastSort[key] ? { [key]: !lastSort[key] } : { [key]: true })
  };

  useEffect(() => {
    let dataForSort = (autoRefreshState && timerData) ? [...timerData] : [...reportsData]

    if ((dataForSort && dataForSort.length >= 2) || (autoRefreshState && dataForSort)) {
      const key = Object.keys(lastSort)[0];

      if (key === "amount") {
        !lastSort[key] ? setSortedReports([...dataForSort?.sort((a, b) => a[key] - b[key])]) : setSortedReports([...dataForSort?.sort((a, b) => b[key] - a[key])]);
      }
      if (key !== "amount") {
        const filter = dataForSort.filter(item => item[key]);
        const sort = filter.sort((a, b) => {
          if (a[key] > b[key]) return -1;
        });
        setSortedReports(!lastSort[key] ? [...sort.reverse(), ...dataForSort.filter(item => !item[key])] : [...sort, ...dataForSort.filter(item => !item[key])]);
      }
    } else {
      setSortedReports(reportsData);
    }
  }, [lastSort, reportsData, timerData]);

  useEffect(() => {
    console.log(updatedData, 'updatedData')
    if(updatedData){
      handleClickApply()
      setUpdatedData(false)
    }
  }, [updatedData])

  const getDataForCSV = () => {
    let data = reportsData.map(report => ({
      "Requested ID": report?.requestId,
      "Username": report?.loginName,
      "Amount": report?.amount,
      "UTR_NO": report?.UTR,
      "Status": report?.status,
      "Bank manager": report?.manager,
      "Gateway": report.gateway ? `${report.gateway?.name} ${report.gateway?.acNo} ${report.gateway?.bankName}` : '',
      "IP": report?.IP,
      "CreatedAt": `${moment(report?.createdAt).format("DD.MM.YY")} ${moment(report?.createdAt).format("LTS")}`,
      "CompletedAt": `${moment(report?.updatedAt).format("DD.MM.YY")} ${moment(report?.updatedAt).format("LTS")}`,
    }))
    console.log('data', data)
    return { data: data }
  }

  // PLAY AUDIO
  const startAudio = () => {
    audio.play()
  }
  //END PLAY AUDIO

  // TIMER REQUEST
  // let timerRequest = async () => {
  //     //TODO: CHECK !!!!!!!!!!!!!!!!!!!!!!!!!!
  //     setSortedReports(null)
  //     // setReportsData([]);

  // }

  ///////////////////////////////////
  ///////////////////////////////////
  ///////////////////////////////////


  // //////// push dummy items
  // let [tempData, setTempdata] = useState(  [
  //     {
  //         "requestId": 2203242143519030,
  //         "status": "Batch_Created",
  //         "type": "Withdrawal",
  //         "amount": 100,
  //         "userId": 564426,
  //         "loginName": "91_1262464435",
  //         "memberCode": "M2010101M0N",
  //         "account": {
  //             "accountNo": "121",
  //             "accountHolderName": "Acc",
  //             "branchName": "AIRPORT ROAD",
  //             "IFSC": "sbin0020852",
  //             "bankName": "State Bank of India"
  //         },
  //         "UTR": null,
  //         "remarks": null,
  //         "createdAt": "2022-03-24T21:43:51.903",
  //         "updatedAt": "2022-03-24T21:54:15.031",
  //         "manager": " ",
  //         "batchId": 220325025,
  //         "IP": "5.194.197.201",
  //         "gateway": null
  //     },
  //     {
  //         "requestId": 2203242345519740,
  //         "status": "Complete",
  //         "type": "Withdrawal",
  //         "amount": 121,
  //         "userId": 564426,
  //         "loginName": "91_1262464435",
  //         "memberCode": "M2010101M0N",
  //         "account": {
  //             "accountNo": "121",
  //             "accountHolderName": "Acc",
  //             "branchName": "AIRPORT ROAD",
  //             "IFSC": "sbin0020852",
  //             "bankName": "State Bank of India"
  //         },
  //         "UTR": null,
  //         "remarks": null,
  //         "createdAt": "2022-03-24T23:45:51.974",
  //         "updatedAt": "2022-03-25T16:21:28.615",
  //         "manager": " ",
  //         "batchId": 220325027,
  //         "IP": "5.194.197.201",
  //         "gateway": null
  //     },
  //     {
  //         "requestId": 2203241904123720,
  //         "status": "Complete",
  //         "type": "Withdrawal",
  //         "amount": 100,
  //         "userId": 564426,
  //         "loginName": "91_1262464435",
  //         "memberCode": "M2010101M0N",
  //         "account": {
  //             "accountNo": "121",
  //             "accountHolderName": "Acc",
  //             "branchName": "AIRPORT ROAD",
  //             "IFSC": "sbin0020852",
  //             "bankName": "State Bank of India"
  //         },
  //         "UTR": null,
  //         "remarks": null,
  //         "createdAt": "2022-03-24T19:04:12.372",
  //         "updatedAt": "2022-03-24T19:51:37.986",
  //         "manager": " ",
  //         "batchId": 220325024,
  //         "IP": "5.194.197.201",
  //         "gateway": null
  //     },
  //     {
  //         "requestId": 2203250014154130,
  //         "status": "Batch_Created",
  //         "type": "Withdrawal",
  //         "amount": 23000,
  //         "userId": 564426,
  //         "loginName": "91_1262464435",
  //         "memberCode": "M2010101M0N",
  //         "account": {
  //             "accountNo": "121",
  //             "accountHolderName": "Acc",
  //             "branchName": "AIRPORT ROAD",
  //             "IFSC": "sbin0020852",
  //             "bankName": "State Bank of India"
  //         },
  //         "UTR": null,
  //         "remarks": null,
  //         "createdAt": "2022-03-25T00:14:15.413",
  //         "updatedAt": "2022-03-25T17:47:44.932",
  //         "manager": " ",
  //         "batchId": 220325029,
  //         "IP": "5.194.197.201",
  //         "gateway": null
  //     },
  //     {
  //         "requestId": 2203251623582310,
  //         "status": "Batch_Created",
  //         "type": "Withdrawal",
  //         "amount": 100,
  //         "userId": 2214,
  //         "loginName": "inrp1",
  //         "memberCode": "IS010101M01",
  //         "account": {
  //             "accountNo": "550011224455",
  //             "accountHolderName": "Pramod Sinha",
  //             "branchName": "ZEMABAWK",
  //             "IFSC": "sbin0017400",
  //             "bankName": "State Bank of India"
  //         },
  //         "UTR": null,
  //         "remarks": null,
  //         "createdAt": "2022-03-25T16:23:58.231",
  //         "updatedAt": "2022-03-25T17:52:14.987",
  //         "manager": " ",
  //         "batchId": 220325030,
  //         "IP": null,
  //         "gateway": null
  //     },
  //     {
  //         "requestId": 2203251623590370,
  //         "status": "Complete",
  //         "type": "Withdrawal",
  //         "amount": 100,
  //         "userId": 2214,
  //         "loginName": "inrp1",
  //         "memberCode": "IS010101M01",
  //         "account": {
  //             "accountNo": "550011224455",
  //             "accountHolderName": "Pramod Sinha",
  //             "branchName": "ZEMABAWK",
  //             "IFSC": "sbin0017400",
  //             "bankName": "State Bank of India"
  //         },
  //         "UTR": null,
  //         "remarks": null,
  //         "createdAt": "2022-03-25T16:23:59.037",
  //         "updatedAt": "2022-03-25T20:42:26.638",
  //         "manager": " ",
  //         "batchId": 220325049,
  //         "IP": null,
  //         "gateway": null
  //     },
  //     {
  //         "requestId": 2203251650089820,
  //         "status": "Batch_Created",
  //         "type": "Withdrawal",
  //         "amount": 100,
  //         "userId": 2214,
  //         "loginName": "inrp1",
  //         "memberCode": "IS010101M01",
  //         "account": {
  //             "accountNo": "550011224455",
  //             "accountHolderName": "Pramod Sinha",
  //             "branchName": "ZEMABAWK",
  //             "IFSC": "sbin0017400",
  //             "bankName": "State Bank of India"
  //         },
  //         "UTR": null,
  //         "remarks": null,
  //         "createdAt": "2022-03-25T16:50:08.982",
  //         "updatedAt": "2022-03-25T18:13:13.582",
  //         "manager": " ",
  //         "batchId": 220325033,
  //         "IP": null,
  //         "gateway": null
  //     },
  //     {
  //         "requestId": 2203241422098860,
  //         "status": "Complete",
  //         "type": "Withdrawal",
  //         "amount": 100,
  //         "userId": 563355,
  //         "loginName": "91_1243564534",
  //         "memberCode": "M2010101M05",
  //         "account": {
  //             "accountNo": "0020252521234",
  //             "accountHolderName": "PAHILA client",
  //             "branchName": "ZEMABAWK",
  //             "IFSC": "sbin0017400",
  //             "bankName": "State Bank of India"
  //         },
  //         "UTR": null,
  //         "remarks": null,
  //         "createdAt": "2022-03-24T14:22:09.886",
  //         "updatedAt": "2022-03-24T14:23:57.546",
  //         "manager": " ",
  //         "batchId": 220324001,
  //         "IP": "5.194.197.201",
  //         "gateway": null
  //     },
  //     {
  //         "requestId": 2203241719141580,
  //         "status": "Batch_Created",
  //         "type": "Withdrawal",
  //         "amount": 111,
  //         "userId": 564426,
  //         "loginName": "91_1262464435",
  //         "memberCode": "M2010101M0N",
  //         "account": {
  //             "accountNo": "121",
  //             "accountHolderName": "Acc",
  //             "branchName": "ATTIBELE",
  //             "IFSC": "sbin0021615",
  //             "bankName": "State Bank of India"
  //         },
  //         "UTR": null,
  //         "remarks": null,
  //         "createdAt": "2022-03-24T17:19:14.158",
  //         "updatedAt": "2022-03-24T19:51:37.992",
  //         "manager": " ",
  //         "batchId": 220325025,
  //         "IP": "5.194.197.201",
  //         "gateway": null
  //     },
  //     {
  //         "requestId": 2203241720337000,
  //         "status": "Cancelled",
  //         "type": "Withdrawal",
  //         "amount": 121,
  //         "userId": 564426,
  //         "loginName": "91_1262464435",
  //         "memberCode": "M2010101M0N",
  //         "account": {
  //             "accountNo": "121",
  //             "accountHolderName": "Acc",
  //             "branchName": "ATTIBELE",
  //             "IFSC": "sbin0021615",
  //             "bankName": "State Bank of India"
  //         },
  //         "UTR": null,
  //         "remarks": "efes",
  //         "createdAt": "2022-03-24T17:20:33.7",
  //         "updatedAt": null,
  //         "manager": " ",
  //         "batchId": null,
  //         "IP": "5.194.197.201",
  //         "gateway": null
  //     },
  //     {
  //         "requestId": 2203251610190270,
  //         "status": "Complete",
  //         "type": "Withdrawal",
  //         "amount": 1000,
  //         "userId": 2214,
  //         "loginName": "inrp1",
  //         "memberCode": "IS010101M01",
  //         "account": {
  //             "accountNo": "550011224455",
  //             "accountHolderName": "Pramod Sinha",
  //             "branchName": "ZEMABAWK",
  //             "IFSC": "sbin0017400",
  //             "bankName": "State Bank of India"
  //         },
  //         "UTR": null,
  //         "remarks": null,
  //         "createdAt": "2022-03-25T16:10:19.027",
  //         "updatedAt": "2022-03-25T16:11:28.513",
  //         "manager": " ",
  //         "batchId": 220325026,
  //         "IP": null,
  //         "gateway": null
  //     },
  //     {
  //         "requestId": 2203251623572870,
  //         "status": "Batch_Created",
  //         "type": "Withdrawal",
  //         "amount": 100,
  //         "userId": 2214,
  //         "loginName": "inrp1",
  //         "memberCode": "IS010101M01",
  //         "account": {
  //             "accountNo": "550011224455",
  //             "accountHolderName": "Pramod Sinha",
  //             "branchName": "ZEMABAWK",
  //             "IFSC": "sbin0017400",
  //             "bankName": "State Bank of India"
  //         },
  //         "UTR": null,
  //         "remarks": null,
  //         "createdAt": "2022-03-25T16:23:57.287",
  //         "updatedAt": "2022-03-25T18:11:43.564",
  //         "manager": " ",
  //         "batchId": 220325032,
  //         "IP": null,
  //         "gateway": null
  //     },
  //
  //     {
  //         "lol": "lol1",
  //         "requestId": 2203251610190297,
  //         "status": "Complete",
  //         "type": "Withdrawal",
  //         "amount": 1000,
  //         "userId": 2214,
  //         "loginName": "inrp1",
  //         "memberCode": "IS010101M01",
  //         "account": {
  //             "accountNo": "550011224455",
  //             "accountHolderName": "Pramod Sinha",
  //             "branchName": "ZEMABAWK",
  //             "IFSC": "sbin0017400",
  //             "bankName": "State Bank of India"
  //         },
  //         "UTR": null,
  //         "remarks": null,
  //         "createdAt": "2022-09-08T07:09:19.027",
  //         "updatedAt": "2022-09-08T07:10:28.513",
  //         "manager": " ",
  //         "batchId": 220325026,
  //         "IP": null,
  //         "gateway": null
  //     },
  //     {
  //         "lol": "lol2",
  //         "requestId": 2203251623572777,
  //         "status": "Batch_Created",
  //         "type": "Withdrawal",
  //         "amount": 100,
  //         "userId": 2214,
  //         "loginName": "inrp1",
  //         "memberCode": "IS010101M01",
  //         "account": {
  //             "accountNo": "550011224455",
  //             "accountHolderName": "Pramod Sinha",
  //             "branchName": "ZEMABAWK",
  //             "IFSC": "sbin0017400",
  //             "bankName": "State Bank of India"
  //         },
  //         "UTR": null,
  //         "remarks": null,
  //         "createdAt": "2022-09-08T06:08:30.287",
  //         "updatedAt": "2022-09-08T06:08:35.564",
  //         "manager": " ",
  //         "batchId": 220325032,
  //         "IP": null,
  //         "gateway": null
  //     },
  //
  // ])
  //
  // useEffect(() => {
  //     let lol = setTimeout(()=> {
  //         console.log('___data pushed___');
  //         setTempdata([...tempData,
  //             {
  //                 "lol": "lol3",
  //                 "requestId": 22032516235724449119,
  //                 "status": "Batch_Created",
  //                 "type": "Withdrawal",
  //                 "amount": 100,
  //                 "userId": 2214,
  //                 "loginName": "inrp1",
  //                 "memberCode": "IS010101M01",
  //                 "account": {
  //                     "accountNo": "550011224455",
  //                     "accountHolderName": "Lorem Ipsum1",
  //                     "branchName": "ZEMABAWK",
  //                     "IFSC": "sbin0017400",
  //                     "bankName": "State Bank of India"
  //                 },
  //                 "UTR": null,
  //                 "remarks": null,
  //                 "createdAt": "2022-08-11T04:08:30.287",
  //                 "updatedAt": "2022-08-11T05:08:35.564",
  //                 "manager": " ",
  //                 "batchId": 220325032,
  //                 "IP": null,
  //                 "gateway": null
  //             }
  //         ])
  //         return () => clearTimeout(lol)
  //     } , 15000 )
  //     return () => clearTimeout(lol)
  // },[])
  // useEffect(() => {
  //     let lol2 = setTimeout(()=> {
  //         console.log('___data pushed_2___');
  //         setTempdata([...tempData,
  //             {
  //                 "lol": "lol3",
  //                 "requestId": 22032516235724449119,
  //                 "status": "Batch_Created",
  //                 "type": "Withdrawal",
  //                 "amount": 100,
  //                 "userId": 2214,
  //                 "loginName": "inrp1",
  //                 "memberCode": "IS010101M01",
  //                 "account": {
  //                     "accountNo": "550011224455",
  //                     "accountHolderName": "Lorem Ipsum2",
  //                     "branchName": "ZEMABAWK",
  //                     "IFSC": "sbin0017400",
  //                     "bankName": "State Bank of India"
  //                 },
  //                 "UTR": null,
  //                 "remarks": null,
  //                 "createdAt": "2022-08-11T04:08:30.287",
  //                 "updatedAt": "2022-08-11T05:08:35.564",
  //                 "manager": " ",
  //                 "batchId": 220325032,
  //                 "IP": null,
  //                 "gateway": null
  //             },
  //             {
  //                 "lol": "lol4",
  //                 "requestId": 2203251623572444911977,
  //                 "status": "Batch_Created",
  //                 "type": "Withdrawal",
  //                 "amount": 100,
  //                 "userId": 2214,
  //                 "loginName": "inrp1",
  //                 "memberCode": "IS010101M01",
  //                 "account": {
  //                     "accountNo": "550011224455",
  //                     "accountHolderName": "Lorem Ipsum3",
  //                     "branchName": "ZEMABAWK",
  //                     "IFSC": "sbin0017400",
  //                     "bankName": "State Bank of India"
  //                 },
  //                 "UTR": null,
  //                 "remarks": null,
  //                 "createdAt": "2022-08-11T11:08:30.287",
  //                 "updatedAt": "2022-08-11T12:09:35.564",
  //                 "manager": " ",
  //                 "batchId": 220325032,
  //                 "IP": null,
  //                 "gateway": null
  //             }])
  //         return () =>  clearTimeout(lol2)
  //     } , 25000 )
  //     return () =>  clearTimeout(lol2)
  // },[])
  // useEffect(() => {
  //     let lol3 = setTimeout(()=> {
  //         console.log('___data pushed_3___');
  //         setTempdata([...tempData,
  //             {
  //                 "lol": "lol3",
  //                 "requestId": 22032516235724449119,
  //                 "status": "Batch_Created",
  //                 "type": "Withdrawal",
  //                 "amount": 100,
  //                 "userId": 2214,
  //                 "loginName": "inrp1",
  //                 "memberCode": "IS010101M01",
  //                 "account": {
  //                     "accountNo": "550011224455",
  //                     "accountHolderName": "Lol Rofl",
  //                     "branchName": "ZEMABAWK",
  //                     "IFSC": "sbin0017400",
  //                     "bankName": "State Bank of India"
  //                 },
  //                 "UTR": null,
  //                 "remarks": null,
  //                 "createdAt": "2022-08-11T04:08:30.287",
  //                 "updatedAt": "2022-08-11T05:08:35.564",
  //                 "manager": " ",
  //                 "batchId": 220325032,
  //                 "IP": null,
  //                 "gateway": null
  //             },
  //             {
  //                 "lol": "lol4",
  //                 "requestId": 2203251623572444911977,
  //                 "status": "Batch_Created",
  //                 "type": "Withdrawal",
  //                 "amount": 100,
  //                 "userId": 2214,
  //                 "loginName": "inrp1",
  //                 "memberCode": "IS010101M01",
  //                 "account": {
  //                     "accountNo": "550011224455",
  //                     "accountHolderName": "Lols Roflmao",
  //                     "branchName": "ZEMABAWK",
  //                     "IFSC": "sbin0017400",
  //                     "bankName": "State Bank of India"
  //                 },
  //                 "UTR": null,
  //                 "remarks": null,
  //                 "createdAt": "2022-08-11T11:08:30.287",
  //                 "updatedAt": "2022-08-11T12:09:35.564",
  //                 "manager": " ",
  //                 "batchId": 220325032,
  //                 "IP": null,
  //                 "gateway": null
  //             },
  //             {
  //                 "lol": "lol5",
  //                 "requestId": 2203251623572444911977,
  //                 "status": "Batch_Created",
  //                 "type": "Withdrawal",
  //                 "amount": 100,
  //                 "userId": 2214,
  //                 "loginName": "inrp1",
  //                 "memberCode": "IS010101M01",
  //                 "account": {
  //                     "accountNo": "550011224455",
  //                     "accountHolderName": "Lorem Ipsum",
  //                     "branchName": "ZEMABAWK",
  //                     "IFSC": "sbin0017400",
  //                     "bankName": "State Bank of India"
  //                 },
  //                 "UTR": null,
  //                 "remarks": null,
  //                 "createdAt": "2022-08-11T12:01:30.287",
  //                 "updatedAt": "2022-08-11T12:02:35.564",
  //                 "manager": " ",
  //                 "batchId": 220325032,
  //                 "IP": null,
  //                 "gateway": null
  //             },
  //         ])
  //         return  () => clearTimeout(lol3)
  //     } , 35000 )
  //     return  () => clearTimeout(lol3)
  // },[])
  //
  // console.log('tempData_!!!', tempData)
  // //////// end push dummy items

  // // PLAY AUDIO
  // const startAudio = () => {
  //     audio.play()
  // }
  // //END PLAY AUDIO

  // TIMER REQUEST
  let timerRequest = async () => {
    setSortedReports(null)

    try {
      //TODO: CHECK IT !!!!!!!!!!!
      let timerResponse = await requestsToApi.get("/banking/dw-report" + urlParams);
      timerResponse = timerResponse.data.result

      let filteredByDateData = timerResponse.filter(item => {
        let todaysDate = new Date()
        let itemNewDate = new Date(item.createdAt)
        if ((itemNewDate.getFullYear() === todaysDate.getFullYear()) &&
          (itemNewDate.getMonth() === todaysDate.getMonth()) &&
          (itemNewDate.getDate() === todaysDate.getDate())) {
          return item
        }
        else return null
      })

      // TODO: check previous data !!!!!!
      if (timerData && filteredByDateData && (filteredByDateData.length !== timerData.length)) {
        setTimerData(filteredByDateData)
        startAudio()
        setLastSort({ "createdAt": true })
      }
      else {
        // TODO: CHECK IT !!!!!!!!!!!
        filteredByDateData.length > 0 ? setTimerData(filteredByDateData) : setTimerData(null);
      }
      setAutoRefreshTimer(intervalTime)
    }
    catch (error) {
      console.log(error?.message);
      setAutoRefreshTimer(intervalTime)
    }
  };
  // END TIMER REQUEST

  //////////////////////////
  // TIMER
  // let interval
  // let intervalTime = 5

  // useEffect(() => {
  //     if(autoRefreshState) {
  //         setAutoRefreshTimer(intervalTime)
  //     }
  //     if(!autoRefreshState) {
  //         setAutoRefreshTimer(0)
  //         return () => clearInterval(interval);
  //     }
  // },[autoRefreshState])
  // useEffect(() => {
  //     if(!autoRefreshState) {
  //         return () => clearInterval(interval);
  //     }
  //     if(autoRefreshTimer === 0) {
  //
  //         // TODO: FIX IT
  //         timerRequest()
  //     }
  //     if(autoRefreshState && autoRefreshTimer !== 0) {
  //         interval = setInterval(() => {
  //             setAutoRefreshTimer(autoRefreshTimer - 1);
  //             console.log('autoRefreshTimer', autoRefreshTimer)
  //         }, 1000);
  //     }
  //     return () => clearInterval(interval);
  // }, [autoRefreshTimer]);
  // END TIMER

  //////////////////////////
  //     try {
  //         //TODO: CHECK IT !!!!!!!!!!!
  //         let timerResponse = requestsToApi.get("/banking/dw-report" + urlParams);
  //         timerResponse = timerResponse.data.result

  //         let filteredByDateData = timerResponse.filter(item => {
  //             let todaysDate = new Date()
  //             let itemNewDate = new Date(item.createdAt)
  //             if ((itemNewDate.getFullYear() === todaysDate.getFullYear()) &&
  //                 (itemNewDate.getMonth() === todaysDate.getMonth())  &&
  //                 (itemNewDate.getDate() === todaysDate.getDate())) {
  //                 return item
  //             }
  //             else return null
  //         })

  //         // TODO: check previous data !!!!!!
  //         if (timerData && filteredByDateData && (filteredByDateData.length !== timerData.length)) {
  //             setTimerData(filteredByDateData)
  //             startAudio()
  //             setLastSort({"createdAt": true})
  //         }
  //         else {
  //             // TODO: CHECK IT !!!!!!!!!!!
  //             filteredByDateData.length > 0 ? setTimerData(filteredByDateData) : setTimerData(null);
  //         }
  //         setAutoRefreshTimer(intervalTime)
  //     }
  //     catch (error) {
  //         console.log(error?.message);
  //         setAutoRefreshTimer(intervalTime)
  //     }
  // };
  // // END TIMER REQUEST

  // TIMER
  let interval
  let intervalTime = 30

  useEffect(() => {
    if (autoRefreshState) {
      setAutoRefreshTimer(intervalTime)

      //TODO: CHECK !!!!!!!!!!!!!
      setLastSort({ "createdAt": true })
    }
    if (!autoRefreshState) {
      setAutoRefreshTimer(0)
      return () => clearInterval(interval);
    }
  }, [autoRefreshState])

  useEffect(() => {
    if (!autoRefreshState) {
      return () => clearInterval(interval);
    }
    if (autoRefreshTimer === 0) {
      timerRequest()
    }
    if (autoRefreshState && autoRefreshTimer !== 0) {
      interval = setInterval(() => {
        setAutoRefreshTimer(autoRefreshTimer - 1);
        // console.log('autoRefreshTimer', autoRefreshTimer)
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [autoRefreshTimer]);
  // END TIMER

  //FIRST RENDER
  // useEffect(() => {
  //     handleClickApply();
  // }, []);
  //END FIRST RENDER

  //PAGINATION
  // const [activePage, setActivePage] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const reportsByPage = 100;
  useEffect(() => {
    if (sortedReports) {
      const reports = [];
      for (let i = 0; i < Math.ceil(sortedReports.length / reportsByPage); i++) {
        reports[i] = sortedReports.slice((i * reportsByPage), (i * reportsByPage) + reportsByPage);
      }
      setAllPages(reports.length || 1)
      setPaginationReports(reports)
    }
  }, [sortedReports]);
  // END PAGINATION

  return (
    <div className="customer-reports">
      {/*{cancelPopupId !== false ? <CancelPopup cancelPopupId={cancelPopupId} setCancelPopup={setCancelPopup}/> : ""}*/}
      {depositPopUpData && <DepositPopUp
        closeFunc={handleClosePopUp}
        data={depositPopUpData}
        isUpdated={setUpdatedData}
      />}
      <div className="customer-reports__header">
        <div className="customer-reports__header_date-rows">
          <div className="customer-reports__header_date-row">
            <h1> Customer {type === "D" ? "Deposits" : "Withdrawals"}</h1>
            <DateTimeRangePicker dateTimeRange={autoRefreshState ? false : newOptions.date} onChangeFunc={(value) => changeValue(value, "date")} />
            <ButtonCustom color="cyan" onClick={() => downloadCsv(getDataForCSV())}>Download CSV</ButtonCustom>
          </div>
          <div className="customer-reports__header_date-unput-row">
            <TextInputCustom className="customer-reports__textInputCustom" onChange={(value) => changeValue(value, "utrInput")} placeholder="UTR">
              {newOptions.utrInput}
            </TextInputCustom>
            <TextInputCustom className="customer-reports__textInputCustom" onChange={(value) => changeValue(value, "amountInput")} placeholder="Amount">
              {newOptions.amountInput}
            </TextInputCustom>
            <div className="customer-reports__header_date-input-row-drop-down-wrapper">
              <DropdownStatus
                options={["Pending", "Complete", "Cancelled"]}
                title={newOptions.status}
                onChange={(value) => changeValue(value, "status")} />
            </div>
            <TextInputCustom className="customer-reports__textInputCustom" onChange={(value) => changeValue(value, "userId")} placeholder="User ID">
              {newOptions.userId}
            </TextInputCustom>
            <TextInputCustom className="customer-reports__textInputCustom" onChange={(value) => changeValue(value, "idInput")} placeholder="Request ID">
              {newOptions.idInput}
            </TextInputCustom>
            <TextInputCustom className="customer-reports__textInputCustom" onChange={(value) => changeValue(value, "gatewayId")} placeholder="Gateway Id">
              {newOptions.gatewayId}
            </TextInputCustom>
            <TextInputCustom className="customer-reports__textInputCustom" onChange={(value) => changeValue(value, "userName")} placeholder="User Name">
              {newOptions.userName}
            </TextInputCustom>
            <TextInputCustom className="customer-reports__textInputCustom" onChange={(value) => changeValue(value, "managerId")} placeholder="Manager ID">
              {newOptions.managerId}
            </TextInputCustom>
          </div>
        </div>
        <div className="customer-reports__header-search-column">
          <div className={`customer-reports__auto-refresh-wrapper`}>
            <p>Auto-Refresh</p>
            <div className={`customer-reports__auto-refresh-button-wrapper`}>
              <span className={`customer-reports__auto-refresh-timer`}>{autoRefreshTimer}</span>
              <div className={`customer-reports__auto-refresh-button ${(autoRefreshState === true) ? "active" : ""}`} onClick={() => setAutoRefreshState(prevState => !prevState)}>
                <span className={`customer-reports__auto-refresh-toggle-icon`}></span>
                <span className={`customer-reports__auto-refresh-on`}>ON</span>
                <span className={`customer-reports__auto-refresh-off`}>OFF</span>
              </div>
            </div>
          </div>
          {/* TODO: CHECK IT - TIMER STATE */}
          <div className="customer-reports__header-search-column-buttons">
            <ButtonCustom onClick={() => { handleClickApply(); setAutoRefreshState(false); }} color="cyan">Apply</ButtonCustom>
            <ButtonCustom onClick={handleClickClear} color="red">Clear</ButtonCustom>
          </div>
          {/*<div className="customer-reports__header-search-column-search-wrapper">*/}
          {/*  <SearchCustom onChange={(value) => console.log(value)} />*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="customer-reports__table">
        <div className="customer-reports__table-row-date">
          {/*<div className="customer-reports__table-date">{moment(dateForPagination).format("DD.MM.YY")}</div>*/}
          <div className="customer-reports__table-paginaton">
            <PaginationCustom
              totalPages={allPages}
              activePage={activePage}
              changeActivePage={page => setActivePage(page)}
            />
            {/*  <PaginationCustom*/}
            {/*  totalPages={(newOptions?.date && newOptions?.date?.start && newOptions?.date?.end) ? newOptions?.date?.end.diff(newOptions?.date?.start, "days") + 1 : 1}*/}
            {/*  activePage={activePage}*/}
            {/*  changeActivePage={page => {*/}
            {/*      setActivePage(page);*/}
            {/*      setDateForPagination(new Date(firstDay.setDate(firstDay.getDate() - (activePage - page))));*/}
            {/*  }}*/}
            {/*/>*/}
          </div>
        </div>

        <ul className={`customer-reports__table-header ${autoRefreshState ? "autoRefreshOn" : ""}`}>
          <li className={`customer-reports__table-header-sort-button`}>Request ID</li>
          <li className={`customer-reports__table-header-sort-button`}>Username</li>
          <li onClick={() => changeSort("amount")} className={`customer-reports__table-header-sort-button`}>
            <span className={`customer-reports__table-header-sort ${lastSort.amount ? "" : "up"}`}>Amount</span>
          </li>
          <li className={`customer-reports__table-header-sort-button`}>UTR_NO</li>
          <li className={`customer-reports__table-header-sort-button`}>Status</li>
          <li className={`customer-reports__table-header-sort-button`}>Remark</li>
          <li className={`customer-reports__table-header-sort-button`}>Bank manager</li>
          <li className={`customer-reports__table-header-sort-button`}>Gateway</li>
          <li className={`customer-reports__table-header-sort-button`}>IP</li>
          <li onClick={() => changeSort("createdAt")} className={`customer-reports__table-header-sort-button customer-reports__createdAt-button`}>
            <span className={`customer-reports__table-header-sort ${lastSort.createdAt ? "" : "up"}`}>CreatedAt</span>
          </li>
          <li onClick={() => changeSort("updatedAt")} className={`customer-reports__table-header-sort-button`}>
            <span className={`customer-reports__table-header-sort ${lastSort.updatedAt ? "" : "up"}`}>CompletedAt</span>
          </li>
          <li className={`customer-reports__table-header-sort-button`}>Action</li>
        </ul>
        <div className="customer-reports__table-body">
          {sortedReports && paginationReports && paginationReports[activePage - 1]?.map((report, index) =>
            <ul key={report.requestId + index}
              className="customer-reports__table-body-row">
              <li>{report?.requestId}</li>
              <li>{report?.loginName}</li>
              <li>{report?.amount}</li>
              <li>{report?.UTR}</li>
              <li>{report?.status}</li>
              <li>{report?.remarks || "-"}</li>
              <li>{report?.manager}</li>
              <li className="customer-reports__table-body-row-date">
                {report.gateway &&
                  <div>
                    <div>{report.gateway?.name}</div>
                    <div>{report.gateway?.acNo}</div>
                    <div>{report.gateway?.bankName}</div>
                  </div>}
              </li>
              <li>{report?.IP}</li>
              {/*<li onClick={() => showDepositPopUpPopup(report)} >{report?.IP}</li>*/}
              {/*<li onClick={() => showCancelPopup(report?.requestId)} className="customer-reports__table-body-row-cancel">X</li>*/}
              <li className="customer-reports__table-body-row-date">
                <div>{moment(report?.createdAt).format("DD.MM.YY")}</div>
                <div className="time">{moment(report?.createdAt).format("LTS")}</div>
              </li>
              <li className="customer-reports__table-body-row-date">
                {report.updatedAt &&
                  <div>
                    <div>{moment(report.updatedAt).format("DD.MM.YY")}</div>
                    <div className="time">{moment(report.updatedAt).format("LTS")}</div>
                  </div>}
              </li>
              <li className={`customer-reports__table-body-row-cancel`}>
                {report?.attachment && (<EyeIcon className="cursor-pointer" color="white" onClick={() => { showAttachment(report) }} />)}
                {report?.status ==='Pending' && (<span className="cancel" onClick={() => showDepositPopUpPopup(report)} color="red" >
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 30 30">
                    <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" fill="white"></path>
                  </svg>
                </span>)}
              </li>
            </ul>,
          )}
        </div>
        <div className="customer-reports__table-row-date">
          <div className="customer-reports__table-paginaton">
            <PaginationCustom
              totalPages={allPages}
              activePage={activePage}
              changeActivePage={page => setActivePage(page)}
            />
          </div>
        </div>
      </div>
      {showModal && (<div className="modal-attachment">
        <div className="pop-up__body">
          <div className="close" onClick={handleModalClose}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 30 30">
              <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" fill="white"></path>
            </svg>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-6" style={{overflowY:"auto"}}>
                <img className="attached-img img-fluid" src={attachmentUrl} alt="attachment" />
              </div>
              <div className="col-6" style={{overflowY:"auto"}}>
                <div className="container">
                  <div className="row">
                    {modalData?.loginName && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>Log In Name</span>
                          <span>: {modalData?.loginName}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.amount && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>Amount</span>
                          <span>: {modalData?.amount}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.userId && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>User Id</span>
                          <span>: {modalData?.userId}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.status && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>Status</span>
                          <span>: <span style={{color:modalData?.status==='Cancelled' ? 'red':modalData?.status==='Pending' ? 'darkgoldenrod':'green'}}>{modalData?.status}</span></span>
                        </span>
                      </div>
                    )}
                    {modalData?.remarks && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>Remark</span>
                          <span>: {modalData?.remarks}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.requestId && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>Request ID</span>
                          <span>: {modalData?.requestId}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.manager && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>Manager</span>
                          <span>: {modalData?.manager}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.amount && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>Amount</span>
                          <span>: {modalData?.amount}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.UTR && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>UTR</span>
                          <span>: {modalData?.UTR}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.createdAt && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>CreatedAt</span>
                          <span>: {modalData?.createdAt}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.updatedAt && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>UpdatedAt</span>
                          <span>: {modalData?.updatedAt}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.IP && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>IP</span>
                          <span>: {modalData?.IP}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.gateway?.name && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>Gateway</span>
                          <span>: {modalData?.gateway?.name}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.gateway?.acNo && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>AccountNo</span>
                          <span>: {modalData?.gateway?.acNo}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.gateway?.bankName && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>BankName</span>
                          <span>: {modalData?.gateway?.bankName}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.gateway?.accountHolderName && (
                      <div className="col-sm-12">
                        <span className="modal_body_data">
                          <span>AccountHolderName</span>
                          <span>: {modalData?.gateway?.accountHolderName}</span>
                        </span>
                      </div>
                    )}
                    {modalData?.gateway?.IFSC && (
                      <div className="col-sm-12">
                        <span className="modal_body_data" style={{ textTransform: "uppercase" }}>
                          <span>IFSC</span>
                          <span>: {modalData?.gateway?.IFSC}</span>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pop-up__row-buttons">
            <ButtonCustom
              onClick={handleModalClose}
              color={"red"}
            >
              Close
            </ButtonCustom>
          </div>
        </div>
      </div>)}


    </div>
  );
};
