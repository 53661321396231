import { useEffect, useState, Fragment } from "react"
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import ButtonCustom from "../../components/ButtonCustom"
import { useAuth } from "../../Providers/AuthProvider"
import "./index.scss"
import StringToRecolorNumber from "../../components/StringToRecolorNumber"
import { useStorage } from "../../Providers/StorageProvider"
import CurrentTime, { getTime } from "../../components/CurrentTime"
import { downloadCsv } from '../../utils/createCsv'

export default ({ userId }) => {
  const [transferData, setTransferData] = useState({})
  const { requestsToApi } = useAuth()
  const { userName, setIsLoading ,activeTimeSetting } = useStorage()

  useEffect(async () => {
    try {
      setIsLoading(true)
      const response = await requestsToApi.get(
        `agency/${userName}/report/transfer${userId ? `?dl=${userId}&showDownlineView=true` : ""}`
      )
      setTransferData(response.data.result)
      setIsLoading(false)
    } catch (e) {
      console.log("error", e)
      setIsLoading(false)
    }
  }, [])

  const renderTooltip = (props, text, id) => (
    <Tooltip id={id} className="button-tooltip" {...props}>
      {text}
    </Tooltip>
  )

  const dataCsv = () => {
    let data_new = []
    Object.entries(transferData).forEach(([date, data]) => {
      data.forEach(row => {
        data_new.push({
          Date: getTime("DD/MM/YYYY", row.date, activeTimeSetting),
          Time:  getTime("HH:mm:ss", row.date, activeTimeSetting),
          'Payer/Payee': row.description,
          Amount: row.amount,
          Notes: row.comment || ''
        })
      })

    });
    console.log('data_new', data_new)
    return { data: data_new }
  }

  return (
    <div className="transfer-statement-page">
      <div className="page-header">
        <h1>Transfer Statement</h1>
        <ButtonCustom color="cyan" onClick={() => downloadCsv(dataCsv())}>Download CSV</ButtonCustom>
      </div>
      <div className="transfer-statement-page__content">
        {/* transferData - last chenge */}
        {!!Object.keys(transferData)?.length ? (
          <Table striped hover variant="dark">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Payer/Payee</th>
                <th scope="col">Amount</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(transferData).map(([date, data]) => (
                <Fragment key={date}>
                  <tr>
                    <td colSpan="4" style={{ backgroundColor: "#7E747F", paddingLeft: "30px" }}>
                      {<CurrentTime format="DD/MM/YYYY">{date}</CurrentTime>}
                    </td>
                  </tr>
                  {data.map(row => (
                    <tr key={row.date}>
                      <td className="td_time" scope="col">
                        {<CurrentTime format="HH:mm:ss">{row.date}</CurrentTime>}
                      </td>
                      <td scope="col">{row.description}</td>
                      <td scope="col">
                        <StringToRecolorNumber>{row.amount}</StringToRecolorNumber>
                      </td>
                      <td scope="col">
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 0, hide: 0 }}
                          overlay={props => renderTooltip(props, row.comment, row.date)}
                        >
                          {row.comment ? <a className="no-href">Notes</a> : <></>}
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </Table>
        ) : (
          <>There have been no transfer in the last 180 days</>
        )}
      </div>
    </div>
  )
}
