import "./index.scss"
import TextInputCustom from "../../components/TextInputCustom"
import { useEffect, useState } from "react"
import { HOST, useAuth } from "../../Providers/AuthProvider"
import ButtonCustom from "../../components/ButtonCustom"
import DateTimeRangePicker from "../../components/DateTimeRangePicker"
import moment from "moment"
import { ExportedBathcesInnerTable } from "./ExportedBathcesInnerTable"
import { useHistory } from "react-router-dom"
import { toastr } from "react-redux-toastr"

export const ExportedBatchesPage = () => {

  const [newOptions, setNewOptions] = useState({
    BatchID: "",
    managerId: " ",
    date: null
  })
  const history = useHistory()
  const { requestsToApi } = useAuth()
  const [reportsData, setReportsData] = useState([])
  const [dwReports, setDwReports] = useState([])
  const [accordeonOpen, setAccordeonOpen] = useState("")
  const [sortByDesc, setSortByDesc] = useState({ createdAt: true, updatedAt: false, amount: false })
  const [sortedReports, setSortedReports] = useState([])

  const token = sessionStorage.getItem("accessToken")
  const startOfToday = moment().startOf('day');
  const urlParams =
    "?" +
    "batchNo=" + newOptions.BatchID +
    "&filterManagerId=" + newOptions.managerId +
    "&from=" + (!newOptions?.date?.start ? startOfToday : newOptions?.date?.start)

  const changeValue = (value, filterTitle) => {
    setNewOptions({ ...newOptions, [filterTitle]: value })
  }

  const handleClickClear = () => {
    setNewOptions({ BatchID: "", managerId: "", date: null })
    setReportsData([])
    // console.log('handleClickClear works')
  }

  const handleClickApply = async () => {
    try {
      const response = await requestsToApi.get("/Banking/withdraw/batches" + urlParams)
      // console.log('/Banking/withdraw/batches response', response)
      setSortedReports(response.data.result.sort((a, b) => {
        if (a.createdAt > b.createdAt) return -1
      }))
      setReportsData(response.data.result)
    } catch (error) {
      console.log(error?.message)
    }
  }

  const completeBatch = async (e, BatchID) => {
    e.stopPropagation()
    try {
      const responseCompleteBatch = await requestsToApi.get("/Banking/withdraw/complete-batch?batchId=" + BatchID)
      console.log("responseCompleteBatch", responseCompleteBatch)
      if (responseCompleteBatch?.data?.success) {
        toastr.success(`${responseCompleteBatch?.data?.result}`)
      } else {
        toastr.error(`${responseCompleteBatch?.data?.result}`)
      }
    } catch (error) {
      console.log(error?.message)
      toastr.error(`${error?.message}`)
    }
  }

  // const handleDwReport = async (BatchID) => {
  //     try {
  //         const responseDwReport = await requestsToApi.get("/banking/dw-report" + "?status=Approved&type=W" + "&batchId=" + BatchID);
  //         setDwReports(responseDwReport.data.result);
  //         setSortedReports(responseDwReport.data.result.sort((a, b) => {if (a.createdAt > b.createdAt) return -1;}));
  //         accordeonOpen === BatchID ? setAccordeonOpen("") : setAccordeonOpen(BatchID);
  //     } catch (error) {
  //         console.log(error?.message);
  //     }
  // };

  useEffect(() => {
    // console.log('works')
    handleClickApply()
    handleClickClear()
    // setTimeout(() => {
    //     handleClickClear(); console.log('works2')
    // }, 2000);
  }, [])

  const changeSort = (key) => {
    setSortByDesc({ createdAt: false, updatedAt: false, amount: false, [key]: !sortByDesc[key] })

    if (reportsData.length >= 2) {
      if (key !== "amount") {
        const filter = reportsData.filter(item => item[key])

        const sort = filter.sort((a, b) => {
          if (a[key] > b[key]) return -1
        })
        setSortedReports(sortByDesc[key] ? [...sort.reverse(), ...reportsData.filter(item => !item[key])] : [...sort, ...reportsData.filter(item => !item[key])])
      }
    }
  }

  return (
    <div className="exported-batches">
      <div className="exported-batches__header-wrapper">
        <div className="exported-batches__header">
          <h1>My Batches</h1>
          <div className="exported-batches__header-filters">
            <div className="exported-batches__header-filters-inputs">
              <TextInputCustom className="customer-reports__textInputCustom" type="text"
                               onChange={(value) => changeValue(value, "BatchID")} placeholder="BatchID">
                {newOptions.BatchID}
              </TextInputCustom>
              <TextInputCustom className="customer-reports__textInputCustom" type="text"
                               onChange={(value) => changeValue(value, "managerId")} placeholder="ManagerID">
                {newOptions.managerId}
              </TextInputCustom>
            </div>
            <div className="exported-batches__header-date-buttons">
              <div className="exported-batches__header-date">
                <DateTimeRangePicker dateTimeRange={newOptions.date}
                                     onChangeFunc={(value) => changeValue(value, "date")} />
              </div>
              <div className="exported-batches__header-buttons">
                <ButtonCustom onClick={handleClickApply} color="cyan">Apply</ButtonCustom>
                <ButtonCustom onClick={handleClickClear} color="red">Clear</ButtonCustom>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="exported-batches__table">
        <ol className="exported-batches__table-header">
          <li>Open</li>
          <li>ID</li>
          <li>Manager</li>
          <li>No of records</li>
          <li>Amount</li>
          <li>Account Used</li>
          <li onClick={() => changeSort("createdAt")}>
            <span className={`exported-batches__table-header-sort ${sortByDesc.createdAt ? "" : "up"}`}>CreatedAt</span>
          </li>
          <li onClick={() => changeSort("updatedAt")}>
            <span
              className={`exported-batches__table-header-sort ${sortByDesc.updatedAt ? "" : "up"}`}>CompletedAt</span>
          </li>
          <li>Status</li>
          <li>Actions</li>
        </ol>
        <div className="exported-batches__table-body">
          {sortedReports?.map((report, index) =>
            <div key={report.requestId + index}
                 className={`${accordeonOpen === report.batchNo ? "accordeonOpen" : ""} exported-batches__table-row`}
            >

              {/*<div className="exported-batches__table-row-arrow-icon"*/}
              {/*     onClick={() => history.push("/exports/exported-batches/details", {*/}
              {/*       report: report.batchNo,*/}
              {/*       data: sortedReports*/}
              {/*     })}*/}
              {/*>*/}

              {/*</div>*/}
              <ol key={report.requestId + index} className="exported-batches__table-row-main-list">
                <li>
                  <button onClick={() => history.push("/exports/exported-batches/details", {
                  report: report.batchNo,
                  data: sortedReports
                })} className="exported-batches__table-button exported-batches__complete_button">
                  <svg className="mdi-icon " width="24" height="24" fill="currentColor"
                       viewBox="0 0 24 24">
                    <path d="M7,10L12,15L17,10H7Z"></path>
                  </svg>
                </button>
                </li>
                <li>{report.batchNo}</li>
                <li>{report.createdBy}</li>
                <li>{report.noTxn}</li>
                <li>{report?.amount}</li>
                <li>{report?.ourAccountNo}</li>
                <li className="exported-batches__list-date-cell">
                  <span>{moment(report?.createdAt).format("DD.MM.YY")}</span>
                  <span className="time">{moment(report?.createdAt).format("LTS")}</span>
                </li>
                <li className="exported-batches__list-date-cell">
                  {report.completionDate &&
                    <div>
                      <span>{moment(report.completionDate).format("DD.MM.YY")}</span>
                      <span className="time">{moment(report.completionDate).format("LTS")}</span>
                    </div>}
                </li>
                <li>{report?.status}</li>
                <li>
                  <button onClick={(e) => completeBatch(e, report.batchNo)}
                          className="exported-batches__table-button exported-batches__complete_button">
                    <span>Complete</span>
                  </button>
                  <button onClick={(e) => e.stopPropagation()}
                          className="exported-batches__table-button exported-batches__download_button">
                    <a
                      href={HOST + "/Banking/withdraw/export-batch?batchId=" + report.batchNo + "&downloadtoken=" + token}
                      target="_blank" download rel="noreferrer"
                    >
                      <span>Download</span>
                    </a>
                  </button>
                  <button onClick={(e) => e.stopPropagation()}
                          className="exported-batches__table-button exported-batches__download_button">
                    <a
                      href={HOST + "/Banking/withdraw/export-batch/csv?batchId=" + report.batchNo + "&downloadtoken=" + token}
                      target="_blank" download rel="noreferrer"
                    >
                      <span>CSV</span>
                    </a>
                  </button>
                </li>
              </ol>
              {/* {accordeonOpen === report.batchNo
                                ? dwReports?.map((dwReport, index) => <ExportedBathcesInnerTable dwReport={dwReport} key={dwReport.requestId + index}/>)
                                : ""
                            } */}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

