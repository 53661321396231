import "./index.scss";
import {useEffect, useRef, useState} from "react";
import InfoPopUp from "../InfoPopUp";
import axios from "axios";
import { HOST } from "../../Providers/AuthProvider"

export default ({visible, closeFunc, data}) => {
    const [report, setReport] = useState({});
    const [error, setError] = useState("");
    const [isVisibleInfoPopUp, setIsVisibleInfoPopUp] = useState(false);

    const utrInput = useRef(null);
    const submitButton = useRef(null);

    useEffect(() => {
        setReport(data);
    }, [data]);

    const changeReport = (e) => {
        setReport({...report, [e.target.name]: e.target.value});
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const token = sessionStorage.getItem("accessToken");
        try {
            const response = await axios.post(HOST + "/Banking/deposit/update-request",
                {"requestId": report?.requestId, "UTR": report?.utrNo, "amount": report?.amount},
                {headers: {"Authorization": `${token}`}},
            );
            // console.log("response", response);

            if (response.data.success) {
                setIsVisibleInfoPopUp(true);
                setTimeout(() => setIsVisibleInfoPopUp(false), 3000);
                setTimeout(() => closeFunc(), 2500);
            }

        } catch (error) {
            setError(error?.response?.data?.error || error?.response?.data?.result);
            setIsVisibleInfoPopUp(true);
            setTimeout(() => setError(""), 3000);
            setTimeout(() => setIsVisibleInfoPopUp(false), 3000);
        }
    };
    const handleClickReset = () => {
        setReport(data);
        closeFunc();
    };

    return visible && (
    <div className="deposit-entry-pop-up">
      <InfoPopUp title={error} isError={!!error} isVisible={isVisibleInfoPopUp}/>;
      <div className="deposit-entry-pop-up__form-wrapper">
        <div className="deposit-entry-pop-up__title_row">
          <span className="deposit-entry-pop-up__title_row-title">Update Deposit Entry</span>
          <span className="deposit-entry-pop-up__title_row-id">#{data.requestId}</span>
        </div>
        <div className="deposit-entry-pop-up__form">
          <div className="deposit-entry-pop-up__form-body">
            <div className="deposit-entry-pop-up__form-input-wrapper">
              <label className="deposit-entry-pop-up__top-input-label deposit-entry__label">Amount</label>
              <input className="deposit-entry-pop-up__form-input"
                     type="number"
                     placeholder="Amount"
                     autoFocus
                     value={report?.amount}
                     name={"amount"}
                     onChange={changeReport}
                     onKeyPress={e => e.key === "Enter" ? utrInput.current.focus() : "" }
              />
            </div>
            <div className="deposit-entry-pop-up__form-input-wrapper">
              <label className="deposit-entry-pop-up__top-input-label deposit-entry__label">UTR or Ref No</label>
              <input className="deposit-entry-pop-up__form-input"
                     type="text"
                     autoComplete="off"
                     placeholder="UTR"
                     ref={utrInput}
                     onKeyPress={e => e.key === "Enter" ? submitButton.current.focus() : "" }
                     value={report?.utrNo}
                     name={"utrNo"}
                     onChange={changeReport}
              />
            </div>
          </div>
          <div className="deposit-entry-pop-up__form-footer">
            <button onClick={handleClickReset} className="deposit-entry-pop-up__form-button deposit-entry-pop-up__form-reset">
              <span>RESET</span>
            </button>
            <button ref={submitButton} onClick={handleFormSubmit} className="deposit-entry-pop-up__form-button deposit-entry-pop-up__form-save">
              <span>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
