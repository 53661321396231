import { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import MenuDownIcon from 'mdi-react/MenuDownIcon'
import './index.scss'

export default ({ options, title = 'some title', triangle, className, opacity }) => {
  // const [activeOption, setActiveOption] = useState(options ? options[0].title : null)

  return (
    <Dropdown className={`header-dropdown ${className ? className : ''}`}>
      <Dropdown.Toggle className={`header-dropdown__toggle ${triangle ? '' : 'triangle_disabled'} ${opacity ? 'hover-opacity' : ''}`}>
        {title}
        {/* <MenuDownIcon className='custom-dropdown__icon' /> */}
      </Dropdown.Toggle>

      <Dropdown.Menu className="header-dropdown__menu">
        {
          options && options.length ?
            options.map(item => <Dropdown.Item
              key={Math.random()}
              onClick={() => {
                // setActiveOption(item.title);
                if (item.toggle) item.toggle();
              }
              }
            >{item.title}</Dropdown.Item>) :
            <Dropdown.Item active>No ontions</Dropdown.Item>
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}