import * as XLSX from "xlsx/xlsx.mjs"
import axios from "axios"
import { HOST } from "../../../Providers/AuthProvider"

let asdasd = ['t']
export const AUXLSParse = async (file, selectedAccount, setErroredItems) => {
    // const dispatch = useDispatch()
  const token = sessionStorage.getItem("accessToken")
  const reader = new FileReader()
  const rABS = !!reader.readAsBinaryString
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target.result
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
    /* Get first worksheet */
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    console.log(rABS, wb)
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
    /* Update state */
    parsing(data)
    console.log("daaaaaaaaa", data)
  }
  if (rABS) reader.readAsBinaryString(file)
  else reader.readAsArrayBuffer(file)

  const parsing = xlsData => {
    let res = []
    let errored = []
    console.log("xlsData", xlsData)
    xlsData.map(item => {
      if (!item[3]?.toString()?.toLowerCase()?.includes("cheque")
          && !item[3]?.toString()?.toLowerCase()?.includes("ref.")) {
      if (
        item.length === 28 &&
        (item[3]?.split("/").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0] || item[3]?.split("-").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0]) &&
        item[22]
      ) {
        res.push({
          UTR: (item[3]?.split("/").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0] || item[3]?.split("-").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0]),
          amount: item[22],
          acNo: selectedAccount
        })
      } else if(item[3] && item[22]?.replace(/\D/g, "")[0]){
        errored.push({
            UTR: item[3],
            amount: item[22]
        })
        // dispatch(getDepositEntryErroredItems(['t', 't']))
      }
    }
    })
    setErroredItems(errored)
    try {
      axios.post(HOST + "/Banking/deposit-entry-bulk",
        res,
        {
          headers: { "Authorization": `${token}` }
        }).then((response) => {
        console.log(response.data)
      })
    } catch (e) {
      console.log(e)
    }
    console.log("DATA TO SEND", res)
    console.log("ERRORED ITEM (No Amount or UTR)", errored)
  }
  ////// END FEDERAL XSLX //////
}

export const erroredAU = () => {
    return asdasd
}
