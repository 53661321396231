import "./index.scss"
import { useEffect, useRef, useState } from "react"
import InfoPopUp from "../../components/InfoPopUp"
import axios from "axios"
import { HOST, useAuth } from "../../Providers/AuthProvider"
import ButtonCustom from "../../components/ButtonCustom"
import SwitchCustom from "../../components/SwitchCustom"
import { useHistory } from "react-router-dom";
import { AddingPaymentAccountsPage } from "../AddingPaymentAccountsPage"
import { useStorage } from "../../Providers/StorageProvider"

export const AccountsPage = () => {
  let [showOption, setShowOption] = useState(false)
  let [selectedAccount, setSelectedAccount] = useState("Account No")
  let [isVisibleInfoPopUp, setIsVisibleInfoPopUp] = useState(false)
  const { requestsToApi } = useAuth()
  const utrInput = useRef(null)
  const history = useHistory()
  const submitButton = useRef(null)
  let [editing, setEditing] = useState(false);
  let [editingType, setEditingType] = useState(false);
  let [editingData, setEditingData] = useState(false);
  const { userPermissions } = useStorage();

  const handleListDisplay = () => {
    setShowOption(prevState => !prevState)
  }

  const handleOptionClick = e => {
    setSelectedAccount(e.target.innerHTML)
    setShowOption(false)
    sessionStorage.setItem("selectedAccountNumber", e.target.innerHTML)
  }

  const [accounts, setAccounts] = useState([])
  const [allAccounts, setAllAccounts] = useState([])
  let [amountState, setAmountState] = useState("")
  let [utrRefState, setUtrRefState] = useState("")
  let [amountValidationCheck, setAmountValidationCheck] = useState(false)
  let [utrRefValidationCheck, setUtrRefValidationCheck] = useState(false)
  let [utrRefExistCheck, setUtrRefExistCheck] = useState(true)
  let [isAccountSwitcher, setIsAccounSwitcher] = useState(true)


  const toggleSwitch = () => {
    setIsAccounSwitcher(!isAccountSwitcher)
    if(isAccountSwitcher){
      const results = []
      allAccounts.map((value) => {
        if (!value["isActive"]) {
          results.push(value)
        }
      })

      setAccounts([
        ...results
      ])
    }
    else{
      const results = []
      allAccounts.map((value) => {
        if (value["isActive"]) {
          results.push(value)
        }
      })

      setAccounts([
        ...results
      ])
    }
  }

  const savedAccountNumber = () => {
    sessionStorage.getItem("selectedAccountNumber")
      ? setSelectedAccount(sessionStorage.getItem("selectedAccountNumber"))
      : setSelectedAccount("Account No")
  }

  useEffect(() => {
    if (!editing){
      setEditingData(null)
      setEditingType('')
    }
  }, [editing])

  let amountOnInputChange = e => {
    let temp = Number(Number(e.target.value).toFixed(2))

    if (temp > 0) {
      setAmountState(temp)
    } else {
      setAmountState("Amount")
    }
  }

  let utrRefOnInputChange = e => {
    // let temp = e.target.value.split(".")[0]
    // temp = Number(temp)

    if (e.target.value.length >= 0) {
      setUtrRefState(e.target.value)
    } else {
      setUtrRefState("")
    }
  }

  let amountValidation = e => {
    let amountToNum = Number(e.target.value)

    if (amountToNum > 0) {
      setAmountValidationCheck(true)
    } else {
      setAmountValidationCheck(false)
    }
  }

  let utrRefValidation = e => {
    // let utrRefToNum = Number(e.target.value);

    if (e.target.value.length > 0) {
      setUtrRefValidationCheck(true)
    } else {
      setUtrRefValidationCheck(false)
    }
  }

  let runAmountSetStateValidation = e => {
    amountOnInputChange(e)
    amountValidation(e)
  }

  let runUtrRefSetStateValidation = e => {
    utrRefOnInputChange(e)
    utrRefValidation(e)
  }

  useEffect(async () => {
    try {
      const response = await requestsToApi.get(HOST + "/Banking/deposit/payment-gateways?requestAll=true")
      setAllAccounts(response.data.result)

      const results = []
      response.data.result.map((value) => {
        if (value["isActive"]) {
          results.push(value)
        }
      })

      setAccounts([
        ...results
      ])

    } catch (error) {
      console.log(error?.message)
    }
    savedAccountNumber()
  }, [editing])

  const handleClickReset = e => {
    e.preventDefault()
    setAmountState("")
    setUtrRefState("")
  }

  const handleFormSubmit = async e => {
    e.preventDefault()
    const token = sessionStorage.getItem("accessToken")

    try {
      const response = await axios.post(
        HOST + "/Banking/deposit-entry",
        {
          UTR: utrRefState,
          amount: amountState,
          acNo: selectedAccount
        },
        { headers: { Authorization: `${token}` } }
      )

      if (response.data.success) {
        setIsVisibleInfoPopUp(true)
        setAmountState("Amount")
        setUtrRefState("UTR")
        setAmountValidationCheck(false)
        setUtrRefValidationCheck(false)
        setTimeout(() => setIsVisibleInfoPopUp(false), 3000)
        setUtrRefExistCheck(true)
      }
    } catch (error) {
      if (error && error.response && error.response.status && error.response.status === 400) {
        setUtrRefExistCheck(false)
        setIsVisibleInfoPopUp(true)
        setTimeout(() => setIsVisibleInfoPopUp(false), 3000)
      }
    }
  }


  return (
    !editing ? <div className="deposit-entry__main">
      <div className="deposit-entry__main-header">
        <h1 className="deposit-entry__main-header-title">Deposit Accounts</h1>
        {userPermissions?.includes( "deposit-accounts-edit") &&
        <ButtonCustom color="green" onClick={() => {
          setEditing(true)
          setEditingType('Add')
          }}>Add New</ButtonCustom>}
        <form action="#">
          <div className="switchAccount deposit-entry__main-header-switch" >
            <input
              id={"account_switch"}
              type="checkbox"
              onChange={toggleSwitch}
              className={`switchAccount-input`}
              checked={isAccountSwitcher}
            />
            <label htmlFor={"account_switch"} className={`switchAccount__label labelAccount_red`}>Switch</label>
          </div>
        </form>
      </div>
      
      <div className="accountsPayment__table">
        <ul className={`accountsPayment__table-header`}>
          <li className={`accountsPayment__table-header-sort-button`}>ID</li>
          <li className={`accountsPayment__table-header-sort-button`}>Image</li>
          <li className={`accountsPayment__table-header-sort-button`}>Name</li>
          <li className={`accountsPayment__table-header-sort-button`}>isManual</li>
          <li className={`accountsPayment__table-header-sort-button`}>isActive</li>
          <li className={`accountsPayment__table-header-sort-button`}>isUPI</li>
          <li className={`accountsPayment__table-header-sort-button`}>Tiers</li>
          <li className={`accountsPayment__table-header-sort-button`}>min</li>
          <li className={`accountsPayment__table-header-sort-button`}>max</li>
          <li className={`accountsPayment__table-header-sort-button`}>Promo</li>
          <li className={`accountsPayment__table-header-sort-button`}>Index</li>
          <li className={`accountsPayment__table-header-sort-button`}>Account details</li>
          <li className={`accountsPayment__table-header-sort-button`}></li>
        </ul>
        <div className="accountsPayment__table-body">
          {accounts?.sort((a, b) => b.isActive - a.isActive).map((report, index) => (
            <ul key={index} className="accountsPayment__table-body-row">
             {/* {false && <AddingPaymentAccountsPage report={account}></AddingPaymentAccountsPage>} */}
              <li>{report?.id}</li>
              <li className="accountsPayment__table-body-row-logo"><img style={{width: '80%', height: '50%'}} src={report.logo} alt="" /></li>
              <li>{report?.name}</li>
              <li>
                <form action="#">
                    <div className="switchAccount">
                      <input
                        id={report.id}
                        type="checkbox"
                        // onChange={toggleSwitch}
                        className={`switchAccount-input `}
                        checked={report.isManual}
                      />
                      <label htmlFor={report.id} className={`switchAccount__label labelAccount_red`}>Switch</label>
                    </div>
                  </form>
              </li>
              <li>
                <form action="#">
                    <div className="switchAccount">
                      <input
                        id={report.id + 1}
                        type="checkbox"
                        // onChange={toggleSwitch}
                        className={`switchAccount-input `}
                        checked={report.isActive}
                      />
                      <label htmlFor={report.id + 1} className={`switchAccount__label labelAccount_red`}>Switch</label>
                    </div>
                  </form>
              </li>
              <li>
                <form action="#">
                    <div className="switchAccount">
                      <input
                        id={report.id}
                        type="checkbox"
                        // onChange={toggleSwitch}
                        className={`switchAccount-input `}
                        checked={report.isUPI}
                      />
                      <label htmlFor={report.id} className={`switchAccount__label labelAccount_red`}>Switch</label>
                    </div>
                  </form>
              </li>
              {/* <li>{report.isActive ? "true" : "false"}</li>
              <li>{report.isUPI ? "true" : "false"}</li> */}
              <li>{report.isTier ? report.tier : "Off"}</li>
              <li>{report.min}</li>
              <li>{report.max}</li>
              <li>{report?.promo || ''}</li>
              <li>{report?.index || ''}</li>
              <li className="accountsPayment__table-body-row-date">
                <div className="accountsPayment__table-body-row-date-detailes">
                  <div className="time">acNo</div>
                  <div>{report?.account?.acNo}</div>
                  <div className="time">IFSC</div>
                  <div>{report?.account?.IFSC}</div>
                  <div className="time">Name</div>
                  <div>{report.account.name}</div>
                  <div className="time">Bank Name</div>
                  <div>{report.account.bankName}</div>
                </div>
              </li>
              { userPermissions?.includes( "deposit-accounts-edit") &&
              <li className={`accountsPayment__table-body-row-cancel`}>
                <ButtonCustom color="green" onClick={() => {
                  setEditing(true)
                  setEditingType('Edit')
                  setEditingData(report)
                }}>Edit</ButtonCustom>
              </li>}
            </ul>
          ))}
        </div>
      </div>
    </div>
    :
    <AddingPaymentAccountsPage report={editingData} editingType={editingType} setEditing={setEditing}/>
  )
}
