import "./index.scss";
import {useState} from "react";

export default ({placeholder = "Search", onChange}) => {
    let [inputText, setInputText] = useState("");

    const handleInput = (e) => {
        setInputText(e.target.value);
        onChange(e.target.value);
    };

    return (
    <div className="search-custom-main">
      <input type="search" placeholder={placeholder} value={inputText} onInput={handleInput} />
    </div>
    );
};
