import * as XLSX from 'xlsx/xlsx.mjs';
import axios from "axios"
import { HOST } from "../../../Providers/AuthProvider"

export const FederalParse = async (file, selectedAccount, setErroredItems) => {

  const token = sessionStorage.getItem("accessToken")
  let accountNumber = "";

  const reader = new FileReader()
  const rABS = !!reader.readAsBinaryString
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target.result
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
    /* Get first worksheet */
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    console.log(rABS, wb)
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 })

    accountNumber = data[8][2];

    /* Update state */
    parsing(data)
    // console.log('daaaaaaaaa', data)
  }
  if (rABS) reader.readAsBinaryString(file)
  else reader.readAsArrayBuffer(file)


  const parsing = (xlsData) => {
    let res = []
    let errored = []
    xlsData.map(item => {
      let amount
      let UTR

      if (item.length === 10 && item[8]) {
        if (!item[2]?.toString()?.toLowerCase()?.includes("cheque")
        && !item[2]?.toString()?.toLowerCase()?.includes("ref.")) {
        if (item[2].includes("UPI IN")) {
          UTR = item[2].split("/")[1]
          amount = item[8]
          res.push({
            "UTR": UTR,
            "amount": amount,
            "acNo": accountNumber
          });
        } else if (item[2]?.includes("FT IMPS")) {
          UTR = item[2]?.split("/")[2]
          amount = item[8]
          res.push({
            "UTR": UTR,
            "amount": amount,
            "acNo": accountNumber,
          });
        } else {
          errored.push({
            "UTR": item[2],
            "amount": item[8],
            "acNo": accountNumber,
            "particular": item
          })
        }
      }

      } else {
        errored.push({
          "UTR": item[2],
          "amount": item[7],
          "acNo": accountNumber,
          "particular": item
        })
      }
    })
    setErroredItems(errored)
    console.log("DATA TO SEND", res)

    try {
      axios.post(HOST + "/Banking/deposit-entry-bulk",
        res,
        {
          headers: { "Authorization": `${token}` }
        }).then((response) => {
          console.log(response.data)
      })
    } catch (e) {
      console.log(e);
    }
    console.log("ERRORED ITEM (No Amount or UTR)", errored)

  }
////// END FEDERAL XSLX //////

}
