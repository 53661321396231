import './index.scss'
import TextInputCustom from '../TextInputCustom'
import { ButtonGroup, Dropdown } from 'react-bootstrap'
import { useStorage } from '../../Providers/StorageProvider';
import { useAuth } from '../../Providers/AuthProvider';
// import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';


export default ({ placeholder = "", dropdownClickFunc, color = "white", defaultInputValue = "" }) => {
  const [searchValue, setSearchValue] = useState(defaultInputValue);
  const [searchResult, setSearchResult] = useState(null);
  const { userName } = useStorage();
  const { requestsToApi } = useAuth();
  const searchUserInputTrigger = async (value) => {
    setSearchValue(value)
    if (value.length >= 4) {
      const response = await requestsToApi.get('agency/' + userName + '/agency-mgmt/downline/suggestion?isMemberOnly=false&searchHint=' + value)
      setSearchResult(response.data.result)
    }
  }

  useEffect(() => {
    setTimeout(() => { setSearchValue(defaultInputValue) }, 100)
  }, [defaultInputValue])

  return <div className="search-user">
    <Dropdown show={searchResult ? true : false} as={ButtonGroup}>
      <Dropdown.Toggle id="dropdown-invisible-1">Pow! Zoom!</Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="dropdown-menu__container">
          {searchResult ? searchResult.map((user, index) => {
            return <Dropdown.Item
              eventKey={index + 1}
              key={index + 1}
              onClick={() => { dropdownClickFunc(user) }}
            >
              <div className="user-name">
                {user.loginName + ' - ' + user.userCode}
              </div>
              <div className={"level " + user.userLevel}>
                {user.userLevel}
              </div>
            </Dropdown.Item>
          }) : <></>}
        </div>

      </Dropdown.Menu>
    </Dropdown>
    <TextInputCustom
      placeholder={placeholder}
      color={color}
      onChange={searchUserInputTrigger}
      autocomplete="cc-csc"
      type="cc-csc"
      name="cvc"
      onBlur={() => setTimeout(() => { setSearchValue(defaultInputValue); setSearchResult(null) }, 100)}
    >{searchValue}
    </TextInputCustom>
  </div>
}