import {useEffect, useRef, useState} from "react";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import {Button, FormControl} from "react-bootstrap";
import moment from "moment";
import "./index.scss";
import CalendalIcon from "mdi-react/CalendarMonthIcon";

export default ({
  color = "#37133F",
  dateTimeRange,
  onChangeFunc,
}) => {


    let now = new Date();

    const startOfToday = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    const endOfToday = moment(startOfToday).add(1, "days").subtract(1, "seconds");
    const [rangeDate, setRangeDate] = useState(dateTimeRange ? dateTimeRange :{start: startOfToday, end: endOfToday});
    const specifickButton = useRef(null);

    useEffect(() => {
        dateTimeRange ? setRangeDate(dateTimeRange) : setRangeDate({start: startOfToday, end: endOfToday});
    }, [dateTimeRange]);

    let ranges = {
        "Today Only": [moment(startOfToday), moment(endOfToday)],
        "Yesterday Only": [moment(startOfToday).subtract(1, "days"), moment(endOfToday).subtract(1, "days")],
        "3 Days": [moment(startOfToday).subtract(3, "days"), moment(endOfToday)],
        "Current Week": [moment(startOfToday).startOf('week'), moment(endOfToday)],
        "Last Week": [moment(startOfToday).startOf('week').subtract(1,'week'), moment(endOfToday).endOf('week').subtract(1,'week')],
        "Current Month": [moment(startOfToday).startOf('month'), moment(endOfToday)],
        "Last Month": [moment(startOfToday).startOf('month').subtract(1,'month'), moment(endOfToday).endOf('month').subtract(1,'month')],
        "Last 30 days": [moment(startOfToday).subtract(30, "days"), moment(endOfToday)],
    };
    let local = {
        "format": "DD-MM-YYYY HH:mm:ss",
        "sundayFirst": false,
        days: ["M", "T", "W", "T", "F", "S", "S"],
        fromDate: "Time Range",
        toDate: "Date Range",
        cancel: "Clear",
    };
  // let maxDate = moment(startOfToday).add(24, "hour")

    const doSomethingElse = (start, end) => {
        setRangeDate({start, end});
        onChangeFunc({start, end});

    // setInputValue(`${start.format('Do MMM h:mm:ss a, YYYY').replace(twelve, ' 00:')} - ${end.format('Do MMM h:mm:ss a, YYYY')}`)
    };

    return (
    <div
      className="date-time-range__box"
      style={{width: "fit-content", backgroundColor: color}}
      ref={specifickButton}
      onFocus={() => specifickButton.current.focus()}
      onBlur={() => specifickButton.current.focus()}
    >
      <div >
        <DateTimeRangeContainer
          ranges={ranges}
          start={rangeDate.start}
          end={rangeDate.end}
          local={local}
          applyCallback={doSomethingElse}
          noMobileMode={true}
          style={{
              fromDate: {color: "white", backgroundColor: "#37133F", borderRadius: 0},
              toDate: {color: "white", backgroundColor: "#37133F", borderRadius: 0},
            // customRangeButtons : { backgroundColor : ' rgb (40, 90, 75) ' } ,
            // customRangeSelected : {         background: "#0f8422",
            //     "box-shadow": "inset -2px -2px 0px #0b5617" } ,
          }}
        >
          <Button
            id="formControlsTextB"
            type="text"
            label="Text"
            // placeholder="Enter text"
            // value={inputValue}
            // onChange={(e) => setInputValue(e.target.value)}
            aria-describedby="btnGroupAddon"
            style={{backgroundColor: color}}
          >
            <span>{`${rangeDate.start.format("Do MMM HH:mm:ss")} - ${rangeDate.end.format("Do MMM HH:mm:ss, YYYY")}`}</span>
            <CalendalIcon size={18} />
          </Button>
          {/* <button className="test"/> */}

        </DateTimeRangeContainer>
      </div>
    </div>
    );
};
