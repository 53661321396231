import "./index.scss"
import { useEffect, useState } from "react"
import ButtonCustom from "../ButtonCustom"
import InfoPopUp from "../InfoPopUp"
import axios from "axios"

export default ({ visible = true, closeFunc, data, isWithdraw = false, isUpdated}) => {
  const [report, setReport] = useState({});
  const [copied, setCopied] = useState("");
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [isVisibleInfoPopUp, setIsVisibleInfoPopUp] = useState(false);
  const [remark, setRemark] = useState("");

  useEffect(() => {
    setReport(data)
  }, [data]);


  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(""), 500)
    }
  }, [copied])

  const handleClickApprove = async () => {
    const token = sessionStorage.getItem("accessToken");
    setDisabled(false);

    try {
      if (remark === "") {
        return setError("Please enter remark");
      }

      const response = await axios.post(`https://payments.gemexch.bet/api/Banking/reject-request`,
        {
          "remark": remark,
          "requestId": report.requestId
        },
        { headers: { "Authorization": `${token}` } }
      )

      if (response.data.success) {
        setIsSuccess(true)
        setError(response?.data?.result)
        setIsVisibleInfoPopUp(true)
        isUpdated(true);
        handleClickCancel();
        setTimeout(() => {
          setIsVisibleInfoPopUp(false)
          setDisabled(false)
        }, 3000)
        setTimeout(() => closeFunc(true), 2500);
      
      }

    } catch (error) {
      setIsSuccess(false)
      setError(error?.response?.data?.result)
      setIsVisibleInfoPopUp(true)
      setTimeout(() => {
        setError("")
        setDisabled(false)
        setIsVisibleInfoPopUp(false)
      }, 3000)
    }
  }
  const handleClickCancel = () => {
    closeFunc();
  }

  return visible && (
    <div className="deposit-pop-up">
      <div className="message">
        <InfoPopUp title={error} isError={!isSuccess} isSuccess={isSuccess} isVisible={isVisibleInfoPopUp} />
      </div>
      <div className="deposit-pop-up__body">
        <div className="close" onClick={handleClickCancel}>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 30 30">
            <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" fill="white"></path>
          </svg>
        </div>
        <div className="deposit-pop-up__title">Do you wish to cancel the {isWithdraw ? "withdrawal" : "deposit"} request?</div>
        <div className="deposit-pop-up__row-title">
          <div><p>User ID:</p> <span>{report?.loginName}</span></div>
          <div><p>Request ID:</p> <span>{report?.requestId}</span></div>
          <div><p>UTR: </p> <span>{report?.amount}</span></div>
        </div>
        <div className="remark_section">
          <div className="remark_section__title">Remark :</div>
          <textarea
            className="remark_section__textarea"
            placeholder="Enter Remark"
            value={remark}
            onChange={(e) => {
              setRemark(e.target.value);
              if (e.target.value !== "") {
                setDisabled(false)
              } else {
                setDisabled(true)
              }
            }}
            required
          />
        </div>
        <div className="deposit-pop-up__row-buttons">
          <div className="deposit-pop-up__row-buttons-wrapper">
            <ButtonCustom
              onClick={handleClickCancel}
              color={"red"}
            >
              Cancel
            </ButtonCustom>
            <ButtonCustom
              onClick={handleClickApprove}
              color="cyan"
              disabled={isDisabled}
            >
              Reject request
            </ButtonCustom>
          </div>
        </div>
      </div>
    </div>
  )
  return <></>
};
