import * as XLSX from "xlsx/xlsx.mjs"
import axios from "axios"
import { HOST } from "../../../Providers/AuthProvider"
import moment from "moment"
import jwt_decode from "jwt-decode";
let asdasd = ['t']
export const utkarshXLS = async (file, selectedAccount, setErroredItems) => {
    // const dispatch = useDispatch()
    const token = sessionStorage.getItem("accessToken")
    const user = jwt_decode(token);
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    const myId = sessionStorage.getItem("myId")
    const userName = sessionStorage.getItem("myUserName")
    reader.onload = e => {
        /* Parse data */
        const bstr = e.target.result
        const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
        /* Get first worksheet */
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        console.log(rABS, wb)
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
        /* Update state */
        parsing(data)
        console.log("daaaaaaaaa", data)
    }
    if (rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)

    const parsing = xlsData => {
        let res = []
        let accountEntries = []
        let errored = []
        let allSendingArr = []
        console.log("xlsData", xlsData);
        xlsData.filter(item => item[1] && item[1].length == 11 && item[1].split("-").length == 3).map(item => {
            console.log("item", item);
            let date = moment(item[1], 'DD-MMM-YYYY').format("YYYY-MM-DD");
            let amount =  item[4] || item[5]
            const obj = {
                description: item[3],
                utr: item[3].split("/")[0] == "IMPS-P2A" ? item[3].split("/")[1] : item[3].split("/")[1] == "IMPS-P2A" ? item[3].split("/")[2] : item[3].split("/")[0] == "UPI" && item[3].split("/")[1] == "CR" ? item[3].split("/")[2] : null,
                accountNumber: selectedAccount,
                amount: parseFloat((amount + "").toString().replaceAll(",", "")),
                isDeposit: item[4] ? false : true,
                userId: myId,
                userName: userName,
                psId: user.paymentSystemId,
                isPlugin: false,
                date: date + "T09:50:05.877Z",
                isRecognized: false,
                isProcessed: false
            }
            accountEntries.push(obj)
            if (obj.isDeposit && obj.utr && obj.amount) {
                allSendingArr.push({
                    UTR: obj.utr,
                    amount: obj.amount,
                    acNo: selectedAccount
                })
            }
        })
        console.log("accountEntries", accountEntries);
        console.log("allSendingArr", allSendingArr);
        setErroredItems(errored)
        try {
            console.log("accountEntries", accountEntries);
            console.log("allSendingArr", allSendingArr);
              axios.post(HOST + "/Banking/deposit-entry-bulk",
                allSendingArr,
                {
                  headers: { "Authorization": `${token}` }
                }).then((response) => {
                  console.log(response.data)
                })
              axios.post("https://banking.upicash.com/api/account_entries/",
                accountEntries,
                {
                  headers: { "Authorization": `${token}` }
                }).then((response) => {
                  console.log(response.data)
                })
        } catch (e) {
            console.log(e)
        }
        console.log("DATA TO SEND", res)
        console.log("DATA TO SEND", accountEntries)
        console.log("ERRORED ITEM (No Amount or UTR)", errored)
    }
    ////// END FEDERAL XSLX //////
}

export const erroredAU = () => {
    return asdasd
}
