import axios from "axios"
import { HOST } from "../../../Providers/AuthProvider"

export const RBLCSVParse = async (file, selectedAccount, setErroredItems) => {
  let allItems
  let parsedItems
  console.log("output", await file.text())
  allItems = await file.text()

  function CSVToArray(strData, strDelimiter) {
    strDelimiter = strDelimiter || ","

    var objPattern = new RegExp(
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" + '(?:"([^"]*(?:""[^"]*)*)"|' + '([^"\\' + strDelimiter + "\\r\\n]*))",
      "gi"
    )

    var arrData = [[]]

    var arrMatches = null

    while ((arrMatches = objPattern.exec(strData))) {
      var strMatchedDelimiter = arrMatches[1]
      if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
        arrData.push([])
      }

      var strMatchedValue

      if (arrMatches[2]) {
        strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"')
      } else {
        strMatchedValue = arrMatches[3]
      }
      arrData[arrData.length - 1].push(strMatchedValue)
    }
    // console.log("object", arrData)

    // Return the parsed data.
    return arrData
  }

  if (allItems) {
    parsedItems = CSVToArray(allItems)
  }

  if (parsedItems) {
    const token = sessionStorage.getItem("accessToken")
    let i = 0
    let errored = []
    let allSendingArr = []
    console.log(parsedItems)
    parsedItems?.map(async item => {
      try {
        // console.log(item[2]?.split('/').filter(item2 => item2.length === 12 && item2.replace( /\D/g, '')).length > 0 ? item[2]?.split('/').filter(item2 => item2.length === 12 && item2.replace( /\D/g, '')) : null);
        if (!item[2]?.toString()?.toLowerCase()?.includes("cheque")
          && !item[2]?.toString()?.toLowerCase()?.includes("ref.")) {
        if (
          (item[2]?.split("/").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0] ||
            item[2]?.split(" ").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0]) &&
          item[5]
        ) {
          allSendingArr.push({
            UTR:
              item[2]?.split("/").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0] ||
              item[2]?.split(" ").filter(item2 => item2.length === 12 && item2.replace(/\D/g, ""))[0],
            amount: item[5].replaceAll(" ", ""),
            acNo: selectedAccount
          })
          i = ++i
          // console.log(i)
        } else {
          errored.push({
            UTR: item[2],
            amount: item[5]
          })
        }
      }
      } catch (e) {
        console.log(e);
      }
    })
    setErroredItems(errored)
    try {
      axios.post(HOST + "/Banking/deposit-entry-bulk",
        allSendingArr,
        {
          headers: { "Authorization": `${token}` }
        }).then((response) => {
        console.log(response.data)
      })
    } catch (e) {
      console.log(e)
    }
    console.log("DATA TO SEND", allSendingArr)

  }
}
