import * as XLSX from "xlsx/xlsx.mjs"
import axios from "axios"
import { HOST } from "../../../Providers/AuthProvider"

let asdasd = ["t"]
export const YESExcel = async (file, selectedAccount, setErroredItems) => {
  // const dispatch = useDispatch()
  const token = sessionStorage.getItem("accessToken")
  const reader = new FileReader()
  const rABS = !!reader.readAsBinaryString
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target.result
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
    /* Get first worksheet */
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    console.log(rABS, wb)
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
    /* Update state */
    parsing(data)
    console.log("daaaaaaaaa", data)
  }
  if (rABS) reader.readAsBinaryString(file)
  else reader.readAsArrayBuffer(file)

  const parsing = xlsData => {
    let res = []
    let errored = []
    console.log("xlsData", xlsData)
    xlsData.map(item => {
      try {
        if (!item[4]?.toString()?.toLowerCase()?.includes("cheque")
          && !item[4]?.toString()?.toLowerCase()?.includes("ref.")) {
        if (
          item.length === 5 &&
          item[2]
          && item[4].includes("UPI")
        ) {
          res.push({
            UTR: (item[4]?.split("/")[1]).substring(0, 12),
            amount: parseFloat(item[2].toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
            acNo: selectedAccount
          })
        } else if (
          item.length === 5 &&
          item[2]
          && item[4].includes("IMPS")
        ) {
          res.push({
            UTR: (item[4]?.split("RRN:")[1]).substring(0, 12),
            amount: parseFloat(item[2].toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
            acNo: selectedAccount
          })
        } else {
          errored.push({
            UTR: item[2],
            amount: item[5]
          })
          // dispatch(getDepositEntryErroredItems(['t', 't']))
        }
      }
      } catch (e) {
        console.log(e)
      }
    })
    setErroredItems(errored)
    try {
      axios.post(HOST + "/Banking/deposit-entry-bulk",
        res,
        {
          headers: { "Authorization": `${token}` }
        }).then((response) => {
        console.log(response.data)
      })
    } catch (e) {
      console.log(e)
    }
    console.log("DATA TO SEND", res)
    console.log("ERRORED ITEM (No Amount or UTR)", errored)
  }
  ////// END FEDERAL XSLX //////
}

export const erroredAU = () => {
  return asdasd
}
