import "./index.scss";
import {useEffect, useState} from "react";

export default ({
                  isVisible = false,
                  title ="Successfully saved",
                  utrRefExistCheck = true,
                  isError = false,
                  isSuccess = false
}) => {

    let [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(isVisible);
        setTimeout(() => setVisible(false), 3000);
    }, [isVisible]);


    if (isError) {
        return visible && (
        <div className={`info-pop-up ${!isError ? "" : "failed-save"} `}>
          {title}
        </div>
      );
    }
    if (isSuccess) {
        return visible && (
        <div className={`info-pop-up`}>
          {title}
        </div>
      );
    }

    return visible && (
      <div
          className={`info-pop-up ${utrRefExistCheck ? "" : "failed-save"} `}
      >
        { utrRefExistCheck === true ? "Successfully saved" : "UTR already exists" }
      </div>
    );
};
