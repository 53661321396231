import React from 'react';
import ReactDOM from 'react-dom';
import App from './Containers/App/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/general.scss';
import {Provider}  from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import {store} from './store/store'

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ReduxToastr
    timeOut={3400}
    closeOnToastrClick={false}
    position="top-center"
    transitionIn="bounceInDown"
    transitionOut="bounceOutUp"
  />
  </Provider>,
  document.getElementById('root')
);