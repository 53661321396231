import axios from "axios"
import { HOST } from "../../../Providers/AuthProvider"
import moment from "moment"
import jwt_decode from "jwt-decode";

export const iciciCSV = async (file, selectedAccount, setErroredItems) => {
  const token = sessionStorage.getItem("accessToken")
  const user = jwt_decode(token);
  let allItems
  let parsedItems
  console.log("output", await file.text())
  allItems = await file.text()
  const myId = sessionStorage.getItem("myId")
  const userName = sessionStorage.getItem("myUserName")
  function CSVToArray(strData, strDelimiter) {
    strDelimiter = strDelimiter || ","

    var objPattern = new RegExp(
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" + "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" + "([^\"\\" + strDelimiter + "\\r\\n]*))",
      "gi"
    )


    var arrData = [[]]

    var arrMatches = null

    while ((arrMatches = objPattern.exec(strData))) {
      var strMatchedDelimiter = arrMatches[1]
      if (
        strMatchedDelimiter.length &&
        strMatchedDelimiter !== strDelimiter
      ) {
        arrData.push([])

      }

      var strMatchedValue

      if (arrMatches[2]) {
        strMatchedValue = arrMatches[2].replace(new RegExp("\"\"", "g"), "\"")
      } else {
        strMatchedValue = arrMatches[3]

      }
      arrData[arrData.length - 1].push(strMatchedValue)
    }
    // console.log("object", arrData)

    // Return the parsed data.
    return arrData
  }

  if (allItems) {
    parsedItems = CSVToArray(allItems)
  }

  if (parsedItems) {
    const token = sessionStorage.getItem("accessToken")
    let i = 0
    let errored = []
    let allSendingArr = []
    let accountEntries = []
    console.log(parsedItems)
    parsedItems?.map(async item => {
      if (!item[3]?.toLowerCase()?.includes("cheque")
        && !item[3]?.toLowerCase()?.includes("ref.")) {
        if (
          item.length === 8 &&
          item[6] === 'CR' &&
          item[3] && item[5] && (item[3].includes("UPI") || item[3].includes("IMPS") || item[3].includes("MB"))
        ) {
          allSendingArr.push({
            UTR: (item[3]?.split('/')[2]),
            amount: parseFloat(item[5].toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
            acNo: selectedAccount
          })
          const date = moment(item[0], 'MM/DD/YYYY').format("YYYY-MM-DD");
          const obj = {
            description: item[3].replaceAll('/\"/', '').replaceAll('"', '').replaceAll('=', ''),
            utr: (item[3]?.split('/')[2]),
            accountNumber: selectedAccount,
            amount: parseFloat(item[5].toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
            isDeposit: true,
            userId: myId,
            userName: userName,
            psId: user.paymentSystemId,
            isPlugin: false,
            date: date + "T00:00:00.000Z",
            isRecognized: true,
            isProcessed: true
          }
          accountEntries.push(obj)
        } else if (item.length === 8 &&
          item[6] === 'DR' &&
          item[3] && item[5] && (item[3].includes("UPI") || item[3].includes("IMPS") || item[3].includes("MB"))) {
          const date = moment(item[0], 'MM/DD/YYYY').format("YYYY-MM-DD");
          const obj = {
            description: item[3].replaceAll('/\"/', '').replaceAll('"', '').replaceAll('=', ''),
            utr: (item[3]?.split('/')[2]),
            accountNumber: selectedAccount,
            amount: parseFloat(item[5].toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
            isDeposit: false,
            userId: myId,
            userName: userName,
            psId: user.paymentSystemId,
            isPlugin: false,
            date: date + "T00:00:00.000Z",
            isRecognized: true,
            isProcessed: true
          }
          accountEntries.push(obj)
        } else if (item.length === 8 &&
          (item[6] === 'CR' || item[6] === 'DR') &&
          item[3] && item[5]) {
          const date = moment(item[0], 'MM/DD/YYYY').format("YYYY-MM-DD");
          const obj = {
            description: item[3].replaceAll('/\"/', '').replaceAll('"', '').replaceAll('=', ''),
            utr: "",
            accountNumber: selectedAccount,
            amount: parseFloat(item[5].toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
            isDeposit: item[6] == 'CR' ? true : false,
            userId: myId,
            userName: userName,
            psId: user.paymentSystemId,
            isPlugin: false,
            date: date + "T00:00:00.000Z",
            isRecognized: false,
            isProcessed: false
          }
          accountEntries.push(obj)
        } else if (item.length === 9 && item[5]) {
          errored.push({
            UTR: item[5],
            amount: item[7],
            CR: item[6]
          })
          // dispatch(getDepositEntryErroredItems(['t', 't']))
        }
      }
    })
    setErroredItems(errored)
    try {
      axios.post(HOST + "/Banking/deposit-entry-bulk",
        allSendingArr,
        {
          headers: { "Authorization": `${token}` }
        }).then((response) => {
          console.log(response.data)
        })
      axios.post("https://banking.upicash.com/api/account_entries/",
        accountEntries,
        {
          headers: { "Authorization": `${token}` }
        }).then((response) => {
          console.log(response.data)
        })
    } catch (e) {
      console.log(e)
    }
    console.log("ALL SENDING ARR", allSendingArr)
    console.log("ALL SENDING ARR", accountEntries)
  }
}
