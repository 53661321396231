import "./index.scss";
import {useAuth} from "../../Providers/AuthProvider";
import {useEffect, useState} from "react";
import moment from "moment";
import ButtonCustom from "../../components/ButtonCustom";
import WithdrawalBatchPopUp from "../../components/WithdrawalBatchPopUp";

export const ExportCSVPage = () => {
    const {requestsToApi} = useAuth();
    const [reportsData, setReportsData] = useState([]);
    const [isVisiblePopUp, setIsVisiblePopUp] = useState( false);
    const [sortByDesc, setSortByDesc] = useState({createdAt: true, updatedAt: false, amount: false});
    const [sortedReports, setSortedReports] = useState([]);
    const [amountFrom, setAmountFrom] = useState('')
    const [amountTo, setAmountTo] = useState('')
    const [selectedReports, setSelectedReports] = useState([]);

    const handleClickApply = async () => {
        try {
            const response = await requestsToApi.get("/banking/dw-report" + "?status=Approved&type=W");
            console.log("table data", response);
            setSortedReports(response.data.result.sort((a, b) => {if (a.createdAt > b.createdAt) return -1;}));
            setReportsData(response.data.result);
        } catch (error) {
            console.log(error?.message);
        }
    };

    const changeSort = (key) => {
        setSortByDesc({createdAt: false , updatedAt: false, amount: false , [key]: !sortByDesc[key]});
        if (reportsData.length >= 2) {
            if (key === "amount") {
                setSortedReports(reportsData?.sort((a, b) => sortByDesc[key] ? a[key] - b[key] : b[key] - a[key]));
            }
            if (key !== "amount") {
                const filter = reportsData.filter(item => item[key]);

                const sort = filter.sort((a, b) => {
                    if (a[key] > b[key]) return -1;
                });
                setSortedReports(sortByDesc[key] ? [...sort.reverse(), ...reportsData.filter(item => !item[key])] : [...sort, ...reportsData.filter(item => !item[key])]);
            }
        }
    };

    // select
    let [showOption, setShowOption] = useState(false);
    let [selectedAccount, setSelectedAccount] = useState();
    const [accounts, setAccounts] = useState([]);

    const handleListDisplay = () => {
        setShowOption(prevState => !prevState);
    };

    const handleOptionClick = (e, account) => {
        setSelectedAccount(account.acNo);
        setShowOption(false);
        sessionStorage.setItem("selectedAccountNumber", account.acNo);
    };

    let closeSelectDropdown = () => {
        setTimeout(() => {setShowOption(false);}, 500);
    };

    // get accounts in select
    // eslint-disable-next-line
    useEffect(async () => {
        console.log("my req");
        try {
            let response = await requestsToApi.get("/Banking/withdraw/payment-gateways");
            console.log("accounts in dropdown", response);
            setAccounts(response.data.result);
        } catch (error) {
            console.log(error?.message);
        }
    }, []);

    const onAmountFrom = (e) => {
        setAmountFrom(e)
      }

      const onAmountTo = (e) => {
        setAmountTo(e)
      }
    const selecteAll = () =>{
        setSelectedReports(sortedReports);
    }
    const deSelectAll = () =>{
        setSelectedReports([]);
    }

    const selectOne = (report,checked) =>{
        // console.log([...selectedReports,report],checked)
        try {
            if(checked){
                setSelectedReports([...selectedReports,report])
            }
            else{
                let temp = selectedReports.filter((e)=>e.requestId !== report.requestId);
                setSelectedReports(temp);
            }
        } catch (error) {

        }

    }

    const selectInNumbers = (num) =>{
        try {
            let temp = sortedReports.slice(selectedReports.length,selectedReports.length + num);
            let temp2 = [...selectedReports,...temp]
            const uniqueIds = new Set();
            const unique = temp2.filter(element => {
            const isDuplicate = uniqueIds.has(element.requestId);
                uniqueIds.add(element.requestId);
                if (!isDuplicate) {
                    return true;
                }
                return false;
            });
            setSelectedReports(unique);
        } catch (error) {

        }

    }

    return (
        <div className="export-csv">
            <WithdrawalBatchPopUp
              data={selectedReports}
              account={selectedAccount}
              visible={isVisiblePopUp}
              setIsVisiblePopUp={setIsVisiblePopUp}
            />
             <div className="export-csv__header">
                <h1 className="export-csv__header_title">Export CSV</h1>
                <div className='export-csv__header_filter'>
                    <input
                        type="text"
                        placeholder='From'
                        onChange={(e) => onAmountFrom(e.target.value)}
                        value={amountFrom}
                        />
                    <input
                        type="text"
                        placeholder='To'
                        onChange={(e) => onAmountTo(e.target.value)}
                        value={amountTo}
                        />
                    </div>
                <ButtonCustom onClick={handleClickApply} color="cyan">Refresh</ButtonCustom>
                <div className="deposit-entry__top-select-custom" onMouseLeave={closeSelectDropdown}>
                    <div
                      className={showOption ? "deposit-entry__top-select-custom-selected active" : "deposit-entry__top-select-custom-selected"}
                      onClick={handleListDisplay}>
                      {selectedAccount || 'Account No'}
                    </div>
                    {showOption && (
                    <ul className="deposit-entry__top-select-custom-options">
                        {accounts.map((account, index) =>
                            <li className="deposit-entry__top-select-custom-option"
                                key={account + index}
                                onClick={() => handleOptionClick(window.event, account)}
                            >
                                {account.acNo + "-" + account.name + "-" + account.bankName}
                            </li>)}
                    </ul>
                    )}
                </div>
                <ButtonCustom
                  onClick={() => setIsVisiblePopUp(true)}
                  disabled={!reportsData?.length || !selectedAccount}
                  color="cyan"
                >Export</ButtonCustom>
            </div>
            <div className="row mt-3 ml-2 selectionbar">
            <ButtonCustom
                  onClick={selecteAll}
                  disabled={!reportsData?.length}
                >Select All</ButtonCustom>
            <ButtonCustom
                  onClick={deSelectAll}
                  disabled={!reportsData?.length}
                >Deselect All</ButtonCustom>
            <ButtonCustom
                  onClick={() => selectInNumbers(10)}
                  disabled={!reportsData?.length}
                  color="cyan"
                > + 10</ButtonCustom>
            <ButtonCustom
                  onClick={() => selectInNumbers(50)}
                  disabled={!reportsData?.length}
                  color="cyan"
                > + 50</ButtonCustom>
            <ButtonCustom
                  onClick={() => selectInNumbers(100)}
                  disabled={!reportsData?.length}
                  color="cyan"
                > + 100</ButtonCustom>

                <div>{selectedReports?.filter(i => (amountFrom ? i.amount >= amountFrom : i.amount > 0) && (amountTo ? i.amount <= amountTo : i.amount > 0))?.length} Rows Selected.</div>
            </div>
            <div className="export-csv__table">
                <ul className="export-csv__table-header">
                    <li>
                        <input
                                    className="custom-checkbox"
                                    id="bet-settings__check"
                                    // onChange={checkboxToggle("betSettingsSameForAllActive")}
                                    type="checkbox"
                                    // checked={editData.betSettingsSameForAllActive}
                                />
                    </li>
                    <li onClick={() => changeSort("createdAt")}>
                        <span className={`export-csv__table-header-sort ${sortByDesc.createdAt ? "" : "up" }`}>CreatedAt</span>
                    </li>
                    <li onClick={() => changeSort("updatedAt")}>
                        <span className={`export-csv__table-header-sort ${sortByDesc.updatedAt ? "" : "up" }`}>CompletedAt</span>
                    </li>
                    <li>ID</li>
                    <li>LoginName</li>
                    <li onClick={() => changeSort("amount")}>
                        <span className={`export-csv__table-header-sort ${sortByDesc.amount ? "" : "up" }`}>Amount</span>
                    </li>
                    <li>A/C</li>
                    <li>IFSC</li>
                    <li>Name</li>
                    <li>BankName</li>
                    <li>Status</li>
                </ul>
                <div className="export-csv__table-body">
                    {
                        sortedReports?.filter(i => (amountFrom ? i.amount >= amountFrom : i.amount > 0) && (amountTo ? i.amount <= amountTo : i.amount > 0))?.map((report, index) =>
                            <ul className="export-csv__table-body-row" key={report.requestId + index}>
                                <li><input
                                    className="custom-checkbox"
                                    id="bet-settings__check"
                                    onChange={(e)=>selectOne(report,e.target.checked)}
                                    type="checkbox"
                                    checked={selectedReports.find((e)=>e.requestId == report.requestId)?true:false}
                                /></li>
                                <li className="export-csv__table-body-row-cell export-csv__table-row-cell-column">
                                    <span>{moment(report?.createdAt).format("DD.MM.YY")}</span>
                                    <span className="export-csv__date-cell">{moment(report?.createdAt).format("LTS")}</span>
                                </li>
                                <li className="export-csv__table-body-row-cell export-csv__table-row-cell-column">
                                    {report.updatedAt &&
                                    <div className="export-csv__table-row-cell-column">
                                        <span>{moment(report.updatedAt).format("DD.MM.YY")}</span>
                                        <span className="export-csv__date-cell">{moment(report.updatedAt).format("LTS")}</span>
                                    </div>}
                                </li>
                                <li className="export-csv__table-body-row-cell">{report?.requestId}</li>
                                <li className="export-csv__table-body-row-cell">{report?.loginName}</li>
                                <li className="export-csv__table-body-row-cell">{report?.amount}</li>
                                <li className="export-csv__table-body-row-cell">{report?.account.accountNo}</li>
                                <li className="export-csv__table-body-row-cell">{report?.account.IFSC}</li>
                                <li className="export-csv__table-body-row-cell">{report?.account.accountHolderName}</li>
                                <li className="export-csv__table-body-row-cell export-csv__table-row-cell-column">
                                    <span>{report?.account.bankName}</span>
                                    <span>{report?.account.branchName}</span>
                                </li>
                                <li className="export-csv__table-body-row-cell">{report?.status.replace("_", " ")}</li>
                            </ul>,
                        )
                    }
                </div>
            </div>
        </div>
    );
};



