import "./index.scss";
import ButtonCustom from "../ButtonCustom";
import InfoPopUp from "../InfoPopUp";
import axios from "axios";
import {useState} from "react";
import { HOST } from "../../Providers/AuthProvider"

export default ({visible, setIsVisiblePopUp, data, account}) => {

    const [error, setError] = useState("");
    const [isVisibleInfoPopUp, setIsVisibleInfoPopUp] = useState(false);
    const [isVisibleDownloadPopUp, setIsVisibleDownloadPopUp] = useState(false);
    const [createBath, setCreateBath] = useState('')

    const token = sessionStorage.getItem("accessToken");

    const handleClickSave = async () => {
        const requestIds = () => {
            const idsArr = [];
            data.forEach(item => idsArr.push(item.requestId));
            return idsArr;
        };

        try {
            const response = await axios.post(HOST + "/Banking/withdraw/create-batch",
                {
                    "requestIds": requestIds(),
                    "acNo": account,
                },
              {headers: {"Authorization": `${token}`}},
            );

            // console.log("info popup" ,response);

            setIsVisibleInfoPopUp(true);
            setIsVisibleDownloadPopUp(true)
            setTimeout(() => setIsVisibleInfoPopUp(false), 3000);
            // setTimeout(() => setIsVisibleDownloadPopUp(false), 3000);
            // setTimeout(() => closeFunc(), 2500);
            setCreateBath(response)
        }
        catch (error) {
            setIsVisibleInfoPopUp(true);
            setError(error?.response?.data?.error);
            setTimeout(() => setError(""), 3000);
            setTimeout(() => setIsVisibleInfoPopUp(false), 3000);
        }
    };

    const handleClickCancel = () => {
        setIsVisiblePopUp(false);
        setIsVisibleDownloadPopUp(false)
    };

    const closeOnDownload = () => {
        setTimeout(() => {setIsVisiblePopUp(false)
            setIsVisibleDownloadPopUp(false)}, 2000)
    }

    if (isVisibleDownloadPopUp) {
        return  (
            <div className="download-batch-pop-up">
                <div className="download-batch-pop-up__wrapper">
                    <div className="download-batch-pop-up__title-row">
                        <p>Download Batch?</p>
                    </div>
                    <div className="download-batch-pop-up__buttons-row">
                        <ButtonCustom onClick={handleClickCancel} color="red">Cancel</ButtonCustom>
                        <ButtonCustom onClick={closeOnDownload}
                                      color="cyan"
                                      disabled={createBath?.data?.result?.batchNo}
                                      className={`popUpDownloadButton`}
                        >
                            <a
                                href={HOST + `/Banking/withdraw/export-batch?batchId=` + createBath?.data?.result?.batchNo + `&downloadtoken=` + token}
                                target="_blank" download
                            >
                                Download
                            </a>
                        </ButtonCustom>
                    </div>
                </div>
            </div>
        )
    }

    return visible && (
    <div className="withdrawal-batch-pop-up">
      <InfoPopUp title={error} isError={!!error} isVisible={isVisibleInfoPopUp}/>;
      <div className="withdrawal-batch-pop-up__wrapper">
        <div className="withdrawal-batch-pop-up__title-row">
          <p>
            <span>Total Entries: </span> <span className="bold">{data?.length}</span>
          </p>
          <p>Do you wish to create withdrawal batch?</p>
        </div>
        <div className="withdrawal-batch-pop-up__buttons-row">
          <ButtonCustom onClick={handleClickCancel} color="red">Cancel</ButtonCustom>
          {/*<ButtonCustom onClick={handleClickSave} color="cyan" setIsVisiblePopUp={handleClickCancel}>Save</ButtonCustom>*/}
          <ButtonCustom onClick={handleClickSave} color="cyan" >Save</ButtonCustom>
        </div>
      </div>
    </div>
  );
};
