import "./index.scss"
import { useStorage } from "../../Providers/StorageProvider"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../Providers/AuthProvider"
import DownlineNav from "../MAListingPage/Nav"
import { Navbar, Nav } from "react-bootstrap"
import ActivityReport from "./ActivityReport"
import BalanceReport from "./BalanceReport"
import BetListReport from "./BetListReport"
import TransferStatement from "../TransferStatement"
import StatementPage from "../StatementPage"
import NetExposureReport from "./NetExposureReport"

const BetListReportPage = ({ match }) => {
  const { userName } = useStorage()
  const history = useHistory()
  const [userData, setUserData] = useState(null)
  const { requestsToApi } = useAuth()
  
  useEffect(async () => {
    try {
      const response = await requestsToApi.get(
        `agency/${userName}/agency-mgmt/downline?parent=${match.params.parent}&searchUser=${match.params.id}`
      )
      setUserData(response.data.result.downline[0].user)
    } catch (error) {
      console.log(error?.message)
    }
  }, [])

  const tabAdjuster = () => {
    if (userData) {
      const {
        params: { id }
      } = match
      switch (history.location.hash) {
        case "#balance":
          return <BalanceReport userLevel={userData.level} userId={id} />
        case "#past":
        case "#current":
          return <BetListReport userId={id} userLevel={userData.level} />
        case "#pnl-statement":
        case "#credit-statement":
        case "#market":
          return <StatementPage userId={id} userLevel={userData.level} />
        case "#transfer-statement":
          return <TransferStatement userId={id} />
        case "#net-exposure":
          return <NetExposureReport userId={id} userLevel={userData.level} />
        default:
          return <ActivityReport userId={id} userLevel={userData.level} />
      }
    }
  }

  const followTheLink = href => () => {
    const rebuiltURL = history.location.pathname.split("/").slice(0, 4).join("/")
    history.push(rebuiltURL + href)
  }
  return (
    <div className="downline-report">
      <div className="downline-report__header">
        <div className="nav">
          {userData ? <DownlineNav uplineData={[{ loginName: userData.loginName, username: userData.name }]}
            url={history.location.state ? history.location.state.lastUrl : '/agency-management/downline'} /> : <></>}
        </div>
      </div>
      <div className="downline-report__delimiter"></div>
      <div className="downline-report__main">
        <Navbar className="report-tabs" variant="dark">
          <Nav
            defaultActiveKey={
              history.location.hash === ""
                ? "#home"
                : history.location.hash === "#credit-statement" || history.location.hash === "#market"
                  ? "#pnl-statement"
                  : history.location.hash === "#past"
                    ? "#current"
                    : history.location.hash
            }
            className="mr-auto"
          >
            <Nav.Link href="#home" onClick={followTheLink("#home")}>
              Activity
            </Nav.Link>
            {userData && userData.level !== "Member" ? (
              <Nav.Link href="#balance" onClick={followTheLink("#balance")}>
                Balance
              </Nav.Link>
            ) : (
              <></>
            )}
            <Nav.Link href="#current" onClick={followTheLink("#current")}>
              Bet List
            </Nav.Link>
            {userData && userData.level === "Member" ? (
              <Nav.Link href="#betting-pnl" onClick={followTheLink("#betting-pnl")}>
                Betting P&L
              </Nav.Link>
            ) : (
              <></>
            )}
            <Nav.Link href="#pnl-statement" onClick={followTheLink("#pnl-statement")}>
              Account Statement
            </Nav.Link>
            <Nav.Link href="#transfer-statement" onClick={followTheLink("#transfer-statement")}>
              Transfer Statement
            </Nav.Link>
            <Nav.Link href="#net-exposure" onClick={followTheLink("#net-exposure")}>
              Net Exposure
            </Nav.Link>
          </Nav>
        </Navbar>
        <div className="main-page-content">
          <div className="tab-content">{tabAdjuster()}</div>
        </div>
      </div>
    </div>
  )
}
export default BetListReportPage
