import React, { useState, useContext, useEffect, useMemo } from "react"
import { useAuth } from "./AuthProvider"

export const StorageContext = React.createContext()
export const useStorage = () => useContext(StorageContext)
export default ({ children }) => {
  const { requestsToApi } = useAuth()
  const [balance, setBalance] = useState({
    cashBalance: "-",
    totalBalance: "-",
    actualNetExposure: "-",
    availableBalance: "-"
  })
  const [reqTrigger, resetReqTrigger] = useState({});
  const userName = useMemo(() => sessionStorage.getItem("myUserName"), []);
  const [serverTime, setServerTime] = useState(null);
  const userLevel = useMemo(() => sessionStorage.getItem("myLevel"), []);
  const userAppMode = useMemo(() => sessionStorage.getItem("appMode"), []);
  const userPermissions = useMemo(() => sessionStorage.getItem("permissions")?.split(","), []);
  const childrenLevel = useMemo(
    () =>
      userLevel === "Agent"
        ? "Member"
        : userLevel === "COY"
        ? "Agency"
        : userLevel === "SMA"
        ? userName.length === 2
          ? "SMA"
          : "MA"
        : userLevel === "MA"
        ? "Agent"
        : "Unknown",
    []
  )

  const myGMT = new Date().toString().match(/([-\+][0-9]+)\s/)[1]
  const timeSettings = [
    {
      title: "System time",
      gmt: serverTime ? serverTime.offset : "+00:00",
      timezone: serverTime
        ? `Etc/GMT${
            (serverTime.offset[0] === "+" ? "-" : "+") +
            (serverTime.offset[1] === "0" ? "" : serverTime.offset[1]) +
            serverTime.offset[2]
          }`
        : "GMT0"
    },
    {
      title: "Your computer time",
      gmt: `${myGMT.slice(0, 3)}:${myGMT.slice(3)}`,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    {
      title: "India Standard time",
      gmt: "+05:30",
      timezone: "Asia/Kolkata"
    }
  ]
  const [activeTimeSetting, setActiveTimeSetting] = useState(timeSettings[1])
  const [isLoading, setIsLoading] = useState(false)

  const balanceReq = async () => {
    try {
      const response = await requestsToApi.get("agency/" + userName + "/account/balance")
      setBalance(response.data.result)
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(async () => {
    try {
      const response = await requestsToApi.get("server-time")
      setServerTime(response.data.result)
    } catch (error) {
      console.log(error?.message)
    }
  }, [])

  useEffect(() => {
    balanceReq()
  }, [reqTrigger])

  useEffect(() => {
    const timer = setTimeout(() => {
      resetReqTrigger({})
    }, 20000)
    return () => clearTimeout(timer)
  }, [balance])

  return (
    <StorageContext.Provider
      value={{
        balance,
        userName,
        activeTimeSetting,
        setActiveTimeSetting,
        isLoading,
        setIsLoading,
        timeSettings,
        userLevel,
        childrenLevel,
        userAppMode,
        userPermissions,
      }}
    >
      {children}
    </StorageContext.Provider>
  )
}
