import "./index.scss";
import {useEffect, useState} from "react";
import InfoPopUp from "../../components/InfoPopUp";
import moment from "moment";
import {useAuth} from "../../Providers/AuthProvider";

const fmt = require('indian-number-format')
export default () => {
  const {requestsToNodeAPI} = useAuth();

  const [sortedReports, setSortedReports] = useState([]);
  const [lastSort, setLastSort] = useState({createdAt: true});

  const getBalance = async () => {
    try {
      const response = await requestsToNodeAPI.get("/account_balance");
      setSortedReports(response.data.data.sort((a, b) => {if (a.amount > b.amount) return -1;}).sort((a, b) => {if (a.updatedAt > b.updatedAt) return -1;}));
    } catch (error) {
      console.log(error?.message);
    }
  }

  useEffect(async () => {
    await getBalance()
     let interval = setInterval(() => {
       getBalance()
     },30000)
    return () => clearInterval(interval)
  }, []);


  return (
    <div className="account_balance">
      <div className="account_balance__header">
        <div className="account_balance__header_date-rows">
          <div className="account_balance__header_date-row">
            <h1>Account Balance</h1>
          </div>
        </div>
      </div>
      <div className="account_balance__table">
        <div className="account_balance__table-row-date">
          <div className="account_balance__table-date">{moment().format("DD.MM.YY")}</div>
        </div>
        <ul className="account_balance__table-header">
          <li>ID</li>
          <li>Bank Name</li>
          <li>Account Name</li>
          <li>Available Balance</li>
          <li>Last updated</li>
        </ul>
        <div className="account_balance__table-body">
          {sortedReports?.map((report, index) =>
            <ul key={report._id + index}
                className="account_balance__table-body-row">
              <li>{report.acId}</li>
              <li>{report.name}</li>
              <li>{report.account_name}</li>
              <li style={{fontWeight:600, color: report.amount > 200000 ? 'red':'green' }}>{fmt.formatFixed(parseInt(report.amount),0)}</li>
              <li>{report.updatedAt}</li>
            </ul>,
          )}
        </div>
        <InfoPopUp />
      </div>
    </div>
  );
};
