import * as XLSX from "xlsx/xlsx.mjs"
import axios from "axios"
import { HOST } from "../../../Providers/AuthProvider"
import moment from "moment"
import jwt_decode from "jwt-decode";
let asdasd = ['t']
export const yesPersonalExcel = async (file, selectedAccount, setErroredItems) => {
  // const dispatch = useDispatch()
  const token = sessionStorage.getItem("accessToken")
  const user = jwt_decode(token);
  const reader = new FileReader()
  const rABS = !!reader.readAsBinaryString
  const myId = sessionStorage.getItem("myId")
  const userName = sessionStorage.getItem("myUserName")
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target.result
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
    /* Get first worksheet */
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    console.log(rABS, wb)
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
    /* Update state */
    parsing(data)
    console.log("daaaaaaaaa", data)
  }
  if (rABS) reader.readAsBinaryString(file)
  else reader.readAsArrayBuffer(file)

  const parsing = xlsData => {
    let res = []
    let accountEntries = []
    let errored = []
    console.log("xlsData", xlsData)
    let accountNumber = xlsData[13][5].substring(0, 15)
    xlsData.map(item => {
      if (!item[3]?.toString()?.toLowerCase()?.includes("cheque")
          && !item[3]?.toString()?.toLowerCase()?.includes("ref.")) {
      if (
        item.length === 7 &&
        item[5]
        && (item[3]?.includes("UPI"))
        // && item[7] === "Cr."
      ) {
        res.push({
          UTR: (item[3].split("/")[1]?.toString().substring(0, 12)),
          amount: parseFloat(item[5]?.toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
          acNo: accountNumber
        })
        let date = moment(item[0], 'DD-MMM-YYYY').format("YYYY-MM-DD");
        const obj = {
          description: item[3],
          utr: (item[3].split("/")[1]?.toString().substring(0, 12)),
          accountNumber: selectedAccount,
          amount: parseFloat(item[5]?.toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
          isDeposit: true,
          userId: myId,
          userName: userName,
          psId: user.paymentSystemId,
          isPlugin: false,
          date: date + "T09:50:05.877Z",
          isRecognized: true,
          isProcessed: true
        }
        accountEntries.push(obj)
      } else if (
        item.length === 7 &&
        item[5]
        && (item[3]?.includes("IMPS"))
        // && item[7] === "Cr."
      ) {
        res.push({
          UTR: (item[3].split("RRN:")[1]?.toString().substring(0, 12)),
          amount: parseFloat(item[5]?.toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
          acNo: accountNumber
        })
        let date = moment(item[0], 'DD-MMM-YYYY').format("YYYY-MM-DD");
        const obj = {
          description: item[3],
          utr: (item[3].split("RRN:")[1]?.toString().substring(0, 12)),
          accountNumber: selectedAccount,
          amount: parseFloat(item[5]?.toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
          isDeposit: true,
          userId: myId,
          userName: userName,
          psId: user.paymentSystemId,
          isPlugin: false,
          date: date + "T09:50:05.877Z",
          isRecognized: true,
          isProcessed: true
        }
        accountEntries.push(obj)
      } else if (item.length === 7 &&
        item[4]
        && (item[3]?.includes("UPI"))) {
        let date = moment(item[0], 'DD-MMM-YYYY').format("YYYY-MM-DD");
        const obj = {
          description: item[3],
          utr: (item[3].split("/")[1]?.toString().substring(0, 12)),
          accountNumber: selectedAccount,
          amount: parseFloat(item[4]?.toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
          isDeposit: false,
          userId: myId,
          userName: userName,
          psId: user.paymentSystemId,
          isPlugin: false,
          date: date + "T09:50:05.877Z",
          isRecognized: true,
          isProcessed: true
        }
        accountEntries.push(obj)
      } else if (item.length === 7 &&
        item[4]
        && (item[3]?.includes("IMPS"))) {
        let date = moment(item[0], 'DD-MMM-YYYY').format("YYYY-MM-DD");
        const obj = {
          description: item[3],
          utr: (item[3].split("RRN:")[1]?.toString().substring(0, 12)),
          accountNumber: selectedAccount,
          amount: parseFloat(item[4]?.toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
          isDeposit: false,
          userId: myId,
          userName: userName,
          psId: user.paymentSystemId,
          isPlugin: false,
          date: date + "T09:50:05.877Z",
          isRecognized: true,
          isProcessed: true
        }
        accountEntries.push(obj)
      } else if (item.length === 7 && item[3] && (item[4] || item[5]) && item[0] !== 'Transaction Date' && item[0] !== 'Opening Balance') {
        let date = moment(item[0], 'DD-MMM-YYYY').format("YYYY-MM-DD");
        let amount = item[4] || item[5]
        const obj = {
          description: item[3],
          utr: "",
          accountNumber: selectedAccount,
          amount: parseFloat(amount?.toString().replace("+", "").replaceAll("₹", "").replaceAll(",", "")),
          isDeposit: item[5] ? true : false,
          userId: myId,
          userName: userName,
          psId: user.paymentSystemId,
          isPlugin: false,
          date: date + "T09:50:05.877Z",
          isRecognized: false,
          isProcessed: false
        }
        accountEntries.push(obj)
      } else {
        errored.push({
          UTR: item[3],
          amount: item[5]
        })
        // dispatch(getDepositEntryErroredItems(['t', 't']))
      }
    }
    })
    setErroredItems(errored)
    console.log(res);
    try {
      axios.post(HOST + "/Banking/deposit-entry-bulk",
        res,
        {
          headers: { "Authorization": `${token}` }
        }).then((response) => {
          console.log(response.data)
        })
      axios.post("https://banking.upicash.com/api/account_entries/",
        accountEntries,
        {
          headers: { "Authorization": `${token}` }
        }).then((response) => {
          console.log(response.data)
        })
    } catch (e) {
      console.log(e)
    }
    console.log("DATA TO SEND", res)
    console.log("DATA TO SEND", accountEntries)
    console.log("ERRORED ITEM (No Amount or UTR)", errored)
  }
  ////// END FEDERAL XSLX //////
}

export const erroredAU = () => {
  return asdasd
}
